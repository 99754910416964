import apiWrapper from './apiWrapper';

const getWhereOrLikes = value => [
  `${value}`,
  `%+${value}+%`,
  `${value}+%`,
  `%+${value}`,
];

export const getFilterWheres = filters => {
  const where = {};
  const where_or_likes = {};

  if (filters.manufacturer?.value) {
    if (filters.manufacturer.value === 'NULL') where.manufacturer = '';
    else
      where_or_likes.manufacturer = getWhereOrLikes(filters.manufacturer.value);
  }

  if (filters.supplier?.value) {
    if (filters.supplier.value === 'NULL') where.supplier = '';
    else where_or_likes.supplier = getWhereOrLikes(filters.supplier.value);
  }

  if (filters.leadTime?.value) {
    if (filters.leadTime.value === 'NULL') where.lead_time = '';
    else where_or_likes.lead_time = getWhereOrLikes(filters.leadTime.value);
  }

  if (filters.tag?.value) {
    if (filters.tag.value === 'NULL') where.product_code = '';
    else where_or_likes.product_code = getWhereOrLikes(filters.tag.value);
  }

  return { where, where_or_likes };
};

export const getFilteredProducts = async (page, filters, setResults, isProductList) => {

  try {
    const { where, where_or_likes } = getFilterWheres(filters);
    const results = await apiWrapper.callApiPost('/api/search', {
      term: filters.searchText,
      table: 'products',
      columns: [
        'product_name',
        'product_code',
        'supplier_title',
        'supplier_sku',
        'sku',
      ],
      order: filters?.sorting?.value || ['cost_price', 'ASC'],
      where,
      where_or_likes,
      page,
    });

    setResults(results);
  } catch (err) {
    console.log(err);
  }
};
