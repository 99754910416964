import React, { useState } from 'react';
import { ImagesModal } from './ImagesModal';
import { ImagesPreview } from './ImagesPreview';
import { getImages } from '../helpers/getImages';
import classnames from 'classnames';

export const ImageArea = ({
  addImage,
  removeImage,
  selectImage,
  unselectImage,
  image,
  className,
  chosenImages,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const images = getImages(image, 'full', false) || [];

  return (
    <>
      <div
        onClick={() => setModalOpen(true)}
        className={classnames(
          'flex justify-center items-center p-4 border-solid border-[1px] border-grey rounded flex justify-between items-center cursor-pointer hover:bg-grey-light transition-colors',
          className
        )}
      >
        {images.length ? (
          <ImagesPreview
            images={images}
            chosenImages={chosenImages}
            allowMoreImagesMessage
          />
        ) : (
          <div className="w-full text-2xl text-center">
            Select
            <br />
            Images
          </div>
        )}
      </div>
      <ImagesModal
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        images={images}
        addImage={addImage}
        selectImage={selectImage}
        unselectImage={unselectImage}
        removeImage={removeImage}
        chosenImages={chosenImages}
      />
    </>
  );
};
