import React from 'react';
import PropTypes from 'prop-types';
import ImageZoom from 'react-medium-image-zoom';
import getImage from '../helpers/getmage';
import { finishesType } from '../../../types';

const Finishes = ({ finishes, context = 'edit' }) => {
  if (!finishes || !finishes.length) {
    return '';
  }

  return (
    <ul className="finishes-list d-flex align-items-end justify-content-center mr-4">
      {finishes.map((finish, i) => {
        let swatchImage = null;
        let fullImage = null;

        if (finish.finish_swatch_image && !finish.upload_file) {
          swatchImage = getImage(finish.finish_swatch_image, 'swatch');
          fullImage = getImage(finish.finish_swatch_image, 'thumbnail');
        } else if (finish.upload_file && context === 'edit') {
          swatchImage = URL.createObjectURL(finish.upload_file);
        }
        return (
          <li key={i} className="d-flex flex-column align-items-center">
            {swatchImage && (
              <span className="finish-swatch-image-wrap">
                {context === 'edit' ? (
                  <img src={swatchImage} alt={finish.finish_title} />
                ) : (
                  <ImageZoom
                    image={{
                      src: fullImage,
                      alt: finish.finish_title,
                    }}
                    shouldReplaceImage={false}
                    shouldRespectMaxDimension
                  />
                )}
              </span>
            )}
            {finish.finish_title && (
              <span className="finish-title mt-2">{finish.finish_title}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

Finishes.propTypes = {
  finishes: PropTypes.arrayOf(finishesType),
  context: PropTypes.oneOf(['view', 'edit']),
};

Finishes.defaultProps = {
  finishes: [],
  context: 'edit',
};

export default Finishes;
