import calculateDisplayedCostPrice from './calculateDisplayedCostPrice';

export default function calculateTotals(products, quoteCurrencyName) {
  const subtotal = { A: 0, B: 0, AC: 0, BC: 0 };

  if (!products || !products.length) {
    return subtotal;
  }
  products.forEach(product => {
    let rate = 1;
    const productCurrencyName = product.currency || "EUR";
    const hasProductCurrencyRates = product.currency_rates && product.currency_rates.length;
    const changeRate = hasProductCurrencyRates && !!quoteCurrencyName && !!productCurrencyName;
    if (changeRate) {
      const rateObj = product.currency_rates
        .find(r => Object.keys(r)[0] === `${productCurrencyName}/${quoteCurrencyName}`);
      if (rateObj) rate = Object.values(rateObj)[0];
    }
    const price = (product.sell_price * rate) || 0;
    const costPrice = calculateDisplayedCostPrice(
      +product.cost_price || 0,
      +product.percentage_increase || 0,
      +product.fixed_price_surcharge || 0,
    ) * (rate || 1);
    const qty = product.quantity || 1;
    const total = Math.round(price) * qty;
    const totalCost = Math.round(costPrice) * qty;

    if (product.cost_option === 'A' || product.cost_option === 'B') {
      subtotal[product.cost_option] += total;
      subtotal[`${product.cost_option}C`] += totalCost;
    } else if (product.cost_option === 'Both') {
      subtotal.A += total;
      subtotal.B += total;
      subtotal.AC += totalCost;
      subtotal.BC += totalCost;
    }
  });

  return subtotal;
}
