/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  loadQuoteAction,
  resetState,
  updateStatus,
  updateFieldAction,
  showLoader,
  hideLoader,
  showCurrenciesLoader,
  hideCurrenciesLoader,
  setQuoteLoading,
  unsetQuoteLoading,
  updateDelivery,
  updateDeliveryExclusive,
  updateDeliveryInstall,
  updateDeliveryInclusive,
  updateVatText,
  updateVatInclusive,
  updateCosts,
  updateVATRate,
  loadDefaultTcsAction,
  updateTcs,
  getWelcomeTextAction,
  updateTotalNoteAction,
  setQuoteUpdated,
  unsetQuoteUpdated,
  startGeneratingPdf,
  finishGeneratingPdf,
  saveScroll,
} from '../actions/quote';

import quoteDefaultState from '../defaultState/quote';

const quote = createReducer(quoteDefaultState, {
  [loadQuoteAction]: (state, action) => ({
    ...action.payload,
    quoteLoading: false,
  }),
  [resetState]: () => ({ ...quoteDefaultState, quoteLoading: true }),
  [updateStatus]: (state, { payload }) => {
    state.status = payload;
  },
  [updateFieldAction]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [showLoader]: state => {
    state.loading = true;
  },
  [hideLoader]: state => {
    state.loading = false;
  },
  [showCurrenciesLoader]: state => {
    state.currenciesLoading = true;
  },
  [hideCurrenciesLoader]: state => {
    state.currenciesLoading = false;
  },
  [setQuoteLoading]: state => {
    state.quoteLoading = true;
  },
  [unsetQuoteLoading]: state => {
    state.quoteLoading = false;
  },
  [updateDelivery]: (state, { payload }) => {
    const { option, value } = payload;

    state.delivery[option] = value.delivery;
  },
  [updateDeliveryExclusive]: (state, { payload }) => {
    const { option, value } = payload;

    state.deliveryExclusive[option] = value.deliveryExclusive;
  },
  [updateDeliveryInstall]: (state, { payload }) => {
    const { option, value } = payload;

    state.deliveryInstall[option] = value.deliveryInstall;
  },
  [updateDeliveryInclusive]: (state, { payload }) => {
    const { option, value } = payload;

    state.deliveryInclusive[option] = value.deliveryInclusive;
  },
  [updateVatText]: (state, { payload }) => {
    const { option, value } = payload;

    state.vatText[option] = value.vatText;
  },
  [updateVatInclusive]: (state, { payload }) => {
    const { option, value } = payload;

    state.vatInclusive[option] = value.vatInclusive;
  },
  [updateCosts]: (state, { payload }) => {
    const { costs } = state;

    state.costs = { ...costs, ...payload };
  },
  [updateVATRate]: (state, { payload }) => {
    state.vatRate = payload.vatRate;
  },
  [loadDefaultTcsAction]: (state, { payload }) => {
    state.tcs = payload;
  },
  [updateTcs]: (state, { payload }) => {
    state.tcs = payload;
  },
  [getWelcomeTextAction]: (state, { payload }) => {
    state.welcome = payload;
  },
  [updateTotalNoteAction]: (state, { payload }) => {
    const { costOption, value } = payload;

    state.totalNotes[costOption] = value;
  },
  [setQuoteUpdated]: state => {
    state.updated = true;
  },
  [unsetQuoteUpdated]: state => {
    state.updated = false;
  },
  [startGeneratingPdf]: state => {
    state.generatingPdf = true;
  },
  [finishGeneratingPdf]: state => {
    state.generatingPdf = false;
  },
  [saveScroll]: (state, { payload }) => {
    console.log('\n\n========================================');
    console.log('saving scroll to: ', payload);
    console.log('========================================');
    state.oldScroll = payload;
  },
});

export default quote;
