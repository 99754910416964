import React from 'react';
import classnames from 'classnames';
import ImageZoom from 'react-medium-image-zoom';

const isEmpty = val => !val && val !== 0;

export const ImagesPreview = ({
  images,
  chosenImages,
  height = 450,
  allowZoom,
  allowMoreImagesMessage = false,
}) => {
  const selectedImages = (() => {
    const { first, second, third } = chosenImages || {};
    if (isEmpty(first) && isEmpty(second) && isEmpty(third))
      return [
        images[0],
        ...(images[1] ? [images[1]] : []),
        ...(images[2] ? [images[2]] : []),
      ];
    if (isEmpty(first) && isEmpty(second) && isEmpty(third))
      return [
        images[0],
        ...(images[1] ? [images[1]] : []),
        ...(images[2] ? [images[2]] : []),
      ];
    const firstImage = isEmpty(first) ? [] : [images[first]];
    const secondImage = isEmpty(second) ? [] : [images[second]];
    const thirdImage = isEmpty(third) ? [] : [images[third]];
    return [...firstImage, ...secondImage, ...thirdImage];
  })();

  const moreImagesCount = images.length - selectedImages.length;
  const showMoreImagesMessage = allowMoreImagesMessage && moreImagesCount > 0;

  const Image = ({ src }) => (
    <div
      className={classnames(
        'rounded flex justify-center items-center h-full w-full my-zoomed-image'
      )}
    >
      {allowZoom ? (
        <ImageZoom
          image={{ src, alt: 'product' }}
          shouldReplaceImage={false}
          shouldRespectMaxDimension
        />
      ) : (
        <img
          alt="product"
          src={src}
          className={classnames('h-auto max-h-[100%] mx-auto rounded')}
        />
      )}
    </div>
  );

  if (selectedImages.length === 1)
    return (
      <div className="w-full flex flex-col items-center">
        <div
          className="w-full flex justify-center items-center"
          style={{ height: height + (showMoreImagesMessage ? 40 : 0) }}
        >
          {selectedImages.map(preview => (
            <Image src={preview} />
          ))}
        </div>
        {showMoreImagesMessage && (
          <div
            className="h-[40px] flex justify-center items-center text-2xl"
            style={{ marginBottom: -(height * 0.025) }}
          >
            + {moreImagesCount} more{' '}
            {moreImagesCount === 1 ? 'image' : 'images'}
          </div>
        )}
      </div>
    );

  return (
    <div
      className="w-full flex flex-col justify-between w-full"
      style={{
        height: height + (showMoreImagesMessage ? 40 : 0),
      }}
    >
      <div className="w-full flex flex-col justify-between" style={{ height }}>
        <div className="h-[calc(65%-5px)]">
          <Image src={selectedImages[0]} />
        </div>
        <div className="flex flex-row justify-between h-[calc(35%-5px)]">
          <div className="w-[calc(50%-5px)]">
            <Image src={selectedImages[1]} />
          </div>
          <div className="w-[calc(50%-5px)]">
            {selectedImages[2] && <Image src={selectedImages[2]} />}
          </div>
        </div>
      </div>
      {showMoreImagesMessage && (
        <div
          className="h-[40px] flex justify-center items-center text-2xl"
          style={{ marginBottom: -(height * 0.025) }}
        >
          + {moreImagesCount} more {moreImagesCount === 1 ? 'image' : 'images'}
        </div>
      )}
    </div>
  );
};
