import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

library.add(faChevronLeft, faChevronRight);

const QuotePagination = ({
  total,
  perPage,
  editQuote = '',
  groupId = 0,
  currentPage = 1,
  path,
  search,
}) => {
  // if no path set, default to quotes
  const currentPath = path && path !== '/' ? path : '/quotes/';
  const lastPage = total ? Math.ceil(total / perPage) : 1;
  const basePath = currentPath.replace(':page?', '');
  const nextPage = +currentPage + 1;
  const prevPage = +currentPage - 1;
  const nextPageLink = basePath + nextPage;
  const prevPageLink = basePath + prevPage;

  return (
    <div>
      <div className="container">
        <div className="row my-5">
          {currentPage > 1 && (
            <div className="col text-left">
              <Link
                to={{
                  pathname: prevPageLink,
                  search,
                  state: {
                    editQuote,
                    groupId,
                  },
                }}
                className="btn btn-primary pagination-prev-link"
              >
                <FontAwesomeIcon icon="chevron-left" className="" />
                <span className="pl-3">{`${perPage} Previous`}</span>
              </Link>
            </div>
          )}
          <div
            className={
              currentPage > 1 && !(currentPage >= lastPage)
                ? 'col text-center'
                : 'col'
            }
          ></div>
          {!(currentPage >= lastPage) && (
            <div className="col text-right">
              <Link
                to={{
                  pathname: nextPageLink,
                  search,
                  state: {
                    editQuote,
                    groupId,
                  },
                }}
                className="btn btn-primary pagination-next-link"
              >
                <span className="pr-3">{`${perPage} Next`}</span>
                <FontAwesomeIcon icon="chevron-right" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

QuotePagination.propTypes = {
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  editQuote: PropTypes.number,
  groupId: PropTypes.number,
  currentPage: PropTypes.number,
  path: PropTypes.string.isRequired,
  search: PropTypes.string,
};

QuotePagination.defaultProps = {
  groupId: 0,
  editQuote: null,
  currentPage: 1,
  search: '',
};

export default QuotePagination;
