const downloadText = (str = '', filename = 'log') => {
  // const uri = URL.createObjectURL(new Blob([JSON.stringify(str, null, 2)]));
  // const uri = encodeURIComponent(str);
  const uri = URL.createObjectURL(new Blob([str]));
  const a = document.createElement('a');
  a.setAttribute('href', uri);
  a.setAttribute('download', `${filename}.txt`);
  a.click();
};

const downloadTextFromList = (list = [], filename = 'log') => {
  const str = list.join('\n');
  console.log("str: ", str);
  // const uri = encodeURIComponent(str);
  const uri = URL.createObjectURL(new Blob([str]));
  const a = document.createElement('a');
  a.setAttribute('href', uri);
  a.setAttribute('download', `${filename}.txt`);
  a.click();
};

export { downloadText, downloadTextFromList };
