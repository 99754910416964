import { createAction } from '@reduxjs/toolkit';

const addMessage = createAction('ADD_MESSAGE');
const deleteMessage = createAction('DELETE_MESSAGE');

function showSuccessMessage(message) {
  return addMessage({ message, type: 'success' });
}

function showErrorMessage(message) {
  return addMessage({ message, type: 'error' });
}

export { showSuccessMessage, showErrorMessage, addMessage, deleteMessage };
