import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImageZoom from 'react-medium-image-zoom';
import { getImages } from '../../helpers/getImages';
import { productType } from '../../../../types';
import Bullets from '../Bullets';
import Information from '../Information';
import Finishes from '../Finishes';
import formatNum from '../../helpers/formatNum';
import DescriptionLine from './DescriptionLine';
import {
  updateNote,
  updateProductNote,
  notifyManager,
} from '../../../actions/quoteProducts';
import { getTaxonomyNames } from '../../taxonomies/taxonomyUtils';
import { getSettingsEnabledMap } from '../../../selectors/settings';
import toast from 'react-hot-toast';

const mapDispatchToProps = {
  updateNote,
  updateProductNote,
};

const mapStateToProps = ({ quote, ...state }) => {
  return {
    quote,
    currencySymbol: state.settings.currency,
    quoteSettings: getSettingsEnabledMap(state),
    currencyName: state.settings.currencyName,
  };
};

const ProductView = ({
  quote,
  product,
  showOptions,
  currencySymbol,
  greyOut,
  updateNote,
  updateProductNote,
  quoteSettings,
  currencyName: quoteCurrencyName,
  taxonomies,
  taxonomiesLoaded,
  firstInGroup,
}) => {
  const {
    id,
    product_name: productName,
    dimensions,
    bullets,
    warranty,
    brochure_title,
    brochure_url,
    swatches_title,
    swatches_url,
    lead_time,
    overwrite_lead_time,
    overwritten_lead_time,
    sell_price: sellPrice,
    cost_option = 'None',
    quantity = 1,
    finishes,
    client_note = '',
    image,
    first_img,
    currency,
    currency_rates: currencyRates,
  } = product;

  const [clientNoteValue, setClientNoteValue] = useState(client_note);

  let optionName = 'Optional';

  if (cost_option === 'A') {
    optionName = 'High';
  } else if (cost_option === 'B') {
    optionName = 'Low';
  } else if (cost_option === 'Both') {
    optionName = 'Both';
  }

  let productCurrency = currency || 'EUR';
  let rate = 1;

  if (currencyRates && currencyRates.length) {
    const rateObj = currencyRates.find(
      r => Object.keys(r)[0] === `${productCurrency}/${quoteCurrencyName}`
    );

    if (rateObj) {
      rate = Object.values(rateObj)[0];
    }
  }

  const { hide_price, client_notes_disabled } = quoteSettings || {};
  const fullImage = getImages(image, 'full')[first_img || 0];

  const information = [
    { title: brochure_title, url: brochure_url },
    { title: swatches_title, url: swatches_url },
  ];

  const total = quantity * Math.round(sellPrice * rate);

  const { leadTimes } = taxonomies || {};

  return (
    <div className="product-view">
      <div className="row d-md-flex align-items-md-stretch">
        <div
          className={`${
            client_notes_disabled ? 'col-md-12' : 'col-md-10'
          } product-view-columns`}
        >
          <div
            className={`list-group-item ${
              greyOut && (!cost_option || cost_option === 'None')
                ? 'greyed-out'
                : ''
            } ${showOptions ? 'options-shown' : ''}`}
          >
            <div className="row">
              <div
                className="image-column product-view-image-wrap product-view-image-wrap-small"
                style={{ paddingLeft: 0 }}
              >
                <div className="my_option_wrapper">
                  {(showOptions || (optionName === 'Optional' && greyOut)) && (
                    <span className="my_option">{optionName}</span>
                  )}
                </div>
                <div className="img-inner-wrap">
                  <ImageZoom
                    image={{
                      src: fullImage,
                      alt: productName,
                    }}
                    shouldReplaceImage={false}
                    shouldRespectMaxDimension
                  />
                </div>
              </div>
              <div className="description-column product-view-description-wrap">
                <span className="va-middle">
                  <h5>{productName}</h5>
                  <DescriptionLine content={dimensions} />
                  <Bullets bullets={bullets} />
                  <DescriptionLine content={warranty} />
                </span>
              </div>
              <div className="information-column product-view-information-wrap">
                <span className="va-middle">
                  <Information information={information} />
                  <span className="lead-time">
                    {!taxonomiesLoaded ? (
                      <span className="lead-time-loading">loading ...</span>
                    ) : overwrite_lead_time ? (
                      overwritten_lead_time ||
                      getTaxonomyNames(lead_time, leadTimes) ||
                      lead_time
                    ) : (
                      getTaxonomyNames(lead_time, leadTimes) || lead_time
                    )}
                  </span>
                  <Finishes finishes={finishes} context="view" />
                </span>
              </div>
              <div className="qty-column text-center product-view-quantity-wrap">
                <div className="row captions">QTY</div>
                <span className="va-middle">
                  {formatNum(quantity, false, false)}
                </span>
              </div>
              <div className="price-column text-center product-view-sell-price-wrap">
                <div className="row captions">Our Price</div>
                <span className="sell-price px-2 va-middle">
                  {hide_price
                    ? `-`
                    : formatNum(rate * sellPrice, currencySymbol)}
                </span>
              </div>
              <div className="total-column text-center product-view-total-wrap">
                <div className="row captions">Our Total</div>
                <span className="total px-2 va-middle">
                  {hide_price ? `-` : formatNum(total, currencySymbol)}
                </span>
              </div>
            </div>
          </div>
        </div>
        {!client_notes_disabled && (
          <div className="col-md-2 product-view-notes not-printable">
            <div className="captions text-center">Client Notes</div>
            <textarea
              name={id}
              className="form-control"
              style={{
                height: 'calc(100% - 32px)',
                resize: 'none',
                borderRadius: 0,
                borderTopWidth: firstInGroup ? undefined : 0,
              }}
              placeholder="Leave your feedback here ..."
              onChange={e => setClientNoteValue(e.target.value)}
              value={clientNoteValue}
            />
            <button
              className="btn btn-secondary btn-sm"
              style={{
                width: '100%',
                height: '32px',
                backgroundColor: '#F2F2F2',
                borderColor: '#D4D4D4',
                color: '#323232',
                borderTopWidth: 0,
                borderRadius: 0,
              }}
              onClick={async () => {
                const {
                  userId,
                  customerName,
                  quoteName,
                  slug,
                  randslug,
                } = quote;

                notifyManager({
                  userId,
                  customerName,
                  quoteName,
                  slug,
                  randslug,
                });

                try {
                  updateProductNote(quote.id, id, clientNoteValue);
                  toast.success('Successfully updated the note!');
                } catch (err) {
                  toast.error('Something went wrong when updating the note.');
                }
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ProductView.propTypes = {
  product: productType.isRequired,
  showOptions: PropTypes.bool.isRequired,
  updateNote: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  greyOut: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
