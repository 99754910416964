import { createReducer } from '@reduxjs/toolkit';

import {
  getOpportunities,
  setOpportunities,
  hideOpportunityLoader,
  selectOpportunity,
  showOpportunityLoader,
} from '../actions/opportunities';

const opportunities = createReducer(
  {
    opportunities: [],
    loaded: false,
    loading: false,
    selected: null,
  },
  {
    [setOpportunities]: (state, { payload }) => {
      state.opportunities = payload;
      state.loaded = true;
    },
    [getOpportunities]: (state, { payload }) => {
      state.opportunities = payload;
      state.loaded = true;
    },
    [showOpportunityLoader]: state => {
      state.loading = true;
    },
    [hideOpportunityLoader]: state => {
      state.loading = false;
    },
    [selectOpportunity]: (state, { payload }) => {
      state.selected = payload;
    },
  }
);

export default opportunities;
