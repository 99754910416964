import React from 'react';
import PropTypes from 'prop-types';

const GroupHeader = ({ parent_title, name, subtitle }) => (
  <header className="product-group-view-header">
    {parent_title && (
      <h3 className="product-group-view-parent-title">{parent_title}</h3>
    )}
    <h4 className="product-group-view-title">{name}</h4>
    {subtitle && <h5 className="product-group-view-subtitle">{subtitle}</h5>}
  </header>
);

GroupHeader.propTypes = {
  parent_title: PropTypes.string,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

GroupHeader.defaultProps = {
  parent_title: '',
  subtitle: '',
};

export default GroupHeader;
