import React, { createContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Products from './products/list/ProductsList';
import Taxonomies from './taxonomies/Taxonomies';
import ProductForm from './products/single/ProductFormContainer';
import Quote from './quotes/edit/Quote';
import QuoteList from './quotes/list/QuoteList';
import QuoteView from './quotes/view/QuoteView';
import Settings from './Settings';
import Redirect from './Redirect';
import NotFound from './404';
import apiWrapper from './helpers/apiWrapper';
import Button from './Button';
import withLoader from './withLoader';
import * as ReactDOM from 'react-dom';
import { CustomModal } from './shared/CustomModal';
import { Modal } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';

export const ModalContext = createContext();

const ButtonWithLoader = withLoader(Button);

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      show: false,
      title: null,
      content: null,
      actionButtons: null,
      isLarge: false,
      isVeryLarge: false,
      isAuthenticated: false,
    };
  }

  async componentWillMount() {
    try {
      const userId = await apiWrapper.callApiWithCredentials({
        url: '/auth/getCurrentUser',
      });

      const isAdmin = userId
        ? await apiWrapper.callApiWithCredentials({
            url: `/auth/isAdmin/${userId}`,
          })
        : false;

      const productsOnly = userId
        ? await apiWrapper.callApiWithCredentials({
            url: `/auth/productsOnly/${userId}`,
          })
        : false;

      this.setState({
        isAuthenticated: !!userId,
        isAdmin,
        productsOnly,
        ready: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  applyMigrations = async () => {
    if (!window.confirm('Have you made a database backup?')) {
      return false;
    }

    this.setState({ doingMigrations: true });
    const result = await apiWrapper.callApi('/api/migration/apply');

    if (result) {
      this.setState({
        hasMigrations: false,
        migrationsError: false,
        doingMigrations: false,
      });

      window.alert('All done!');
    } else {
      this.setState({
        doingMigrations: false,
        migrationsError:
          'There was an error applying migrations. Please try again',
      });
    }
  };

  render() {
    const {
      ready,
      isAuthenticated,
      isAdmin,
      productsOnly,
      hasMigrations,
      migrationsError,
      doingMigrations,
    } = this.state;

    if (!ready) {
      return null;
    }

    if (hasMigrations) {
      return (
        <div className="container">
          <div className="text-center">
            <p className="mt-5">
              There are pending database migrations. Please apply them to make
              the database compatible with the current version of the app.
              Please backup you database before proceed.
            </p>
            {migrationsError && (
              <p className="text-danger">{migrationsError}</p>
            )}
            <ButtonWithLoader
              onClick={this.applyMigrations}
              text="Apply Migrations"
              loaded={!doingMigrations}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <ModalContext.Provider
          value={{
            title: this.state.title,
            show: this.state.show,
            content: this.state.content,
            actionButtons: this.state.actionButtons,
            isLarge: this.state.isLarge,
            isVeryLarge: this.state.isVeryLarge,
            openModal: ({ title, content, actionButtons, isLarge, isVeryLarge }) =>
              this.setState({
                show: true,
                title: title,
                content: content,
                actionButtons: actionButtons,
                isLarge: isLarge,
                isVeryLarge: isVeryLarge
              }),
            closeModal: () => this.setState({ show: false }),
          }}
        >
          <BrowserRouter>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                loggedIn={isAuthenticated}
                isAdmin={isAdmin}
                productsOnly={productsOnly}
                component={QuoteList}
              />
              <PrivateRoute
                exact
                path="/products/edit/:id?"
                component={ProductForm}
                isAdmin={isAdmin}
                productsOnly={productsOnly}
                allowedForProductsOnly
                loggedIn={isAuthenticated}
              />
              <PrivateRoute
                path="/products/:page?"
                component={Products}
                isAdmin={isAdmin}
                productsOnly={productsOnly}
                allowedForProductsOnly
                loggedIn={isAuthenticated}
              />
              <PrivateRoute
                path="/quotes/edit/:id?"
                component={Quote}
                isAdmin={isAdmin}
                productsOnly={productsOnly}
                loggedIn={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/quotes/:page?"
                component={QuoteList}
                isAdmin={isAdmin}
                productsOnly={productsOnly}
                loggedIn={isAuthenticated}
              />
              <Route
                path="/:randslug([a-zA-Z0-9]{6})/:slug"
                component={QuoteView}
              />
              <PrivateRoute
                path="/taxonomies"
                component={Taxonomies}
                isAdmin={isAdmin}
                productsOnly={productsOnly}
                loggedIn={isAuthenticated}
                allowedForProductsOnly
              />
              <PrivateRoute
                exact
                path="/settings"
                loggedIn={isAuthenticated}
                isAdmin={isAdmin}
                productsOnly={productsOnly}
                adminOnly
                component={Settings}
              />

              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
          <Toaster />

          <CustomModal />
        </ModalContext.Provider>
      </>
    );
  }
}

export default App;
