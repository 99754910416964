import React from 'react';
import Logo from './Logo';

const NotFound = ({ title = '404', text = 'Not Found', location }) => {
  return (
    <div className="container text-center my-5">
      <Logo />
      <h1>{title}</h1>
      <h2>{text}</h2>
    </div>
  );
};

export default NotFound;
