/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Logo from '../../Logo';
import getImage from '../../helpers/getmage';
import moment from 'moment';

const HeaderView = ({
  quoteName,
  revision,
  date,
  isUk,
  isUS,
  selectedCustomer,
  managerName,
  logo,
}) => {
  const formattedDate = moment(date).format('Do MMMM yyyy');
  const logoImage = getImage(logo, 'full', false);

  return (
    <div>
      {/* pass title as props to avoid deepEqual stack overflow issue https://github.com/nfl/react-helmet/issues/373 */}
      <Helmet defer={false} title={`${quoteName}${isUk ? ' (UK)' : ''}`} />
      <Logo logo={logoImage} />
      <div className="row quote-details">
        <ul className="list-unstyled col col-md-6 quote-features">
          <li>
            <span className="label">Client: </span>
            <span className="value">{selectedCustomer}</span>
          </li>
          <li>
            <span className="label">Quote Name: </span>
            <span className="value">{quoteName}</span>
          </li>
          <li>
            <span className="label">Account Manager: </span>
            <span className="value">{managerName}</span>
          </li>
          <li>
            <span className="label">Revision: </span>
            <span className="value">{revision}</span>
          </li>
          <li>
            <span className="label">Date: </span>
            <span className="value">{formattedDate}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

HeaderView.propTypes = {
  selectedCustomer: PropTypes.string,
  managerName: PropTypes.string,
};

HeaderView.defaultProps = {
  selectedCustomer: '',
  managerName: '',
};

export default HeaderView;
