import React, { useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useCurrencies } from '../hooks/useCurrencies';
import { useProductComponents } from '../hooks/useProductComponents';
import { ComponentsFormRow } from './ComponentsFormRow';

export const ComponentsForm = ({
  components: comps,
  productCurrency,
  productId,
  isQuoteProduct,
  quoteId,
  onUpdate,
  handleProductChange,
}) => {
  const {
    addNewComponent,
    deleteComponent,
    saveComponents,
    changeNote,
    components,
    finalCostProductCurrency,
    note,
    changeValue,
  } = useProductComponents(
    comps,
    productCurrency,
    productId,
    isQuoteProduct,
    quoteId,
    onUpdate,
    handleProductChange
  );

  return (
    <div style={{ minWidth: '98%' }} className="components-container">
      <Container fluid className="components-titles">
        <Row>
          <Col xs={2}>
            Supplier (Top Input),
            <br />
            Manufacturer (Bottom Input)
          </Col>

          <Col xs={1}>Code</Col>

          <Col xs={1}>Description</Col>

          <Col xs={1}>Supplier RRP</Col>

          <Col xs={1}>Supplier % Discount</Col>

          <Col xs={1}>Supplier Cost</Col>

          <Col xs={1}>QTY</Col>

          <Col xs={1}>Total Supplier Cost</Col>

          <Col xs={1}>Supplier Currency</Col>

          <Col xs={1}>Total Supplier Cost Product Currency</Col>
          <Col xs={1}></Col>
        </Row>
        {components?.map(c => {
          return (
            <ComponentsFormRow
              key={c.id}
              component={c}
              onRemove={deleteComponent}
              onChangeValue={changeValue}
            />
          );
        })}
      </Container>
      <Container fluid>
        <Row>
          <Col xs={6} className="textarea-container">
            <textarea
              onChange={e => changeNote(e.target.value)}
              placeholder="Notes"
            >
              {note}
            </textarea>
          </Col>
          <Col xs={4} style={{ textAlign: 'end', paddingRight: 4 }}>
            Total Supplier Cost (product currency):
          </Col>
          <Col xs={1} style={{ paddingLeft: 4, fontWeight: 'bold' }}>
            {finalCostProductCurrency}
          </Col>
          <Col xs={1}>.</Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <button
              className="btn btn-link"
              onClick={addNewComponent}
              type="button"
            >
              + Add new row
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
