import { createAction } from '@reduxjs/toolkit';
import apiWrapper from '../../client/components/helpers/apiWrapper';

const setManagers = createAction('SET_MANAGERS');
const showManagersLoader = createAction('SHOW_MANAGERS_LOADER');
const hideManagersLoader = createAction('HIDE_MANAGERS_LOADER');
const selectManager = createAction('SELECT_MANAGER');
const getManagerNameByIdAction = createAction('GET_MANAGER_NAME');

const getManagerNameById = id => dispatch => {
  if (!id) {
    return dispatch(getManagerNameByIdAction(''));
  }

  return apiWrapper.callApi(`/auth/getUserName/${id}`).then(result => {
    return dispatch(getManagerNameByIdAction(result));
  });
};

export {
  setManagers,
  showManagersLoader,
  hideManagersLoader,
  selectManager,
  getManagerNameByIdAction,
  getManagerNameById,
};
