import React from 'react';

const Logo = ({ logo }) => {
  const displayedLogo = logo || '/Radius-Office-Site-Logo.png';
  return (
    <div className="text-center my-5">
      <div className="logo-wrap">
        <img src={displayedLogo} alt="Radius Office Logo" />
      </div>
    </div>
  );
};

export default Logo;
