import React from 'react';
import PropTypes from 'prop-types';

const Bullets = ({ bullets, className = 'list-unstyled', Tag = 'ul' }) => {
  if (!bullets) {
    return '';
  }

  return (
    <div>
      <Tag className={className}>
        {bullets.split(/\r?\n/).map((item, i) => {
          if (item) {
            return <li key={i}>{`${item}`}</li>;
          }
          return '';
        })}
      </Tag>
    </div>
  );
};

Bullets.propTypes = {
  bullets: PropTypes.string,
  className: PropTypes.string,
  Tag: PropTypes.string,
};

Bullets.defaultProps = {
  bullets: '',
  className: 'list-unstyled',
  Tag: 'ul',
};

export default Bullets;
