import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Input } from 'reactstrap';
import { loadSettings, updateSetting } from '../../../actions/settings';
import { getSettingsList } from '../../../selectors/settings';
import getImage from '../../helpers/getmage';
import { uploadQuoteLogo } from '../../../actions/quote';

const mapDispatchToProps = {
  loadSettings,
  updateSetting,
  uploadQuoteLogo,
};

const mapStateToProps = state => ({
  quote: state.quote,
  settings: getSettingsList(state),
});

const QuoteSettings = props => {
  const { quote, settings, loadSettings, quoteId } = props;
  const { logo, id } = quote;
  const logoImage = getImage(logo, 'full', false);

  useEffect(() => {
    loadSettings(quoteId);
  }, [loadSettings, quoteId]);

  const filteredSettings = (settings || []).filter(
    ({ slug }) => slug !== 'is_uk' && slug !== 'is_us'
  );

  const handleQuoteTypeChange = e => {
    const name = e.target.value;
    props.updateSetting(e.target.value, true, settings);
  };

  const isQuoteTypeOptionSelected = (option) => {
    if (option === "is_eu") {
      const is_uk = settings.find(({slug}) => slug === "is_uk")?.enabled;
      const is_us = settings.find(({slug}) => slug === "is_us")?.enabled; 
      return !is_uk && !is_us;
    }
    return settings.find(({slug}) => slug === option)?.enabled; 
  }

  console.log("settings: ", settings);

  return (
    <div className="jumbotron">
      <h2>Client Quote Settings</h2>
      <ul className="list-unstyled">
        <select
          name="quote_type"
          onChange={handleQuoteTypeChange}
          className="form-control mb-2"
        >
          <option value="is_uk" selected={isQuoteTypeOptionSelected("is_uk")}>UK Quote</option>
          <option value="is_us" selected={isQuoteTypeOptionSelected("is_us")}>US Quote</option>
          <option value="is_eu" selected={isQuoteTypeOptionSelected("is_eu")}>EU Quote</option>
        </select>
        {filteredSettings &&
          filteredSettings.map((setting, i) => (
            <li key={i}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name={setting.slug}
                    checked={setting.enabled}
                    onChange={e => {
                      const { name, checked } = e.target;
                      props.updateSetting(name, checked, settings);
                    }}
                  />
                  {setting.label}
                </Label>
              </FormGroup>
            </li>
          ))}
      </ul>
      <h3>Quote Logo</h3>
      {logo && (
        <div className="quote-logo">
          <img src={logoImage} alt="Quote Logo" />
        </div>
      )}
      <h4 className="upload-quote-logo-title">Upload a new logo</h4>
      <input
        type="file"
        name="quote-logo"
        accept="image/png, image/jpeg, image/gif"
        onChange={async e => {
          e.preventDefault();

          const { files } = e.target;
          const file = files[0];

          props.uploadQuoteLogo(file);
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSettings);
