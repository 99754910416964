import React, { useEffect, useState } from 'react';
import apiWrapper from '../helpers/apiWrapper';
import { getFilteredProducts } from '../helpers/getFilteredProducts';

const sortingOptions = [
  { value: ['cost_price', 'ASC'], label: 'Supplier Cost Price (Low to High)' },
  { value: ['dimensions', 'ASC'], label: 'Dimensions (A to Z)' },
  { value: ['product_name', 'ASC'], label: 'Product name (A to Z)' },
  { value: ['last_time_used', 'DESC'], label: 'Last Time Used (New to Old)' },
];

export const emptyInitialFilterState = {
  manufacturer: { value: null, label: 'All Manufacturers' },
  supplier: { value: null, label: 'All Suppliers' },
  leadTime: { value: null, label: 'All Lead Times' },
  tag: { value: null, label: 'All Tags' },
  sorting: {
    value: ['cost_price', 'ASC'],
    label: 'Supplier Cost Price (Low to High)',
  },
  searchText: '',
};

const NULL_VALUE = { value: 'NULL', label: 'NULL VALUE' };

const sortObjectArray = arrayToSort => {
  const sorted = arrayToSort.sort((a, b) => {
    if (!a.label || !b.label) return 0;
    if (a.label === b.label) return 0;
    return a.label.trim().toLowerCase() > b.label.trim().toLowerCase() ? 1 : -1;
  });
  return sorted;
};

export const useProductFilters = (
  currentPage,
  resetCurrentPage,
  cleanFilters = true
) => {
  const initialState = (() => {
    const initialFilters = JSON.parse(
      localStorage.getItem('productListFilters') || '{}'
    );
    return cleanFilters
      ? emptyInitialFilterState
      : initialFilters || emptyInitialFilterState;
  })();

  const [results, setResults] = useState(null);

  const [filterValues, setFilterValues] = useState({
    manufacturerOptions: [{ value: null, label: 'Manufacturer' }],
    supplierOptions: [{ value: null, label: 'Supplier' }],
    leadTimeOptions: [{ value: null, label: 'Lead Time' }],
    tagOptions: [{ value: null, label: 'Tag' }],
    sortingOptions,
  });

  useEffect(() => {
    apiWrapper.callApi(`/api/taxonomies`).then(res => {
      const taxonomies = (res?.taxonomies || []).filter(
        taxonomy => taxonomy.name
      );

      const manufacturerOptions = taxonomies
        .filter(taxonomy => taxonomy.type == 'manufacturer')
        .map(taxonomy => ({ value: taxonomy.id, label: taxonomy.name }));
      const supplierOptions = taxonomies
        .filter(taxonomy => taxonomy.type == 'supplier')
        .map(taxonomy => ({ value: taxonomy.id, label: taxonomy.name }));
      const leadTimeOptions = taxonomies
        .filter(taxonomy => taxonomy.type == 'lead_time')
        .map(taxonomy => ({ value: taxonomy.id, label: taxonomy.name }));
      const tagOptions = taxonomies
        .filter(taxonomy => taxonomy.type == 'tag')
        .map(taxonomy => ({ value: taxonomy.id, label: taxonomy.name }));

      setFilterValues({
        manufacturerOptions: [
          emptyInitialFilterState.manufacturer,
          NULL_VALUE,
          ...sortObjectArray(manufacturerOptions),
        ],
        supplierOptions: [
          emptyInitialFilterState.supplier,
          NULL_VALUE,
          ...sortObjectArray(supplierOptions),
        ],
        leadTimeOptions: [
          emptyInitialFilterState.leadTime,
          NULL_VALUE,
          ...sortObjectArray(leadTimeOptions),
        ],
        tagOptions: [
          emptyInitialFilterState.tag,
          NULL_VALUE,
          ...sortObjectArray(tagOptions),
        ],
        sortingOptions,
      });
    });
  }, [setFilterValues]);

  const [filters, setFilters] = useState(initialState);

  // useEffect(() => setFilters(initialState), [initialState]);

  const setManufacturer = value => {
    setFilters({ ...filters, manufacturer: value });
  };

  const setSupplier = value => {
    setFilters({ ...filters, supplier: value });
  };

  const setLeadTime = value => {
    setFilters({ ...filters, leadTime: value });
  };

  const setTag = value => {
    setFilters({ ...filters, tag: value });
  };

  const setSorting = value => {
    setFilters({ ...filters, sorting: value });
  };

  const setSearchText = value => {
    setFilters({ ...filters, searchText: value });
  };

  const getResults = async page =>
    getFilteredProducts(page, filters, setResults);

  useEffect(() => {
    resetCurrentPage();
    getResults(1);
  }, [
    filters.manufacturer,
    filters.supplier,
    filters.leadTime,
    filters.tag,
    filters.sorting,
    filters.searchText,
  ]);

  useEffect(() => {
    getResults(currentPage);
  }, [currentPage]);

  return {
    filters,
    filterValues,
    results,
    setManufacturer,
    setSupplier,
    setLeadTime,
    setTag,
    setSorting,
    setSearchText,
  };
  // return {show, openModal, closeModal};
};
