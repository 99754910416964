import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faImages,
  faTrashAlt,
  faShare,
  faCopy,
  faChevronUp,
  faChevronDown,
  faArrowUp,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faEdit,
  faImages,
  faTrashAlt,
  faShare,
  faCopy,
  faChevronUp,
  faChevronDown,
  faArrowUp,
  faPlus
);

const IconButton = ({
  onClick,
  title = '',
  style = {},
  icon,
  className = 'btn btn-link',
}) => (
  <button
    type="button"
    onClick={onClick}
    title={title}
    style={style}
    className={className}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
);

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  style: PropTypes.shape({}),
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  title: '',
  style: {},
  className: 'btn btn-link',
};

export default IconButton;
