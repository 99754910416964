import { createAction } from '@reduxjs/toolkit';
import apiWrapper from '../components/helpers/apiWrapper';
import { showErrorMessage } from './messages';

const loadGroupsAction = createAction('LOAD_GROUPS');
const resetGroups = createAction('RESET_GROUPS');
const updateGroupAction = createAction('UPDATE_GROUP');
const addGroupToRemoved = createAction('ADD_GROUP_TO_REMOVED');
const addGroupAction = createAction('ADD_GROUP');
const changeGroupPositionAction = createAction('MOVE_GROUP');
const saveGroupsAction = createAction('SAVE_GROUPS');
const deleteRemovedGroupsAction = createAction('DELETE_GROUPS');
const setGroupsLoading = createAction('SET_GROUPS_LOADING');
const unsetGroupsLoading = createAction('UNSET_GROUPS_LOADING');

const getGroups = quoteId => dispatch => {
  return apiWrapper
    .callApiPost('/api/groups/getGroups', {
      quoteId,
    })
    .then(result => {
      return dispatch(loadGroupsAction(result));
    });
};

const saveGroups = (groups, quoteId) => async dispatch => {
  const result = await apiWrapper.callApiPost(`/api/groups/updateGroups`, {
    groups,
    quoteId,
  });

  await dispatch(saveGroupsAction());

  return result;
};

const updateGroup = (id, update) => {
  return updateGroupAction({
    id,
    update,
  });
};

const addGroup = (quoteId, position) => async dispatch => {
  const groupToAdd = {
    position,
    name: 'New group',
    subtitle: '',
    parent_title: '',
  };

  const insertedGroupId = await apiWrapper.callApiPost(
    `/api/groups/insertGroup`,
    {
      quoteId,
      group: groupToAdd,
    }
  );

  if (!insertedGroupId || insertedGroupId.error) {
    return dispatch(showErrorMessage('There was error creating group'));
  }

  groupToAdd.id = insertedGroupId;

  return dispatch(addGroupAction(groupToAdd));
};

const changeGroupPosition = (position, newPosition) => {
  return changeGroupPositionAction({ position, newPosition });
};

const deleteRemovedGroups = (groupsToDelete, quoteId) => dispatch => {
  if (!groupsToDelete || !groupsToDelete.length) {
    return;
  }

  apiWrapper
    .callApiPost(`/api/groups/deleteRemovedGroups`, {
      groupsToDelete,
      quoteId,
    })
    .then(() => {
      return dispatch(deleteRemovedGroupsAction());
    });
};

export {
  getGroups,
  loadGroupsAction,
  resetGroups,
  updateGroupAction,
  updateGroup,
  addGroupToRemoved,
  addGroupAction,
  addGroup,
  changeGroupPositionAction,
  changeGroupPosition,
  saveGroupsAction,
  saveGroups,
  deleteRemovedGroupsAction,
  deleteRemovedGroups,
  setGroupsLoading,
  unsetGroupsLoading,
};
