import React from 'react';
import PropTypes from 'prop-types';

const EditProductFormFooter = ({ close, save }) => (
  <div className="container">
    <div className="row">
      <div className="col text-left">
        <button className="btn btn-link" type="button" onClick={close}>
          Back
        </button>
      </div>
      <div className="col text-right">
        <button type="button" className="btn btn-primary" onClick={save}>
          Save
        </button>
      </div>
    </div>
  </div>
);

EditProductFormFooter.propTypes = {
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default EditProductFormFooter;
