import apiWrapper from '../components/helpers/apiWrapper';

import {
  setManagers,
  showManagersLoader,
  hideManagersLoader,
  selectManager,
} from './managers';

const userTypes = {
  managers: 'managers',
};

const typeToEndpoint = {
  [userTypes.managers]: '/auth/getManagers',
};

const typeToLoader = {
  [userTypes.managers]: {
    showLoader: showManagersLoader,
    hideLoader: hideManagersLoader,
  },
};

const typeToSetUsersAction = {
  [userTypes.managers]: setManagers,
};

const typeToSelectAction = {
  [userTypes.managers]: selectManager,
};

const getUsers = type => async dispatch => {
  const endpoint = typeToEndpoint[type];
  const { showLoader, hideLoader } = typeToLoader[type];

  dispatch(showLoader());

  try {
    const loadedUsers = await apiWrapper.callApi(endpoint, 300000);

    if (loadedUsers.error) {
      throw new Error(loadedUsers.error);
    }

    dispatch(typeToSetUsersAction[type](loadedUsers));
  } catch (e) {
    console.log(e.message);
  } finally {
    dispatch(hideLoader());
  }
};

const selectUser = (type, value) => {
  return typeToSelectAction[type](value);
};

export { userTypes, getUsers, selectUser };
