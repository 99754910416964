import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../../Button';
import withLoader from '../../withLoader';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPdf } from "../../../actions/quote";
const ButtonWithLoader = withLoader(Button);

const FooterNav = ({
  save,
  saveAndQuotes,
  slug,
  randslug,
  goBack,
  loading,
}) => {
  const PdfUrl = `${
    process.env.REACT_APP_SITE
  }/api/quotes/generatePdf/?slug=${slug}&randslug=${randslug}`;

  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  const { generatingPdf } = quote;

  const handleDownloadPdf = useCallback(async () => {

    dispatch(downloadPdf(slug, randslug));

  }, [dispatch, slug, randslug]);

  return (
    <div className="d-flex justify-content-between py-5">
      <Button className="btn btn-default btn-lg" onClick={goBack} text="Back" />
      {slug && randslug && (
        <>
          <ButtonWithLoader
              onClick={handleDownloadPdf}
              className="btn btn-primary btn-lg"
              text="PDF"
              loaded={!generatingPdf}
            />
        </>
      )}
      {slug && randslug && (
        <Link
          to={`/${randslug}/${slug}`}
          target="_blank"
          className="btn btn-primary btn-lg"
        >
          View Client Quote
        </Link>
      )}
      <ButtonWithLoader
        className="btn btn-primary btn-lg"
        onClick={save}
        text="Save"
        loaded={!loading}
        hideOnLoading={false}
      />
      <ButtonWithLoader
        className="btn btn-primary btn-lg"
        onClick={saveAndQuotes}
        text="Save & Quotes"
        loaded={!loading}
        hideOnLoading={false}
      />
    </div>
  );
};

FooterNav.propTypes = {
  save: PropTypes.func.isRequired,
  saveAndQuotes: PropTypes.func.isRequired,
  slug: PropTypes.string,
  randslug: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

FooterNav.defaultProps = {
  slug: null,
  randslug: null,
  loading: null,
};

export default FooterNav;
