import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Group from './Group';
import GroupView from './view/GroupView';
import { groupType, productType } from '../../../types';

import {
  updateGroup,
  addGroup,
  changeGroupPosition,
  deleteRemovedGroups,
} from '../../actions/groups';

const mapDispatchToProps = {
  updateGroup,
  addGroup,
  changeGroupPosition,
  deleteRemovedGroups,
};

function mapStateToProps({ quote, quoteProducts, groups }) {
  return {
    quote,
    groups: groups.groups,
    products: quoteProducts.products,
  };
}

const GroupsDisplay = ({
  quote,
  addGroup,
  openModal,
  deleteProduct,
  context = 'edit',
  showOptions,
  greyOut,
  groups,
  products,
  updateNote,
  taxonomies,
  taxonomiesLoaded,
}) => {
  const isEditContext = context === 'edit';
  const GroupComponent = isEditContext ? Group : GroupView;
  const groupsCount = groups.length;
  // these are used only for editing, no need to pass in in view context
  const contextSpecificProps = isEditContext
    ? {
        openModal,
        deleteProduct,
        groupsCount,
      }
    : {
        updateNote,
      };

  const createGroup = () => {
    addGroup(quote.id, groups.length);
  };

  console.log("products: ", products);

  return (
    <>
      {groups.map((group, i) => {
        const { id } = group;

        const thisGroupProducts = products.filter(
          product => product.group_id == id
        );

        const { name, subtitle, parent_title } = group;

        return (
          <GroupComponent
            name={name}
            subtitle={subtitle}
            parent_title={parent_title}
            thisGroupProducts={thisGroupProducts}
            key={id}
            id={id}
            position={i + 1} // position starts from 1
            showOptions={showOptions}
            greyOut={greyOut}
            taxonomies={taxonomies}
            taxonomiesLoaded={taxonomiesLoaded}
            {...contextSpecificProps}
          />
        );
      })}
      {isEditContext && (
        <div className="group-nav">
          <button
            type="button"
            className="btn btn-primary my-3"
            onClick={createGroup}
          >
            Add Group
          </button>
        </div>
      )}
    </>
  );
};

GroupsDisplay.propTypes = {
  groups: PropTypes.arrayOf(groupType).isRequired,
  groupsCount: PropTypes.number,
  thisGroupProducts: PropTypes.arrayOf(productType),
  updateGroup: PropTypes.func,
  moveGroup: PropTypes.func,
  openModal: PropTypes.func,
  deleteProduct: PropTypes.func,
  duplicateProduct: PropTypes.func,
  context: PropTypes.string,
  showOptions: PropTypes.bool,
  greyOut: PropTypes.bool,
};

GroupsDisplay.defaultProps = {
  products: [],
  greyOut: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsDisplay);
