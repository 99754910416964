import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { getWelcomeText } from '../../../actions/quote';

const mapStateToProps = ({ quote }) => ({
  welcome: quote.welcome,
});

const mapDispatchToProps = {
  getWelcomeText,
};

const Welcome = ({ welcome, getWelcomeText }) => {
  React.useEffect(() => {
    getWelcomeText();
  }, []);

  const [modalOpen, setModalOpen] = React.useState(true);

  if (!welcome) {
    return null;
  }

  return (
    <Modal
      size="lg"
      className="welcome-modal"
      isOpen={modalOpen}
      toggle={() => setModalOpen(false)}
    >
      <ModalBody>{welcome}</ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-primary"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setModalOpen(false)}
        >
          Ok
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
