import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  type = 'button',
  className = 'btn btn-primary',
  onClick,
  text,
}) => (
  // eslint-disable-next-line react/button-has-type
  <button type={type} className={className} onClick={onClick}>
    {text}
  </button>
);

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

Button.defaultProps = {
  type: 'edit',
  className: 'btn btn-primary',
};

export default Button;
