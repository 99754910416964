/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import settingsDefaultState from '../defaultState/settings';

import {
  loadSettingsAction,
  updateSettingAction,
} from '../actions/settings';

const { REACT_APP_CURRENCY_SYMBOL, REACT_APP_CURRENCY_SYMBOL_UK } = process.env;

const settings = createReducer(settingsDefaultState, {
  [loadSettingsAction]: (state, { payload }) => {
    let currency, currencyName;

    if (payload.settings.is_uk.enabled) {
      currency = REACT_APP_CURRENCY_SYMBOL_UK;
      currencyName = 'GBP';
    } else if (payload.settings.is_us.enabled) {
      currency = '$';
      currencyName = 'USD';
    } else {
      currency = REACT_APP_CURRENCY_SYMBOL;
      currencyName = 'EUR';
    }

    return {
      settings: payload.settings,
      currency: currency,
      currencyName,
    };
  },

  [updateSettingAction]: (state, { payload }) => {
    const { name, checked } = payload;

    if (name === 'is_us') {
      state.settings['is_us'].enabled = true;
      state.settings['is_uk'].enabled = false;
    } else if (name === 'is_uk') {
      state.settings['is_us'].enabled = false;
      state.settings['is_uk'].enabled = true;
    } else if (name === 'is_eu') {
      state.settings['is_us'].enabled = false;
      state.settings['is_uk'].enabled = false;
    } else {
      state.settings[name].enabled = checked ? 1 : 0;
    }
  },
});

export default settings;
