import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import classnames from 'classnames';

export const ModalImage = ({
  image,
  removeImage,
  selectImage,
  unselectImage,
  index,
  chosenImages,
}) => {
  const [hovered, setHovered] = useState(false);
  const indexToShow = (() => {
    if (chosenImages.first === index) return 1;
    if (chosenImages.second === index) return 2;
    if (chosenImages.third === index) return 3;
  })();

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="relative flex flex-row justify-center items-center w-[17%] h-[240px] overflow-hidden mb-[30px] border-solid border-[1px] border-grey rounded mx-[1.5%] bg-grey-light px-3"
    >
      <div
        className={classnames(
          'absolute h-full w-full top-0 left-0 bg-primary transition-opacity z-[50]',
          hovered ? 'opacity-[10%]' : 'opacity-0'
        )}
      />
      {indexToShow && (
        <div className="absolute top-0 right-0 mr-3 text-[40px] font-bold z-[100]">
          {indexToShow}
        </div>
      )}
      <img
        alt="product"
        src={image}
        className={classnames(
          'h-auto mx-auto rounded transition-transform',
          hovered ? 'scale-[1.15]' : ''
        )}
      />
      <div
        className={classnames(
          'absolute flex flex-col items-center justify-center w-full bottom-0 left-0 transition-opacity pb-4 px-4 z-[60]',
          hovered ? 'opacity-[100%]' : 'opacity-0'
        )}
      >
        <div className="w-full flex flex-row justify-between items-center">
          <Button
            className="w-[30%] text-center"
            variant="primary"
            onClick={() => selectImage(1)}
          >
            1
          </Button>
          <Button
            className="w-[30%] text-center"
            variant="primary"
            onClick={() => selectImage(2)}
          >
            2
          </Button>
          <Button
            className="w-[30%] text-center"
            variant="primary"
            onClick={() => selectImage(3)}
          >
            3
          </Button>
        </div>
        <Button
          className="w-full mt-2"
          variant="primary"
          onClick={unselectImage}
        >
          Unselect
        </Button>
        <Button className="w-full mt-2" variant="primary" onClick={removeImage}>
          Delete
        </Button>
      </div>
    </div>
  );
};
