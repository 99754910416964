import React, { useEffect, useMemo, useState, useCallback } from "react";
import apiWrapper from '../helpers/apiWrapper';


export const useCurrencies = () => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    apiWrapper.callApi(`/api/settings/currencies`)
      .then(res => {
        setCurrencies(res)
      })
  }, [setCurrencies]);

  const changeCurrency = (id, value) => {
    const changedCurrencies = currencies.map((c) => {
      if(c.id === id) {
        return {
          ...c,
          rate: value
        }
      } 

      return c;
    })
    setCurrencies(changedCurrencies)
  }

  const saveCurrencies = async () => {
    try {
      const result = await apiWrapper.callApiPost(`/api/settings/currencies`, currencies);
      
      setCurrencies(result);
    } catch(e) {}
  }

  const currenciesOptions = useMemo(() => {
    return Object.keys(currencies.reduce((acc, c) => {
      if(acc[c.from] === c.from) return acc;
      
      acc[c.from] = c.from;

      return acc;
    }, {})).map((c) => {
      return {
        label: c,
        value: c
      }
    })
  }, [currencies]);

  const getPairRate = useCallback((from, to) => {
    if (currencies && currencies.length) {
      const rate = currencies.find(c => c.from === from && c.to === to)?.rate;
      
      if (rate) return rate;
      else return 1;
    } else {
      return 1;
    }
  }, [currencies])

  return {
    currencies,
    currenciesOptions,
    getPairRate,
    changeCurrency,
    saveCurrencies
  }
}