import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../../Button';
import withLoader from '../../withLoader';

const ButtonWithLoader = withLoader(Button);

const ProductFormFooter = ({ loading, handleSubmit, editQuote, groupId, filters }) => (
  <div className="d-flex justify-content-between my-3">
    <Link
      className="btn btn-secondary"
      to={{
        pathname: '/products',
        state: {
          editQuote,
          groupId,
          filters,
          cleanFilters: false
        },
      }}
    >
      Back
    </Link>

    <ButtonWithLoader
      type="button"
      className="btn btn-primary submit-product-form"
      text="Save & Products"
      loaded={!loading}
      onClick={handleSubmit}
    />
  </div>
);
ProductFormFooter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  editQuote: PropTypes.number,
};

ProductFormFooter.defaultProps = {
  editQuote: null,
};

export default ProductFormFooter;
