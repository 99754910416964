import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import './index.css';
import App from './client/components/App';
import AlertContainer from './client/components/AlertContainer';
import * as serviceWorker from './serviceWorker';

import store from './client/store';

import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
  <AlertProvider template={AlertTemplate} containerStyle={{ zIndex: 9999 }}>
    <DragDropContextProvider backend={HTML5Backend}>
      <Provider store={store}>
        <AlertContainer>
          <App />
        </AlertContainer>
      </Provider>
    </DragDropContextProvider>
  </AlertProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
