import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import apiWrapper from '../helpers/apiWrapper';

export const useGetTaxonomies = () => {
  const [taxonomies, setTaxonomies] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getRemoteTaxonomies = async () => {
    try {
      const result = await apiWrapper.callApi(`/api/taxonomies`);
      setTaxonomies(
        (result?.taxonomies || []).filter(taxonomy => taxonomy.name)
      );
      setTimeout(() => setLoaded(true), 1000);
    } catch (err) {}
  };

  useAsyncEffect(getRemoteTaxonomies, []);

  const manufacturers = taxonomies.filter(
    taxonomy => taxonomy.type == 'manufacturer'
  );
  const suppliers = taxonomies.filter(taxonomy => taxonomy.type == 'supplier');
  const leadTimes = taxonomies.filter(taxonomy => taxonomy.type == 'lead_time');
  const tags = taxonomies.filter(taxonomy => taxonomy.type == 'tag');

  return { manufacturers, suppliers, leadTimes, tags, loaded };
};
