import React from 'react';
import { connect } from 'react-redux';
import { RIENumber } from 'riek';
import CalculationsOption from './CalculationsOption';
import formatNum from '../../helpers/formatNum';
import { subtotalType } from '../../../../types';
import { updateCosts, updateVATRate } from '../../../actions/quote';

const mapStateToProps = ({ quote, settings }) => ({
  quote,
  currency: settings.currency,
});

const mapDispatchToProps = {
  updateCosts,
  updateVATRate
};

const Calculations = ({ quote, updateCosts, updateVATRate, subtotal, currency }) => {
  console.log("quote: ", quote);
  const { costs, delivery: deliverySell, vatRate } = quote;
  const { install: installCost, other: otherCost } = costs;
  const { A, B, AC, BC } = subtotal;

  return (
    <div className="jumbotron">
      <h2>Calculations</h2>
      <ul className="list-unstyled">
        <li>
          Install Cost: {currency}
          <RIENumber
            value={formatNum(installCost, false)}
            change={updateCosts}
            propName="install"
            className="editable"
          />
        </li>
        <li>
          Other Cost: {currency}
          <RIENumber
            value={formatNum(otherCost, false)}
            change={updateCosts}
            propName="other"
            className="editable"
          />
        </li>
        <li>
          VAT Rate (%) 
          <RIENumber
            value={vatRate === -1 ? "NaN" : formatNum(vatRate, false)}
            change={updateVATRate}
            propName="vatRate"
            className="editable"
          />
        </li>
      </ul>
      <CalculationsOption
        currency={currency}
        title="Low"
        subtotal={B}
        subtotalCost={BC}
        deliverySell={deliverySell.B}
        installCost={installCost}
        otherCost={otherCost}
      />
      <CalculationsOption
        currency={currency}
        title="High"
        subtotal={A}
        subtotalCost={AC}
        deliverySell={deliverySell.A}
        installCost={installCost}
        otherCost={otherCost}
      />
    </div>
  );
};

Calculations.propTypes = {
  subtotal: subtotalType.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculations);
