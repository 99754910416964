import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RIEInput } from 'riek';
import { findDOMNode } from 'react-dom';
import flow from 'lodash/flow';
import { DragSource, DropTarget } from 'react-dnd';
import { withAlert } from 'react-alert';
import Bullets from '../Bullets';
import { getImages } from '../../helpers/getImages';
import Information from '../Information';
import Finishes from '../Finishes';
import EditableTextarea from '../EditableTextarea';
import IconButton from '../../IconButton';
import formatNum, { formatPercent } from '../../helpers/formatNum';
import {
  calculateMarkUp,
  calculateSellPrice,
  getImportCosts,
  getLandedCost,
} from '../../helpers/costs';
import { productType } from '../../../../types';
import calculateDisplayedCostPrice from '../../helpers/calculateDisplayedCostPrice';
import { DuplicateButton } from './DuplicateButton';
import { getTaxonomyNames } from '../../taxonomies/taxonomyUtils';
import { useGetTaxonomies } from '../../taxonomies/useGetTaxonomies';

import {
  updateProduct,
  saveEditedProduct,
  uploadFinishes,
  moveProduct,
  deleteProduct,
  duplicateProductAction,
  updateMainProduct,
  createNewProduct,
} from '../../../actions/quoteProducts';

const mapDispatchToProps = {
  updateProduct,
  saveEditedProduct,
  uploadFinishes,
  moveProduct,
  deleteProduct,
  duplicateProductAction,
  updateMainProduct,
  createNewProduct,
};

function mapStateToProps({ quoteProducts, groups, settings }) {
  return {
    groups: groups.groups,
    products: quoteProducts.products,
    currencySymbol: settings.currency,
    currencyName: settings.currencyName,
  };
}

const productSource = {
  beginDrag(props) {
    return {
      index: props.index,
      listId: props.listId,
      product: props.product,
    };
  },
};

const { REACT_APP_CURRENCY_SYMBOL, REACT_APP_CURRENCY_SYMBOL_UK } = process.env;

export const currencySymbolToValue = {
  [REACT_APP_CURRENCY_SYMBOL]: 'EUR',
  [REACT_APP_CURRENCY_SYMBOL_UK]: 'GBP',
  $: 'USD',
};

export const productTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const sourceListId = monitor.getItem().listId;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    if (props.listId === sourceListId) {
      const { products, groups } = props;
      const currentPosition = products.findIndex(
        product => product.id === monitor.getItem().product.id
      );
      const positionShift = hoverIndex - dragIndex;

      props.moveProduct(products, groups, currentPosition, positionShift);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
};

const collectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const collectTarget = connect => ({
  connectDropTarget: connect.dropTarget(),
});

class Product extends React.PureComponent {
  updateField = (name, value) => {
    const {
      product: { id },
    } = this.props;

    this.props.updateProduct(id, {
      [name]: value,
    });
  };

  updateOriginal = () => {
    const { product, alert } = this.props;

    this.props.updateMainProduct(product, alert);
  };

  createNew = () => {
    const { product, alert } = this.props;

    this.props.createNewProduct(product, alert);
  };

  handleChange = e => {
    const { name, value } = e.target;

    this.updateField(name, value);
  };

  changeClientNote = value => this.updateField('client_note', value);

  changeInternalNote = value => this.updateField('internal_note', value);

  edit = field => {
    const updatedFieldName = Object.keys(field)[0];
    const updatedFieldValue = field[updatedFieldName];

    this.updateField(updatedFieldName, updatedFieldValue);
  };

  editAndSanitize = field => {
    const updatedFieldName = Object.keys(field)[0];
    const updatedFieldValue = field[updatedFieldName]
      .toString()
      .replace(/,/g, '');

    this.updateField(updatedFieldName, updatedFieldValue);
  };

  editMarkup = field => {
    const [value] = Object.values(field);
    const {
      cost_price,
      percentage_increase,
      fixed_price_surcharge,
    } = this.props.product;

    const sellPrice = calculateSellPrice(
      +cost_price,
      +fixed_price_surcharge,
      +percentage_increase,
      parseInt(value)
    );

    this.edit({ markupPercent: value });
    this.edit({ sell_price: sellPrice });
  };

  editSellPrice = field => {
    const [value] = Object.values(field);
    const {
      cost_price,
      percentage_increase,
      fixed_price_surcharge,
    } = this.props.product;

    const markUp = calculateMarkUp(
      parseInt(value),
      +cost_price,
      +fixed_price_surcharge,
      +percentage_increase
    );

    this.edit({ markupPercent: markUp });
    this.edit({ sell_price: value });
  };

  editBullets = value => {
    this.updateField('bullets', value);
  };

  editInModal = () => {
    const { openModal, product } = this.props;
    openModal(product, 'editProduct');
  };

  addFinishes = () => {
    const { openModal, product } = this.props;
    openModal(product, 'finishes');
  };

  deleteThisProduct = () => {
    const {
      product: { id },
      deleteProduct,
    } = this.props;
    deleteProduct(id);
  };

  duplicateProduct = withFinishes => {
    const { product } = this.props;
    this.props.duplicateProductAction({ ...product, withFinishes });
  };

  moveDown = () => {
    const { products, groups, product } = this.props;

    const position = products.findIndex(item => item.id === product.id);

    this.props.moveProduct(products, groups, position, 1);
  };

  moveUp = () => {
    const { products, groups, product } = this.props;

    const position = products.findIndex(item => item.id === product.id);

    this.props.moveProduct(products, groups, position, -1);
  };

  render() {
    const {
      connectDragSource,
      connectDropTarget,
      currencyName: quoteCurrencyName,
      taxonomies,
      taxonomiesLoaded,
    } = this.props;

    const { leadTimes } = taxonomies || {};

    const {
      product: {
        product_name,
        sku,
        dimensions,
        bullets,
        warranty,
        sell_price,
        cost_price,
        fixed_price_surcharge,
        percentage_increase,
        cost_option,
        quantity: qty,
        brochure_title,
        brochure_url,
        swatches_title,
        swatches_url,
        lead_time,
        overwrite_lead_time,
        overwritten_lead_time,
        supplier_price_list_url,
        suggested_direct_markup,
        suggested_trade_markup,
        finishes,
        currency,
        tmp_id = false,
        uploadingImage,
        uploadingFinishes,
        image: productImage,
        first_img,
        currency_rates: currencyRates,
        client_note = '',
        internal_note = '',
      },
      currencySymbol,
    } = this.props;
    const image = getImages(productImage)[first_img || 0];
    const quantity = qty || 1;
    const displayedCostPrice = calculateDisplayedCostPrice(
      +cost_price,
      +percentage_increase,
      +fixed_price_surcharge
    );

    const profit = sell_price - (displayedCostPrice || 0);
    const markUpPercent = calculateMarkUp(
      +sell_price,
      +cost_price,
      +fixed_price_surcharge,
      +percentage_increase
    );
    const importCostPerItem = getImportCosts(
      +cost_price,
      +fixed_price_surcharge,
      +percentage_increase
    );
    const landedCostPerItem = getLandedCost(
      +cost_price,
      +fixed_price_surcharge,
      +percentage_increase
    );

    const options = [
      { label: 'Low', value: 'B' },
      { label: 'High', value: 'A' },
      { label: 'Both' },
      { label: 'None' },
    ];
    const information = [];

    const total = quantity * sell_price;

    if (brochure_title && brochure_url) {
      information.push({ title: brochure_title, url: brochure_url });
    }

    if (swatches_title && swatches_url) {
      information.push({ title: swatches_title, url: swatches_url });
    }

    let productCurrency = currency || 'EUR';
    let rate = 1;

    if (currencyRates && currencyRates.length) {
      const rateObj = currencyRates.find(
        r => Object.keys(r)[0] === `${productCurrency}/${quoteCurrencyName}`
      );

      if (rateObj) {
        rate = Object.values(rateObj)[0];
      }
    }

    const totalProfit = quantity * Math.round(profit * rate);
    const totalImportCost = quantity * Math.round(importCostPerItem * rate);
    const totalSellPrice = quantity * Math.round(sell_price * rate);
    const totalSupplierCost = quantity * Math.round(cost_price * rate);
    const totalLandedCost = quantity * Math.round(landedCostPerItem * rate);

    return connectDragSource(
      connectDropTarget(
        <li className="list-group-item product">
          <div className="row">
            <div className="col col-md-3 product-image-wrap">
              <div className="img-inner-wrap">
                <img src={image} alt={product_name} />
                {uploadingImage && (
                  <span className="image-loader">
                    <img
                      src="/ajax-loader.gif"
                      alt="Loading spinner"
                      className="spinner"
                    />
                  </span>
                )}
              </div>
              {/* TODO: filter empty finishes and display finishes row if there are finishes */}
              <div className="row">
                <div className="col col-md-12 text-right upload-finishes-wrap d-flex justify-content-center">
                  <div className="finishes-inner-wrap">
                    <Finishes finishes={finishes} />
                    {uploadingFinishes && (
                      <span className="image-loader">
                        <img
                          src="/ajax-loader.gif"
                          alt="Loading spinner"
                          className="spinner"
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col col-md-4"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div className="row">
                <h2 className="col col-md-12 product-title">
                  <RIEInput
                    value={product_name}
                    propName="product_name"
                    change={this.edit}
                    className="editable"
                  />
                  {sku && ' ('}
                  {sku && (
                    <RIEInput
                      value={sku}
                      propName="sku"
                      change={this.edit}
                      className="editable"
                    />
                  )}
                  {sku && ' )'}
                </h2>
              </div>
              <div className="row">
                <div className="col col-md-12">
                  {dimensions && (
                    <RIEInput
                      value={dimensions}
                      propName="dimensions"
                      change={this.edit}
                      className="editable"
                    />
                  )}
                  <br />
                  {bullets && (
                    <EditableTextarea value={bullets} update={this.editBullets}>
                      <Bullets bullets={bullets} />
                    </EditableTextarea>
                  )}
                  {warranty && (
                    <RIEInput
                      value={warranty}
                      propName="warranty"
                      change={this.edit}
                      className="editable"
                    />
                  )}
                </div>
              </div>
              <div
                className="row"
                style={{ marginTop: 10, paddingBottom: 10, flexGrow: 1 }}
              >
                <div className="col col-md-6" style={{ height: '100%' }}>
                  <div className="quote-edit-note-header">Internal Notes</div>
                  <textarea
                    className="form-control quote-edit-note-textarea"
                    style={{ height: 'calc(100% - 20px)' }}
                    onChange={e => this.changeInternalNote(e.target.value)}
                    value={internal_note}
                  />
                </div>
                <div className="col col-md-6" style={{ height: '100%' }}>
                  <div className="quote-edit-note-header">External Notes</div>
                  <textarea
                    className="form-control quote-edit-note-textarea"
                    style={{ height: 'calc(100% - 20px)' }}
                    onChange={e => this.changeClientNote(e.target.value)}
                    value={client_note}
                  />
                </div>
              </div>
            </div>
            <div className="col col-md-5">
              <div className="row">
                <div className="col-md-5">
                  <select
                    name="cost_option"
                    onChange={this.handleChange}
                    defaultValue={cost_option || 'None'}
                    className="form-control"
                  >
                    {options.map(item => {
                      const { label, value = label } = item;

                      return (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-7 edit-delete-product-wrap">
                  {/*
                  <IconButton
                    onClick={this.createNew}
                    icon="plus"
                    title="Create new product"
                  />
                  <IconButton
                    onClick={this.updateOriginal}
                    icon="arrow-up"
                    title="Update main product"
                  />
                  */}
                  {!tmp_id && (
                    <IconButton
                      onClick={this.addFinishes}
                      icon="images"
                      title="Edit Finishes"
                    />
                  )}
                  {/* < <IconButton
                    onClick={this.duplicateProduct}
                    icon="copy"
                    title="Duplicate Product"
                  />> */}
                  <DuplicateButton handleDuplicate={this.duplicateProduct} />
                  <IconButton
                    onClick={this.editInModal}
                    icon="edit"
                    title="Edit Product"
                  />
                  <IconButton
                    onClick={this.deleteThisProduct}
                    icon="trash-alt"
                    title="Delete product from the quote"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-md-12">
                  <div className="row">
                    <div className="col col-md-5 p0">
                      <p className="prices mt-2">
                        Mark-Up (%):{' '}
                        <RIEInput
                          value={formatPercent(markUpPercent)}
                          propName="markUpPercent"
                          change={this.editMarkup}
                          className="editable"
                        />
                      </p>
                      <p className="mt-2 fs-12">
                        {suggested_direct_markup && (
                          <>
                            <label>Suggested (direct):</label>
                            <label>{suggested_direct_markup}%</label>
                          </>
                        )}
                      </p>
                      <p className="mt-2 fs-12">
                        {suggested_trade_markup && (
                          <>
                            <label>Suggested (trade):</label>
                            <label>{suggested_trade_markup}%</label>
                          </>
                        )}
                      </p>
                      {information.length !== 0 && (
                        <div className="quote-edit-information-wrap">
                          <Information information={information} />
                        </div>
                      )}
                      {!taxonomiesLoaded ? (
                        <div className="mt-2">
                          <span className="lead-time-loading">loading ...</span>
                        </div>
                      ) : (
                        <>
                          <div className="mt-2">
                            <span
                              className={
                                overwrite_lead_time ? 'text-disabled' : ''
                              }
                            >
                              {getTaxonomyNames(lead_time, leadTimes) ||
                                lead_time ||
                                'NO LEAD TIME'}
                            </span>
                          </div>
                          <div
                            className="mt-1 d-flex justify-content-start"
                            style={{ width: '100%' }}
                          >
                            <div className="d-flex justify-content-end align-items-center fs-12">
                              <span className="mr-2">Overwrite lead time</span>
                              <input
                                type="checkbox"
                                name={'overwrite_lead_time'}
                                onChange={e =>
                                  this.updateField(
                                    'overwrite_lead_time',
                                    e.target.checked
                                  )
                                }
                                checked={overwrite_lead_time}
                              />
                            </div>
                          </div>
                          <div className="mt-1">
                            {!!overwrite_lead_time && (
                              <input
                                min={1}
                                name="overwritten_lead_time"
                                value={overwritten_lead_time}
                                className="form-control"
                                onChange={this.handleChange}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col col-md-7">
                      <ul className="cost-calculations list-unstyled">
                        <li></li>
                        <li className="small-text">
                          {`Per Item Supplier Cost: ${formatNum(
                            Math.round(cost_price * rate),
                            currencySymbol
                          )}`}
                        </li>
                        <li className="small-text">
                          {`Per Item Import Cost: ${formatNum(
                            Math.round(importCostPerItem * rate),
                            currencySymbol
                          )}`}
                        </li>
                        <li className="small-text">
                          {`Per Item Landed Cost: ${formatNum(
                            Math.round(landedCostPerItem * rate),
                            currencySymbol
                          )}`}
                        </li>
                        <li className="small-text">
                          {`Per Item Sell Price: ${formatNum(
                            Math.round(sell_price * rate),
                            currencySymbol
                          )}`}
                        </li>
                        <li className="small-text">
                          {`Per Item Profit: ${formatNum(
                            Math.round(profit * rate),
                            currencySymbol
                          )}`}
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-3 quote-product-qty-label">
                              QTY:
                            </div>
                            <div className="col-md-8">
                              <input
                                min={1}
                                name="quantity"
                                type="number"
                                value={quantity}
                                className="form-control quote-product-qty-input"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="small-text">
                          {`Total Supplier Cost: ${formatNum(
                            totalSupplierCost,
                            currencySymbol
                          )}`}
                        </li>
                        <li className="small-text">
                          {`Total Import Cost: ${formatNum(
                            totalImportCost,
                            currencySymbol
                          )}`}
                        </li>
                        <li className="small-text">
                          {`Total Landed Cost: ${formatNum(
                            totalLandedCost,
                            currencySymbol
                          )}`}
                        </li>
                        <li className="small-text">
                          {`Total Sell Price: `}
                          {`${formatNum(totalSellPrice, currencySymbol)}`}
                        </li>
                        <li className="small-text">
                          {`Total Profit: ${formatNum(
                            totalProfit,
                            currencySymbol
                          )}`}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="up-and-down-buttons-block">
            <IconButton
              onClick={this.moveUp}
              icon="chevron-up"
              title="Move Up"
            />
            <IconButton
              onClick={this.moveDown}
              icon="chevron-down"
              title="Move Down"
            />
          </div>
        </li>
      )
    );
  }
}

Product.propTypes = {
  product: productType.isRequired,
  update: PropTypes.func.isRequired,
  updateMainProduct: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  updatePosition: PropTypes.func.isRequired,
};

export default flow(
  DragSource('product', productSource, collectSource),
  DropTarget('product', productTarget, collectTarget),
  connect(mapStateToProps, mapDispatchToProps),
  withAlert()
)(Product);
