import React from 'react';
import PropTypes from 'prop-types';
import { childrenType } from '../../../types';

class EditableTextarea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.textarea = React.createRef();

    this.timeoutID = null;
    this.state = { edit: false };
  }

  componentDidUpdate(prevProps, prevState) {
    const { edit } = this.state;

    if (prevState.edit === false && edit) {
      this.textarea.current.focus();
    }
  }

  handleBlur = () => {
    const { update } = this.props;
    const { value } = this.textarea.current;

    this.setState({
      edit: false,
    });

    update(value);
  };

  handleFocus = () => {
    this.setState({
      edit: true,
    });
  };

  render() {
    const { edit } = this.state;
    const { children, value } = this.props;

    return (
      // eslint-disable-next-line
      <div
        className="editable"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        role="textbox"
        tabIndex="0"
      >
        {edit ? (
          <textarea
            ref={this.textarea}
            id="editTextarea"
            defaultValue={value}
            className="form-control"
            rows={10}
          />
        ) : (
          children
        )}
      </div>
    );
  }
}

EditableTextarea.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  children: childrenType,
};

EditableTextarea.defaultProps = {
  children: null,
};

export default EditableTextarea;
