import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getImages } from '../../helpers/getImages';
import IconButton from '../../IconButton';
import { productType } from '../../../../types';
import { getAuditLogString } from '../../helpers/getAuditLogString';
import { getTaxonomyNames } from '../../taxonomies/taxonomyUtils';
import moment from 'moment';

import {
  calculateMarkUp,
  calculateSellPrice,
  getImportCosts,
  getFixedProfit,
} from '../../helpers/costs';

import './ProductListItem.css';
import { Container, Row, Col } from 'react-bootstrap';

const PRODUCT_DETAILS_KEYS = [
  {
    key: 'dimensions',
    value: 'Dimensions',
  },
  {
    key: 'warranty',
    value: 'Guarantee',
  },
  {
    key: 'manufacturer',
    value: 'Manufacturer',
    get: ({ manufacturer, manufacturer_unknown }, { manufacturers }) =>
      manufacturer_unknown
        ? 'UNKNOWN'
        : getTaxonomyNames(manufacturer, manufacturers),
  },
  {
    key: 'supplier',
    value: 'Supplier',
    get: ({ supplier, manufacturer_is_supplier }, { suppliers }) =>
      manufacturer_is_supplier
        ? 'MANUFACTURER'
        : getTaxonomyNames(supplier, suppliers),
  },
  {
    className: 'product-list-details-row',
    key: 'lead_time',
    value: 'Lead Time',
    get: ({ lead_time }, { leadTimes }) =>
      getTaxonomyNames(lead_time, leadTimes),
  },
  // {
  //   key: "bullets",
  //   value: "Bullets",
  //   get: ({bullets}) => {
  //     return bullets && bullets?.length ? (
  //       <ul style={{padding: "0px 0px 0px 20px"}}>
  //         {
  //           bullets.split("\n").map((bullet) => {
  //             return <li>{bullet}</li>
  //           })
  //         }
  //     </ul>) : null;
  //   }
  // },
  {
    className: 'product-list-details-row',
    key: 'cost_price',
    value: 'Supplier Cost Price',
    get: ({ cost_price, audit_log }) => {
      try {
        const parsedAuditLog = JSON.parse(audit_log || '{}');
        const costPriceLog = parsedAuditLog?.cost_price;
        if (costPriceLog)
          return (
            <>
              {cost_price}
              <span
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {`(${getAuditLogString(costPriceLog)})`}
              </span>
            </>
          );
        return cost_price;
      } catch (err) {
        return cost_price;
      }
    },
  },
  {
    key: 'fixed_price_surcharge',
    value: 'Import Fixed Charge Per Item',
  },
  {
    key: 'percentage_increase',
    value: 'Import Percentage Per Item (%)',
  },
  {
    className: 'product-list-details-row-half',
    key: 'import_cost',
    get: ({ cost_price, fixed_price_surcharge, percentage_increase }) => {
      return getImportCosts(
        +cost_price,
        +fixed_price_surcharge,
        +percentage_increase
      );
    },
    value: 'Import Cost Per Item',
  },
  {
    key: 'markupe',
    value: 'Markup',
    get: ({
      sell_price,
      cost_price,
      fixed_price_surcharge,
      percentage_increase,
    }) => {
      return calculateMarkUp(
        +sell_price,
        +cost_price,
        +fixed_price_surcharge,
        +percentage_increase
      );
    },
  },
  {
    key: 'sell_price',
    get: ({
      cost_price,
      fixed_price_surcharge,
      percentage_increase,
      sell_price,
    }) => {
      const markUp = calculateMarkUp(
        +sell_price,
        +cost_price,
        +fixed_price_surcharge,
        +percentage_increase
      );

      return calculateSellPrice(
        +cost_price,
        +fixed_price_surcharge,
        +percentage_increase,
        +markUp
      );
    },
    value: 'Sell Price',
  },
  {
    key: 'profit',
    value: 'Profit Per Item',
    get: ({
      sell_price,
      cost_price,
      fixed_price_surcharge,
      percentage_increase,
    }) => {
      const markUp = calculateMarkUp(
        +sell_price,
        +cost_price,
        +fixed_price_surcharge,
        +percentage_increase
      );

      return getFixedProfit(+cost_price, +markUp);
    },
  },
  {
    key: 'last_time_used',
    value: 'Last time used',
    get: ({ last_time_used }) =>
      last_time_used
        ? moment(last_time_used).format('YYYY-MM-DD HH:mm')
        : 'Not used yet',
  },
];

const ProductDetails = ({ product, taxonomies }) => {
  return PRODUCT_DETAILS_KEYS.map(({ key, value, get, className }) => {
    return (
      <Row key={key} className={className || ''}>
        <Col sm xs={3} className="product-list-details-label">
          <small class="text-muted">{value}:</small>
        </Col>
        <Col sm xs={9} className="product-list-details-value">
          <small class="text-muted">
            {get ? get(product || {}, taxonomies || {}) : product[key] || ''}
          </small>
        </Col>
      </Row>
    );
  });
};

const ProductListItem = ({
  product,
  taxonomies,
  deleteProduct,
  duplicateProduct,
  editQuote,
  groupId,
}) => {
  const {
    product_code: saProductCode,
    product_name: productName,
    add_supplier_details,
    supplier_title,
  } = product;

  const image = getImages(product.image)[product.first_img || 0];

  return (
    <Container>
      <Row>
        <Col xs={3} sm style={{ maxWidth: '260px' }}>
          <Link
            to={{
              pathname: `/products/edit/${product.product_id}`,
              state: { editQuote, groupId },
            }}
            className="product-list-item-header"
          >
            <img
              src={`${image}`}
              alt={productName}
              className="product-list-image"
              style={{ maxHeight: 347, maxWidth: 230 }}
            />
          </Link>
        </Col>
        <Col xs={8} style={{ maxWidth: '550px' }}>
          <Row>
            <Link
              to={{
                pathname: `/products/edit/${product.product_id}`,
                state: { editQuote, groupId },
              }}
              className="product-list-item-header"
            >
              <h5 className="product-list-title">
                {productName}{' '}
                {saProductCode
                  ? ` - ${getTaxonomyNames(saProductCode, taxonomies?.tags)}`
                  : ''}
              </h5>
            </Link>
          </Row>
          {!!add_supplier_details && (
            <Row>
              <h6 className="product-list-supplier-title">{supplier_title}</h6>
            </Row>
          )}
          <Row>
            <Col>
              <ProductDetails product={product} taxonomies={taxonomies} />
            </Col>
          </Row>
        </Col>
        <Col xs={1}>
          <IconButton
            icon="trash-alt"
            title="Delete product"
            className="btn btn-link delete-product-button"
            onClick={() => {
              deleteProduct(product.product_id);
            }}
          />
          <IconButton
            icon="copy"
            title="Duplicate product"
            className="btn btn-link delete-product-button"
            onClick={() => {
              duplicateProduct(product.product_id);
            }}
          />
        </Col>
        {/* </div> */}
      </Row>
    </Container>
  );
};

ProductListItem.propTypes = {
  product: productType.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  editQuote: PropTypes.number,
};

ProductListItem.defaultProps = {
  editQuote: null,
};

export default ProductListItem;
