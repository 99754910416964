import {
  arrayOf,
  oneOfType,
  shape,
  node,
  number,
  string,
  object,
  bool,
} from 'prop-types';

export const childrenType = oneOfType([arrayOf(node), node]);

export const finishesType = shape({
  finish_number: number.isRequired,
  finish_swatch_image: string,
  finish_title: string,
  upload_file: object,
});

export const productType = shape({
  brochure_title: string,
  brochure_url: string,
  client_note: string,
  cost_option: string,
  cost_price: oneOfType([number, string]),
  fixed_price_surcharge: oneOfType([number, string]),
  displayed_cost_price: oneOfType([number, string]),
  percentage_increase: oneOfType([number, string]),
  dimensions: string,
  finishes: arrayOf(finishesType),
  group_id: number,
  image: oneOfType([string, object]),
  lead_time: string,
  position: number,
  product_id: number,
  product_name: string,
  quantity: oneOfType([number, string]),
  quote_id: number,
  product_code: string,
  sell_price: oneOfType([number, string]),
  sku: string,
  swatches_title: string,
  swatches_url: string,
  warranty: string,
});

export const groupType = shape({
  id: number,
  name: string.isRequired,
  parent_title: string,
  subtitle: string,
});

export const subtotalType = shape({
  A: number.isRequired,
  B: number.isRequired,
  AC: number.isRequired,
  BC: number.isRequired,
});

export const settingsType = shape({
  enabled: bool.isRequired,
  label: string.isRequired,
  slug: string.isRequired,
});

export const saCatsType = arrayOf(shape({ label: string, value: string }));

export const quoteStatus = {
  open: "open",
  approved: "approved",
  won: "won",
  lost: "lost",
  duplicated: "duplicated"
};

export const quoteStatusLabels = {
  open: "Open",
  approved: "Approved",
  won: "Won",
  lost: "Lost",
  duplicated: "Duplicated"
};

export const quoteCurrencies = {
  euro: "euro",
  us: "us",
  uk: "uk"
};

export const quoteCurrenciesLabels = {
  euro: "€",
  us: "$",
  uk: "£"
};

export const maxFileSizeMB = 5;
export const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
