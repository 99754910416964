import React from 'react';
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';
import { useCurrencies } from '../hooks/useCurrencies';
import { getAuditLogString } from '../helpers/getAuditLogString';
import { useGetTaxonomies } from '../taxonomies/useGetTaxonomies';
import {
  getChosenTaxonomies,
  getTaxonomyOptions,
} from '../taxonomies/taxonomyUtils';

export const ComponentsFormRow = ({
  component,
  onRemove,
  onChangeValue,
  productCurrency,
}) => {
  const { currenciesOptions } = useCurrencies();
  const getComponentAuditLogString = () => {
    const logEntry = component.changed;
    return getAuditLogString(logEntry);
  };
  const auditLogString = getComponentAuditLogString();
  const { suppliers, manufacturers } = useGetTaxonomies();

  const chosenSuppliers = getChosenTaxonomies(
    component?.supplier || '',
    suppliers
  );
  const supplierOptions = getTaxonomyOptions(
    component?.supplier || '',
    suppliers
  );

  const chosenManufacturers = getChosenTaxonomies(
    component?.manufacturer || '',
    manufacturers
  );
  const manufacturerOptions = getTaxonomyOptions(
    component?.manufacturer || '',
    manufacturers
  );

  const handleTaxonomyChange = (chosen, name) =>
    onChangeValue(
      name,
      chosen.map(option => option.value).join('+'),
      component.id
    );

  return (
    <>
      <Row className="component-form-row">
        <Col xs={2}>
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Select
              value={chosenSuppliers}
              onChange={chosen => handleTaxonomyChange(chosen, 'supplier')}
              isMulti
              name="supplier"
              options={supplierOptions}
              placeholder="Supplier"
              style={{ width: '100%' }}
            />
            <Select
              value={chosenManufacturers}
              onChange={chosen => handleTaxonomyChange(chosen, 'manufacturer')}
              isMulti
              name="manufacturer"
              options={manufacturerOptions}
              placeholder="Manufacturer"
              style={{ width: '100%' }}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div className="components-input-column">
            <textarea
              type="text"
              value={component?.code || ''}
              onChange={e =>
                onChangeValue('code', e.target.value, component.id)
              }
              style={{ width: '95%' }}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div className="components-input-column">
            <textarea
              type="text"
              value={component?.description || ''}
              onChange={e =>
                onChangeValue('description', e.target.value, component.id)
              }
              style={{ width: '100%' }}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div className="components-input-column">
            <input
              type="number"
              value={component?.supplierRRP || ''}
              onChange={e =>
                onChangeValue('supplierRRP', e.target.value, component.id)
              }
              style={{ width: '95%' }}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div className="components-input-column">
            <input
              type="number"
              value={component?.supplierDiscount || ''}
              onChange={e =>
                onChangeValue('supplierDiscount', e.target.value, component.id)
              }
              style={{ width: '95%' }}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div className="components-input-column">
            <input
              type="number"
              disabled={component.disabledCost}
              onChange={e =>
                onChangeValue('supplierCost', e.target.value, component.id)
              }
              style={{ width: '95%' }}
              value={component.supplierCost}
            />
          </div>
        </Col>

        <Col xs={1} className="components-row-currency-select">
          <div className="components-input-column">
            <input
              type="number"
              onChange={e =>
                onChangeValue('quantity', e.target.value, component.id)
              }
              value={component.quantity}
              style={{ width: '95%' }}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div className="components-input-column">
            <input
              type="number"
              disabled
              onChange={e =>
                onChangeValue('totalSupplierCost', e.target.value, component.id)
              }
              style={{ width: '95%' }}
              value={component.totalSupplierCost}
            />
          </div>
        </Col>

        <Col xs={1} className="components-row-currency-select">
            <Select
              value={component.currency}
              options={currenciesOptions}
              onChange={currency =>
                onChangeValue('currency', currency, component.id)
              }
              style={{ width: '95%' }}
            />
        </Col>

        <Col xs={1}>
          <div className="components-input-column">
            <input
              type="text"
              disabled
              style={{ width: '95%' }}
              value={component.supplierCostProductCurrency}
            />
          </div>
        </Col>
        <Col xs={1}>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => onRemove(component.id)}
          >
            X
          </button>
        </Col>
      </Row>
      {auditLogString && (
        <Row className="component-form-row">
          <Col xs={11}>
            <div
              style={{ width: '100%' }}
              className="d-flex justify-content-end small text-secondary"
            >
              {auditLogString}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
