/* eslint-disable no-param-reassign */
import uuid from 'uuid/v4';
import { createReducer } from '@reduxjs/toolkit';
import {
  loadQuoteProductsAction,
  resetProducts,
  updateProductAction,
  saveEditedProductAction,
  startImageUploadAction,
  finishImageUploadAction,
  startFinishUploadAction,
  finishFinishUploadAction,
  moveProductAction,
  changeProductGroupAction,
  addProductToRemovedAction,
  duplicateProductAction,
  addSelectedProductsAction,
  deleteRemovedProductsAction,
  setProductsLoadingAction,
  unsetProductsLoadingAction,
} from '../actions/quoteProducts';

const quoteProducts = createReducer(
  {
    products: [],
    removed: [],
  },
  {
    [loadQuoteProductsAction]: (state, action) => ({
      ...state,
      products: [...action.payload],
      productsLoading: false,
    }),
    [resetProducts]: (state, action) => ({
      products: [],
      removed: [],
      productsLoading: true,
    }),
    [updateProductAction]: ({ products }, { payload }) => {
      const { id, field } = payload;
      const productIndex = products.findIndex(product => product.id === id);
      const updatedFieldName = Object.keys(field)[0];
      const updatedFieldValue = field[updatedFieldName];

      products[productIndex] = {
        ...products[productIndex],
        [updatedFieldName]: updatedFieldValue,
      };
    },
    [saveEditedProductAction]: ({ products }, { payload }) => {
      const productIndex = products.findIndex(
        product => product.id === payload.id
      );

      products[productIndex] = payload;
    },
    [startImageUploadAction]: ({ products }, { payload }) => {
      const productIndex = products.findIndex(
        product => product.id === payload
      );

      products[productIndex].uploadingImage = true;
    },
    [finishImageUploadAction]: ({ products }, { payload }) => {
      const productIndex = products.findIndex(
        product => product.id === payload
      );

      products[productIndex].uploadingImage = false;
    },
    [startFinishUploadAction]: ({ products }, { payload }) => {
      const productIndex = products.findIndex(
        product => product.id === payload
      );

      products[productIndex].uploadingFinishes = true;
    },
    [finishFinishUploadAction]: ({ products }, { payload }) => {
      const productIndex = products.findIndex(
        product => product.id === payload
      );

      products[productIndex].uploadingFinishes = false;
    },
    [moveProductAction]: ({ products }, { payload }) => {
      const { position, positionShift } = payload;
      const newPosition = position + positionShift;

      // prevent position going below 0 or above the total products list length
      const constrainedPosition = Math.min(
        Math.max(newPosition, 0),
        products.length - 1
      );

      // swap products positions in the array
      const tmp = products[position];
      products[position] = products[constrainedPosition];
      products[constrainedPosition] = tmp;
    },
    [changeProductGroupAction]: ({ products }, { payload }) => {
      const { position, newGroupId } = payload;

      products[position].group_id = newGroupId;
    },
    [addProductToRemovedAction]: ({ products, removed }, { payload }) => {
      const productIndex = products.findIndex(
        product => product.id === payload
      );

      products.splice(productIndex, 1);
      removed.push(payload);
    },
    [duplicateProductAction]: ({ products }, { payload }) => {
      const product = { ...payload };

      // set product id to unique random value and flag that it is temporary
      product.id = uuid();
      product.tmp_id = true;

      // do not clone finishes for now
      if (!product.withFinishes) delete product.finishes;

      // do not clone client note
      delete product.client_note;

      // calculate new product position right after the one we are duplicating
      const duplicatedProductPosition = products.findIndex(
        item => item.id === payload.id
      );

      // add the new product to the end of the group
      products.splice(duplicatedProductPosition + 1, 0, product);
    },
    [addSelectedProductsAction]: ({ products }, { payload }) => {
      const { groupId } = payload;

      // if we insert to a specific group, need to calculate the proper position for the products
      let position = products.length;

      if (groupId) {
        for (let j = products.length - 1; j > 0; j--) {
          // find the last product in the group and insert after it
          if (products[j].group_id === groupId) {
            position = j + 1;

            break;
          }
          // shift all next products position
          products[j].position += 1;
        }
      }

      products.splice(position, 0, ...payload.products);
    },
    [deleteRemovedProductsAction]: state => {
      state.removed = [];
    },
    [setProductsLoadingAction]: state => {
      state.productsLoading = true;
      return state;
    },
    [unsetProductsLoadingAction]: state => {
      state.productsLoading = false;
      return state;
    },
  }
);

export default quoteProducts;
