import React from 'react';
import PropTypes from 'prop-types';

const withLoader = Component => {
  const Wrapped = ({ loaded, hideOnLoading = false, ...componentProps }) => {
    return loaded ? (
      <Component {...componentProps} />
    ) : (
      <div className="loader-wrapper">
        <div className="overlay">
          <img
            src="/ajax-loader.gif"
            alt="Loading spinner"
            className="spinner"
          />
        </div>
        {!hideOnLoading && <Component {...componentProps} />}
      </div>
    );
  };

  Wrapped.propTypes = {
    loaded: PropTypes.bool,
    hideOnLoading: PropTypes.bool,
  };

  Wrapped.defaultProps = {
    loaded: false,
    hideOnLoading: false,
  };

  return Wrapped;
};

export default withLoader;
