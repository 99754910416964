import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from './Button';
import FixedHeaderNavItem from './FixedHeaderNavItem';
import withLoader from './withLoader';
import { downloadPdf } from '../actions/quote';

const mapStateToProps = ({ quote }) => ({ quote });
const mapDispatchToProps = {
  downloadPdf,
};

class FixedHeader extends React.PureComponent {
  renderProductListNav = productsOnly => (
    <ul className="navbar-nav">
      <FixedHeaderNavItem
        linkType="router"
        className="btn btn-outline-primary"
        link="/products/edit"
        text="New Product"
        state={{ editQuote: this.props.quoteId }}
      />
      {!productsOnly && (
        <FixedHeaderNavItem
          linkType="router"
          className="btn btn-outline-primary"
          link={`/quotes/edit/${this.props.editQuote || ''}`}
          state={{
            addSelected: true,
            groupId: this.props.groupId,
          }}
          text={this.props.editQuote ? 'Add to Quote' : 'New Quote'}
          listItemClassName="add-to-quote-button mx-2"
        />
      )}
    </ul>
  );

  renderQuotesNav = (currentPage, editQuote) => {
    const cleanFilters = this.props.context !== 'product';
    const quotesLink = {
      link: '/quotes',
      text: 'Quotes',
      active: currentPage === 'quotes',
      state: { editQuote },
    };
    const productsLink = {
      link: '/products',
      text: 'Products',
      active: currentPage === 'productList',
      state: { editQuote, cleanFilters },
    };
    const taxonomiesLink = {
      link: '/taxonomies',
      text: 'Taxonomies',
      active: currentPage === 'taxonomies',
      state: { editQuote },
    };
    const settingsLink = {
      link: '/settings',
      text: 'Settings',
      active: currentPage === 'settings',
      state: { editQuote },
    };
    const links = (() => {
      if (this.props.isAdmin)
        return [quotesLink, productsLink, taxonomiesLink, settingsLink];
      if (this.props.productsOnly) return [productsLink, taxonomiesLink];
      return [quotesLink, productsLink, taxonomiesLink];
    })();

    const linksElements = links.map((linkItem, i) => {
      const { link, text, active, state } = linkItem;
      let { className = 'nav-link' } = linkItem;

      if (active) {
        className += ' active';
      }

      return (
        <FixedHeaderNavItem
          linkType="router"
          key={i}
          link={link}
          text={text}
          className={className}
          state={state}
        />
      );
    });

    return <React.Fragment>{linksElements}</React.Fragment>;
  };

  downloadPdf = async () => {
    const { slug, randslug } = this.props.quote;

    this.props.downloadPdf(slug, randslug);
  };

  renderEditQuoteNav = () => {
    const { saveQuote, updateCurrencies } = this.props;
    const {
      id: quoteId,
      slug,
      randslug,
      loading: quoteLoading,
      currenciesLoading,
      generatingPdf,
    } = this.props.quote;
    const ButtonWithLoader = withLoader(Button);
    const PdfUrl =
      slug && randslug
        ? `${process.env.REACT_APP_SITE}/api/quotes/generatePdf/?slug=${slug}&randslug=${randslug}`
        : null;

    return (
      <ul className="navbar-nav edit-quote-toolbar">
        <FixedHeaderNavItem
          linkType="router"
          className="btn btn-outline-primary"
          link="/products"
          text="Add Product(s)"
          state={{ editQuote: quoteId, cleanFilters: true }}
          listItemClassName="mx-2"
        />
        {PdfUrl && (
          <li className="nav-item mx-2">
            <ButtonWithLoader
              onClick={this.downloadPdf}
              className="btn btn-outline-primary"
              text="PDF"
              loaded={!generatingPdf}
            />
          </li>
        )}
        <li className="nav-item mx-2">
          <ButtonWithLoader
            className="btn btn-outline-primary"
            onClick={saveQuote}
            text="Save"
            loaded={!quoteLoading}
            hideOnLoading={false}
          />
        </li>
        <li className="nav-item mx-2">
          <ButtonWithLoader
            className="btn btn-outline-primary"
            onClick={updateCurrencies}
            text="Update Currency Rates"
            loaded={!currenciesLoading}
            hideOnLoading={false}
          />
        </li>
      </ul>
    );
  };

  render() {
    const { context, quote, productsOnly } = this.props;
    const { id: quoteId } = quote;

    return (
      <nav className="navbar navbar-light fixed-top bg-light navbar-expand-sm">
        <div className="container">
          <ul className="navbar-nav">
            {this.renderQuotesNav(context, quoteId)}
          </ul>
          {context === 'quotes' && (
            <ul className="navbar-nav">
              <FixedHeaderNavItem
                linkType="router"
                link="/quotes/edit"
                text="New Quote"
                className="btn btn-outline-primary"
              />
            </ul>
          )}
          {context === 'productList' && this.renderProductListNav(productsOnly)}
          {context === 'editQuote' && this.renderEditQuoteNav()}
          <ul className="navbar-nav">
            <li className="nav-item ml-4">
              <a
                href={`${process.env.REACT_APP_SITE}/auth/logout`}
                className="small nav-link"
              >
                Log out
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

FixedHeader.propTypes = {
  context: PropTypes.oneOf([
    'editQuote',
    'quotes',
    'productList',
    'settings',
    'product',
  ]),
  quoteId: PropTypes.number,
  saveQuote: PropTypes.func,
  updateCurrencies: PropTypes.func,
  loading: PropTypes.bool,
  slug: PropTypes.string,
  randslug: PropTypes.string,
  isAdmin: PropTypes.bool,
  editQuote: PropTypes.number,
  groupId: PropTypes.number,
};

FixedHeader.defaultProps = {
  context: 'quotes',
  quoteId: null,
  saveQuote: null,
  updateCurrencies: null,
  loading: null,
  slug: null,
  randslug: null,
  isAdmin: false,
  productsOnly: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(FixedHeader);
