export function getImages(
  imageObj,
  size = 'thumbnail',
  showPlaceholder = true
) {
  try {
    if (imageObj instanceof File) {
      return [URL.createObjectURL(imageObj)];
    } else if (
      imageObj &&
      typeof imageObj === 'string' &&
      imageObj !== 'null'
    ) {
      const parsedObj = JSON.parse(imageObj);
      if (parsedObj.length)
        return parsedObj.map(imgObj => {
          if (imgObj.simple_url) return imgObj.simple_url;
          return `https://app.quote.black/images/${imgObj[size]}`;
        });
      if (parsedObj.simple_url) return [parsedObj.simple_url];
      return [`https://app.quote.black/images/${parsedObj[size]}`];
    } else if (showPlaceholder) {
      return ['/placeholder.png'];
    } else {
      return null;
    }
  } catch (e) {
    return ['/placeholder.png'];
  }
}
