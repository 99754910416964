const getTaxonomyNames = (taxonomyIdsString, taxonomyList) =>
  taxonomyIdsString
    ? taxonomyIdsString
        .split('+')
        .map(
          id =>
            (taxonomyList || []).find(taxonomy => `${taxonomy.id}` == id)
              ?.name || ''
        )
        .filter(name => !!name)
        .join(', ')
    : [];

const getChosenTaxonomies = (taxonomyIdsString, taxonomyList) =>
  taxonomyIdsString
    ? taxonomyIdsString
        .split('+')
        .map(id => ({
          value: id,
          label: (taxonomyList || []).find(taxonomy => `${taxonomy.id}` == id)
            ?.name,
        }))
        .filter(option => !!option.label)
    : [];

const getTaxonomyIds = taxonomyIdsString =>
  taxonomyIdsString ? taxonomyIdsString.split('+') : [];

const getTaxonomyOptions = (taxonomyIdString, taxonomyList) =>
  (taxonomyList || [])
    .map(taxonomy => ({ value: taxonomy.id, label: taxonomy.name }))
    .filter(
      option => !getTaxonomyIds(taxonomyIdString).includes(`${option.value}`)
    );

module.exports = { getTaxonomyNames, getChosenTaxonomies, getTaxonomyOptions };
