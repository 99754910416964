import apiWrapper from '../components/helpers/apiWrapper';
import { createAction } from '@reduxjs/toolkit';

const setOpportunities = createAction('SET_OPPORTUNITIES');
const showOpportunityLoader = createAction('SHOW_OPPORTUNITY_LOADER');
const hideOpportunityLoader = createAction('HIDE_OPPORTUNITY_LOADER');
const selectOpportunity = createAction('SELECT_OPPORTUNITY');

const getOpportunities = () => async dispatch => {
  dispatch(showOpportunityLoader());

  try {
    apiWrapper.callApi(`/api/iq/getOpportunities`).then(result => {
      return dispatch(setOpportunities(result));
    });
  } catch (e) {
    console.log(e.message);
    dispatch(setOpportunities([]));
  } finally {
    dispatch(hideOpportunityLoader());
  }
};

export {
  setOpportunities,
  getOpportunities,
  showOpportunityLoader,
  hideOpportunityLoader,
  selectOpportunity,
};
