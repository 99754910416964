import { createAction } from '@reduxjs/toolkit';
import apiWrapper from '../components/helpers/apiWrapper';
import { showErrorMessage, showSuccessMessage } from './messages';
import {
  deleteRemovedProducts,
  saveQuoteProducts,
  notifyManager,
} from './quoteProducts';
import { saveGroups, deleteRemovedGroups } from './groups';
import { quoteStatus } from '../../types';

const loadQuoteAction = createAction('LOAD_QUOTE');
const resetState = createAction('RESET_STATE');
const saveQuoteAction = createAction('SAVE_QUOTE');
const updateStatus = createAction('UPDATE_STATUS');
const updateFieldAction = createAction('UPDATE_FIELD');
const showLoader = createAction('SHOW_LOADER');
const hideLoader = createAction('HIDE_LOADER');
const showCurrenciesLoader = createAction('SHOW_CURRENCIES_LOADER');
const hideCurrenciesLoader = createAction('HIDE_CURRENCIES_LOADER');
const setQuoteLoading = createAction('SET_QUOTE_LOADING');
const unsetQuoteLoading = createAction('UNSET_QUOTE_LOADING');
const updateDelivery = createAction('UPDATE_DELIVERY');
const updateDeliveryExclusive = createAction('UPDATE_DELIVERY_EXCLUSIVE');
const updateDeliveryInstall = createAction('UPDATE_DELIVERY_INSTALL');
const updateDeliveryInclusive = createAction('UPDATE_DELIVERY_INCLUSIVE');
const updateVatText = createAction('UPDATE_VAT_TEXT');
const updateVatInclusive = createAction('UPDATE_VAT_INCLUSIVE');
const updateCosts = createAction('UPDATE_COSTS');
const updateVATRate = createAction('UPDATE_VAT_RATE');
const loadDefaultTcsAction = createAction('LOAD_DEFAULT_TCS');
const updateTcs = createAction('UPDATE_TCS');
const getWelcomeTextAction = createAction('GET_WELCOME');
const updateTotalNoteAction = createAction('UPDATE_TOTAL_NOTE');
const setQuoteUpdated = createAction('SET_QUOTE_UPDATED');
const unsetQuoteUpdated = createAction('UNSET_QUOTE_UPDATED');
const startGeneratingPdf = createAction('START_GENERATING_PDF');
const finishGeneratingPdf = createAction('FINISH_GENERATING_PDF');
const startUploadingQuoteLogoAction = createAction(
  'START_UPLOADING_QUOTE_LOGO'
);
const finishUploadingQuoteLogoAction = createAction(
  'FINISH_UPLOADING_QUOTE_LOGO'
);
const saveScroll = createAction('SAVE_SCROLL');

const saveOldScroll = scrollValue => dispatch => {
  dispatch(saveScroll(scrollValue));
};

const getQuote = id => dispatch => {
  return apiWrapper
    .callApiWithCredentials({ url: `/api/quotes/edit/${id}` })
    .then(result => {
      return dispatch(loadQuoteAction(result));
    });
};

const getQuoteBySlug = (randslug, slug) => dispatch => {
  return apiWrapper.callApi(`/api/quotes/${randslug}/${slug}`).then(result => {
    return dispatch(loadQuoteAction(result));
  });
};

const saveQuote = ({
  quoteId,
  quoteState,
  groups,
  products,
  groupsToDelete,
  productsToDelete,
}) => async dispatch => {
  dispatch(showLoader());

  try {
    const { ...savedQuote } = await apiWrapper.callApiPost(
      `/api/quotes/edit/${quoteId}`,
      {
        ...quoteState,
      }
    );

    if (savedQuote.error) {
      throw new Error(savedQuote.error);
    }

    // we don't want to run this on new quotes so check if it has id
    if (quoteId) {
      await dispatch(deleteRemovedProducts(quoteId, productsToDelete));
      dispatch(deleteRemovedGroups(groupsToDelete, quoteId));
    }

    const savedQuoteId = savedQuote.id;
    const savedGroups = await dispatch(saveGroups(groups, savedQuoteId));

    // if products don't have a group, add them to one
    const defaultGroup = savedGroups[0];
    const defaultGroupId =
      defaultGroup && defaultGroup.id ? defaultGroup.id : null;
    const productsWithDefaultGroup = JSON.parse(JSON.stringify(products)).map(
      product => {
        if (!product.group_id) {
          product.group_id = defaultGroupId;
        }

        return product;
      }
    );

    await dispatch(saveQuoteProducts(savedQuoteId, productsWithDefaultGroup));

    // this is to inform that the quote has been saved
    dispatch(saveQuoteAction());

    dispatch(showSuccessMessage(savedQuote.success));

    dispatch(hideLoader());

    // remove products selection
    sessionStorage.removeItem('selectedProducts');

    return savedQuote;
  } catch (e) {
    console.log(e);

    dispatch(hideLoader());

    dispatch(showErrorMessage(e.message));
    return Promise.reject();
  }
};

const updateCurrencies = ({ quoteId }) => async dispatch => {
  dispatch(showCurrenciesLoader());
  try {
    const result = await apiWrapper.callApiPost(
      `/api/quoteProducts/updateCurrencies`,
      { quoteId }
    );
    return result;
  } catch (e) {
    console.log(e);
    dispatch(showErrorMessage(e.message));
    return Promise.reject();
  } finally {
    dispatch(hideCurrenciesLoader());
  }
};

const editDate = ({ date }) => dispatch =>
  dispatch(updateFieldAction({ date: date.getTime() }));

const loadDefaultTcs = () => async dispatch => {
  const termsResponse = await apiWrapper.callApi(
    '/api/settings/getSettings/terms'
  );

  if (!termsResponse || !termsResponse.length) {
    return dispatch(loadDefaultTcsAction(''));
  }

  return dispatch(loadDefaultTcsAction(termsResponse[0].value));
};

const getWelcomeText = () => async dispatch => {
  const welcomeRes = await apiWrapper.callApi(
    '/api/settings/getSettings/welcome'
  );

  if (!welcomeRes || !welcomeRes.length) {
    return dispatch(getWelcomeTextAction(null));
  }

  return dispatch(getWelcomeTextAction(welcomeRes[0].value));
};

const updateTotalNote = (quoteId, target) => dispatch => {
  const { name: costOption, value } = target;

  dispatch(updateTotalNoteAction({ costOption, value }));

  return apiWrapper.callApiPost('/api/quotes/updateTotalNote', {
    quoteId,
    note: value,
    cost_option: costOption,
  });
};

const updateTotalNoteWithButton = (quoteId, costOption, value) => dispatch => {
  dispatch(updateTotalNoteAction({ costOption, value }));

  return apiWrapper.callApiPost('/api/quotes/updateTotalNote', {
    quoteId,
    note: value,
    cost_option: costOption,
  });
};

const approve = ({
  id: quoteId,
  userId,
  customerName,
  quoteName,
  slug,
  randslug,
}) => async dispatch => {
  try {
    const result = await apiWrapper.callApi(`/api/quotes/approve/${quoteId}`);

    if (!(result && result.newStatus && quoteStatus[result.newStatus])) {
      throw new Error('Incorrect approve API response');
    }

    dispatch(updateFieldAction({ status: result.newStatus }));
    dispatch(
      notifyManager({
        userId,
        customerName,
        quoteName,
        slug,
        randslug,
        reason: 'approve',
      })
    );
  } catch (e) {
    console.log(e);
  }
};

const downloadPdf = (slug, randslug) => async dispatch => {
  const pdfUrl = `/api/quotes/generatePdf/?slug=${slug}&randslug=${randslug}`;

  dispatch(startGeneratingPdf());

  try {
    const response = await apiWrapper.callApiWithCredentials({
      url: pdfUrl,
      timeout: 100000,
      responseType: 'blob',
    });

    // capitalize first letter of each word
    const capitalizedSlug = slug
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-');

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${capitalizedSlug}.pdf`);
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(finishGeneratingPdf());
  }
};

const uploadQuoteLogo = file => async dispatch => {
  if (!(file instanceof Blob || file instanceof File)) {
    return;
  }

  dispatch(startUploadingQuoteLogoAction());

  const data = new FormData();

  data.append('logo', file, file.name);

  const { logo } = await apiWrapper.callApiPost(
    `/api/quotes/uploadQuoteLogo`,
    data,
    false,
    undefined,
    true
  );

  dispatch(updateFieldAction({ logo: JSON.stringify(logo) }));

  dispatch(finishUploadingQuoteLogoAction());

  // eslint-disable-next-line
  return logo;
};

export {
  getQuote,
  loadQuoteAction,
  resetState,
  saveQuote,
  updateCurrencies,
  updateStatus,
  updateFieldAction,
  editDate,
  showLoader,
  hideLoader,
  showCurrenciesLoader,
  hideCurrenciesLoader,
  setQuoteLoading,
  unsetQuoteLoading,
  updateDelivery,
  updateDeliveryExclusive,
  updateDeliveryInstall,
  updateDeliveryInclusive,
  updateVatText,
  updateVatInclusive,
  updateCosts,
  updateVATRate,
  loadDefaultTcsAction,
  loadDefaultTcs,
  updateTcs,
  getQuoteBySlug,
  getWelcomeText,
  getWelcomeTextAction,
  updateTotalNoteAction,
  updateTotalNote,
  updateTotalNoteWithButton,
  approve,
  setQuoteUpdated,
  unsetQuoteUpdated,
  saveQuoteAction,
  downloadPdf,
  startGeneratingPdf,
  finishGeneratingPdf,
  uploadQuoteLogo,
  saveScroll,
  saveOldScroll,
};
