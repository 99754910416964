import { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrencies } from "./useCurrencies";
import apiWrapper from '../helpers/apiWrapper';
import { useModal } from "./useModal";
import { useDispatch } from "react-redux";
import { getQuoteProducts } from "../../actions/quoteProducts";
import _ from "lodash";

const getEmptyComponent = () => {
  return {
    supplier: "",
    code: "",
    description: "",
    supplierRRP: "",
    supplierDiscount: "",
    supplierCost: "",
    currency: {label: "EUR", value: "EUR"},
    supplierCostProductCurrency: "",
    quantity: "1",
    totalSupplierCost: "",
    disabledCost: false
  };
}

export const useProductComponents = (existingComponents, productCurrency, productId, isQuoteProduct, quoteId, onUpdate = () => {}, handleProductChange) => {
  let [id, setId] = useState(0);
  const { getPairRate } = useCurrencies();
  const {closeModal} = useModal();

  const dispatch = useDispatch();


  let comps = {notes: "", finalCost: "", finalCostProductCurrency: 0, components: []};

  useEffect(() => {
    if (existingComponents && existingComponents.components?.length) {
      const maxId = existingComponents.components.reduce((acc, c) => {
        if (c.id > acc) return c.id;
        else return acc;
      }, 0);
  
      setId(maxId + 1);
    }
  }, [existingComponents])
 
  if (existingComponents) {
    comps = _.cloneDeep(existingComponents);
  }

  const [components, setComponents] = useState(comps);

  const addNewComponent = useCallback(() => {
    const newComponent = getEmptyComponent();
    newComponent.id = id++;

    const newCompObj = {...components, components: [...(components.components || []), newComponent]};
    handleProductChange({target: {
      name: "components",
      value: newCompObj,
    }});
    setComponents(newCompObj);
    setId(id);
  }, [setComponents, setId, components.components, components.notes, id]);

  const deleteComponent = useCallback((id) => {
    const newComponents = components.components.filter(c => c.id !== id);

    components.finalCostProductCurrency = newComponents.filter(c => !!c.supplierCostProductCurrency)
      .reduce((acc, c) => {
        acc += Number(c.supplierCostProductCurrency);
        return acc;
      }, 0);
    const newCompObj = {...components, components: newComponents};
    setComponents(newCompObj);
    handleProductChange({target: {
      name: "components",
      value: newCompObj,
    }});
  
  }, [setComponents, components.components]);

  const changeValue = useCallback((key, value, id) => {
    const component = components.components.find(c => c.id === id);

    component[key] = value;

    const supplierRRP = parseFloat(component.supplierRRP);
    const supplierDiscount = parseFloat(component.supplierDiscount);
    const haveSupplierRRP = supplierRRP == 0 || !!supplierRRP;
    const haveSupplierDiscount = supplierDiscount == 0 || !!supplierDiscount;
    if (haveSupplierRRP && haveSupplierDiscount) {
      const supplierDicscount =  100 - (+supplierDiscount);
      component.supplierCost = ((+(supplierRRP * supplierDicscount / 100))||0).toFixed(2);
      component.disabledCost = true;
    } else {
      component.disabledCost = false;
    }

    const quantity = parseFloat(component.quantity);
    const supplierCost = parseFloat(component.supplierCost);
    const haveQuantity = quantity == 0 || !!quantity;
    const haveSupplierCost = supplierCost == 0 || !!supplierCost;
    if (haveQuantity && haveSupplierCost) {
      const newTotalSupplierCost = quantity * supplierCost; 
      component.totalSupplierCost = newTotalSupplierCost;
    } else {
      component.totalSupplierCost = "";
    }

    const totalSupplierCost = parseFloat(component.totalSupplierCost);
    const haveTotalSupplierCost = totalSupplierCost == 0 || !! totalSupplierCost;
    const haveCurrencies = component.currency.value && productCurrency.value;
    if (haveTotalSupplierCost && haveCurrencies) {
      const rate = getPairRate(component.currency.value, productCurrency.value);
      component.supplierCostProductCurrency = ((rate * component.totalSupplierCost)||0).toFixed(2);
    } else {
      component.supplierCostProductCurrency = "";
    }

    const newComponents = components.components.map((c) => {
      if (c.id === id) return component;
      else return c;
    });


    const newFinalCost = newComponents.filter(c => !!c.supplierCost)
      .reduce((acc, c) => {
        acc += Number(c.supplierCost);
        return acc;
      }, 0);
    const newFinalCostRounded = (newFinalCost||0).toFixed(2)
    components.finalCost = newFinalCostRounded;


    const newTotalPrice = newComponents.filter(c => !!c.supplierCostProductCurrency)
      .reduce((acc, c) => {
        acc += Number(c.supplierCostProductCurrency);
        return acc;
      }, 0);
    const newTotalPriceRounded = (newTotalPrice||0).toFixed(2);
      components.finalCostProductCurrency = newTotalPriceRounded;
    
    const newCompObj = {...components, components: newComponents};

    handleProductChange({target: {
      name: "cost_price",
      value: components.finalCostProductCurrency,
    }});
    handleProductChange({target: {
      name: "supplier_rrp",
      value: null,
    }});
    handleProductChange({target: {
      name: "supplier_discount",
      value: null,
    }});
    handleProductChange({target: {
      name: "components",
      value: newCompObj,
    }});

    setComponents(newCompObj);
  }, [setComponents, components, productCurrency]);

  const componentsTotalPrice = useMemo(() => {
    // return components.reduce();
  }, [components]);

  const saveComponents = useCallback(async () => {
    const apiUrl = isQuoteProduct ?
       `/api/quoteProducts/updateComponents/${productId}` :
       `/api/products/updateComponents/${productId}`;

    try {
      console.log("components: ", components);
      const result = await apiWrapper.callApiPost(apiUrl, components);
      onUpdate(result);

      if (!isQuoteProduct) document.location.reload();
      else {
        dispatch(getQuoteProducts(quoteId))
      }

    } catch (e) {

    }
    
    closeModal();


    if (isQuoteProduct) {
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 500)
    }

  }, [components, productId, isQuoteProduct, dispatch, quoteId]); 

  const changeNote = useCallback((value) => {
    components.notes = value;

    handleProductChange({target: {
      name: "components",
      value: {...components},
    }});

    setComponents({...components});
  }, [setComponents, components]);

  return {
    components: components.components,
    note: components.notes,
    finalCost: components.finalCost,
    finalCostProductCurrency: components.finalCostProductCurrency,
    changeNote,
    addNewComponent,
    deleteComponent,
    saveComponents,
    changeValue
  }

}
