/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { RIEInput, RIENumber } from 'riek';
import Select from 'react-select';
import { withAlert } from 'react-alert';
import dateFormat from 'dateformat';
import PropTypes from 'prop-types';
import withLoader from '../withLoader';
import { quoteStatus, quoteStatusLabels } from '../../../types';
import { addMessage } from '../../actions/messages';
import { getUsers, selectUser, userTypes } from '../../actions/users';
import { updateStatus, updateFieldAction, editDate } from '../../actions/quote';
import {
  getOpportunities,
  selectOpportunity,
} from '../../actions/opportunities';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const mapStateToProps = ({ managers, quote, opportunities }) => ({
  quote,
  managers,
  opportunities,
  // TODO: move mapped settings to separate state
  settingsMap: (quote.settings || []).reduce((acc, setting) => {
    const { slug: name, enabled } = setting;
    acc[name] = enabled;

    return acc;
  }, {}),
});

const mapDispatchToProps = {
  getUsers,
  selectUser,
  updateStatus,
  editDate,
  addMessage,
  getOpportunities,
  selectOpportunity,
  updateField: updateFieldAction,
};

const SelectWithLoader = withLoader(Select);

const statusOptions = [
  { value: quoteStatus.open, label: quoteStatusLabels.open },
  { value: quoteStatus.won, label: quoteStatusLabels.won },
  { value: quoteStatus.approved, label: quoteStatusLabels.approved },
  { value: quoteStatus.lost, label: quoteStatusLabels.lost },
  { value: quoteStatus.duplicated, label: quoteStatusLabels.duplicated },
];

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: null,
    };
  }

  componentDidMount() {
    this.props.getUsers(userTypes.managers);
  }

  componentDidUpdate(prevProps) {
    const { quote, managers } = this.props;
    const { userId, status } = quote;

    if (
      managers.users !== prevProps.managers.users ||
      quote.userId !== prevProps.quote.userId
    ) {
      this.props.selectUser(
        userTypes.managers,
        this.getSelected(managers.users, userId)
      );
    }

    if (status !== prevProps.status) {
      this.updateStatus(status);
    }
  }

  updateStatus = status => {
    this.setState({
      selectedStatus: this.getSelected(statusOptions, status),
    });
  };

  selectManager = value => this.props.selectUser(userTypes.managers, value);

  handleStatusChange = selectedOption => {
    this.props.updateStatus(selectedOption.value);
  };

  getSelected = (list, selected) => {
    if (!list || !list.length || !selected) {
      return null;
    }

    return list.find(item => {
      const { value } = item;

      return selected === value;
    });
  };

  isNotEmpty = input => input !== '';

  render() {
    const {
      quote,
      managers,
      opportunities,
      updateField,
      settingsMap,
    } = this.props;
    const { selectedStatus } = this.state;
    const { quoteName, revision, date, quote_note = '' } = quote;
    const { is_uk: isUk } = settingsMap;

    const formattedDate = dateFormat(date, 'dd/mm/yyyy');

    return (
      <div>
        {/* pass title as props to avoid deepEqual stack overflow issue https://github.com/nfl/react-helmet/issues/373 */}
        <Helmet defer={false} title={`${quoteName}${isUk ? ' (UK)' : ''}`} />
        <h1>
          <RIEInput
            value={quoteName}
            propName="quoteName"
            change={updateField}
            className="editable"
            validate={this.isNotEmpty}
          />
        </h1>
        <div className="row">
          <ul className="list-unstyled col col-md-6 quote-features">
            <li>
              <span className="label">Client: </span>
              <span className="value">
                <input
                  type="text"
                  name="customerName"
                  placeholder="Customer"
                  className="form-control"
                  value={quote.customerName}
                  onInput={(e, val) => {
                    updateField({
                      [e.target.name]: e.target.value,
                    });
                  }}
                  required
                />
              </span>
            </li>
            <li>
              <span className="label">Status</span>
              <span className="value">
                <Select
                  value={selectedStatus}
                  options={statusOptions}
                  onChange={this.handleStatusChange}
                />
              </span>
            </li>
            <li>
              <span className="label">Account Manager: </span>
              <span className="value">
                <SelectWithLoader
                  loaded={!managers.loading}
                  options={managers.users}
                  placeholder="Account Manager"
                  onChange={this.selectManager}
                  value={managers.selected}
                  onMenuOpen={() => {
                    if (!managers.loaded) {
                      this.props.getUsers(userTypes.managers);
                    }
                  }}
                />
              </span>
            </li>
            <li>
              <span className="label">Opportunity: </span>
              <span className="value">
                <SelectWithLoader
                  loaded={!opportunities.loading}
                  options={opportunities.opportunities}
                  placeholder="Opportunity"
                  onChange={this.props.selectOpportunity}
                  value={opportunities.selected || quote.opportunity}
                  onMenuOpen={() => {
                    if (!opportunities.loaded) {
                      this.props.getOpportunities();
                    }
                  }}
                />
              </span>
            </li>
            <li>
              <span className="label">Revision: </span>
              <span className="value">
                <RIENumber
                  value={revision}
                  propName="revision"
                  change={updateField}
                  className="editable"
                />
              </span>
            </li>
            <li>
              <span className="label">Date: </span>
              <span className="value">
                <DatePicker
                  selected={new Date(date)}
                  onChange={date => this.props.editDate({ date })}
                  dateFormat="do MMMM yyyy"
                />
              </span>
            </li>
          </ul>
          <div className="col col-md-6">
            <textarea
              name={'quote_note'}
              className="form-control"
              placeholder="You can leave general internal notes about the quote here."
              value={quote_note}
              onChange={e => updateField({ quote_note: e.target.value })}
              style={{ height: '100%', resize: 'none' }}
              maxlength={5000}
            />
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  users: PropTypes.shape({
    managers: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
  }),
  editDate: PropTypes.func,
  context: PropTypes.string,
  status: PropTypes.string.isRequired,
};

Header.defaultProps = {
  users: {},
  context: 'edit',
  editDate: null,
};

export default withAlert()(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);
