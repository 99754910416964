import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { childrenType } from '../../../../types';
import ProductFormFooter from './EditProductFormFooter';

const EditProductFormModal = ({
  isOpen,
  closeModal,
  saveEditedProduct,
  modalType,
  children,
}) => {
  const saveEditedProductModal = () => saveEditedProduct(modalType);

  return (
    <Modal
      show={isOpen}
      onHide={closeModal}
      dialogClassName="modal-large"
    >
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <ProductFormFooter close={closeModal} save={saveEditedProductModal} />
      </Modal.Footer>
    </Modal>
  );
};

EditProductFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  saveEditedProduct: PropTypes.func.isRequired,
  children: childrenType.isRequired,
  modalType: PropTypes.oneOf([false, 'editProduct', 'finishes']),
};

EditProductFormModal.defaultProps = {
  className: null,
  modalType: false,
};

export default EditProductFormModal;
