import React from 'react';

export const ButtonWithLoader = ({ text, onClick, loading, className }) => (
  <button
    className={'btn btn-primary ' + className}
    onClick={onClick}
    loading={loading}
    style={{ width: 200, height: 40 }}
  >
    {loading ? (
      <div class="spinner-border spinner-border-sm text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    ) : (
      <div>{text}</div>
    )}
  </button>
);
