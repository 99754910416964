import { Modal, Button } from 'react-bootstrap';
import React, { useContext } from 'react';
import { ModalContext } from '../App';

export const CustomModal = () => {
  const {
    content,
    title,
    actionButtons,
    show,
    closeModal,
    isLarge,
    isVeryLarge,
  } = useContext(ModalContext);

  console.log("isVeryLarge: ", isVeryLarge);

  const otherProps = {
    dialogClassName: (() => {
      if (isVeryLarge) return "modal-large my-modal-very-large";
      if (isLarge) return "modal-large";
      return ""
    })()
  };

  return (
    <Modal show={show} onHide={closeModal} {...otherProps}>
      <Modal.Header>
        <Modal.Title>{title || ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content || ''}</Modal.Body>
      <Modal.Footer>
        {actionButtons || (
          <>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {}}>
              Save
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

