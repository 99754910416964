import React from 'react';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import { deleteMessage } from '../actions/messages';

const ALERT_TIMEOUT = 3000;

const mapDispatchToProps = {
  deleteMessage,
};

const mapStateToProps = ({ messages }) => ({
  messages,
});

const AlertContainer = ({ messages, children, deleteMessage }) => {
  const alert = useAlert();

  React.useEffect(() => {
    if (!messages.length) {
      return;
    }

    const { message, type = 'error', timeout = ALERT_TIMEOUT } = messages[
      messages.length - 1
    ];

    alert.show(message, {
      timeout,
      type,
      onClose: deleteMessage,
    });
  }, [messages]);

  return <>{children}</>;
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
