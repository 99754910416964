import { createReducer } from '@reduxjs/toolkit';
import {
  loadGroupsAction,
  resetGroups,
  updateGroupAction,
  addGroupAction,
  addGroupToRemoved,
  changeGroupPositionAction,
  deleteRemovedGroupsAction,
  setGroupsLoading,
  unsetGroupsLoading,
} from '../actions/groups';
import groupsDefaultState from '../defaultState/groups';

const groups = createReducer(
  { groups: groupsDefaultState, removed: [] },
  {
    [loadGroupsAction]: (state, action) => {
      const loadedGroups = action.payload;

      return {
        ...state,
        groups: loadedGroups.length
          ? [...loadedGroups]
          : [...groupsDefaultState],
        groupsLoading: false,
      };
    },
    [resetGroups]: (state, action) => ({
      groups: groupsDefaultState,
      removed: [],
      groupsLoading: true,
    }),
    [updateGroupAction]: ({ groups }, { payload }) => {
      const { id, update } = payload;
      const groupIndex = groups.findIndex(group => group.id === id);

      groups[groupIndex] = { ...groups[groupIndex], ...update };
    },
    [addGroupToRemoved]: (state, { payload }) => {
      state.groups = state.groups.filter(group => group.id !== payload);

      state.removed.push(payload);
    },
    [addGroupAction]: (state, { payload }) => {
      state.groups.push(payload);
    },
    [changeGroupPositionAction]: (state, { payload }) => {
      const { position, newPosition } = payload;
      const groupTomove = state.groups[position];

      state.groups.splice(position, 1);
      state.groups.splice(newPosition, 0, groupTomove);
    },
    [deleteRemovedGroupsAction]: state => {
      state.removed = [];
    },
    [setGroupsLoading]: state => {
      state.groupsLoading = true;
    },
    [unsetGroupsLoading]: state => {
      state.groupsLoading = false;
    },
  }
);

export default groups;
