import React, { useState, useEffect } from 'react';

let timeout1;
let timeout2;
let timeout3;

export const QuoteLoader = ({ loading, oldScroll }) => {
  useEffect(() => {
    if (!loading && oldScroll) {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
      timeout1 = setTimeout(() => {
        window.scrollTo(0, oldScroll);
      }, 1000);
      timeout2 = setTimeout(() => {
        window.scrollTo(0, oldScroll);
      }, 1500);
      timeout3 = setTimeout(() => {
        window.scrollTo(0, oldScroll);
      }, 2000);
    }
  }, [loading]);

  if (loading)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          class="spinner-border"
          style={{ width: '6rem', height: '6rem' }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  return <div></div>;
};
