import React from "react";
import { useCurrencies } from "../hooks/useCurrencies";

export const CurrenciesForm = () => {
  const { currencies, changeCurrency, saveCurrencies } = useCurrencies();

  return (
    <>
      {currencies.map(c => (
        <div className="row" key={c.id} style={{margin: "10px 0"}}>
          <div className="col-md-4">
            <input disabled value={c.from} />
          </div>
          <div className="col-md-4">
            <input disabled value={c.to} />
          </div>
          <div className="col-md-4">
            <input value={c.rate} onChange={(e) => changeCurrency(c.id, e.currentTarget.value)} />
          </div>
        </div>
      )
      )}
      <div className="row">
        <div className="col-md-12" style={{textAlign: "right", paddingRight: "40px"}}>
          <button className="btn btn-primary" onClick={saveCurrencies}>Save Currencies</button>
        </div>
      </div>
    </>
  )
}