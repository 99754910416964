import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from '../../Dropzone';
import withPastable from '../../withPastable';
import { productType } from '../../../../types';

const PastableDropzone = withPastable(Dropzone);

class FinishesModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { hasAvailable: false };
  }

  componentDidMount() {
    window.addEventListener('addedImage', this.resetAvailable);
  }

  componentWillUnmount() {
    window.removeEventListener('addedImage', this.resetAvailable);
  }

  generateInputs = () => {
    // this is a flag to detect whether the modal already has an available for pasting field
    let { hasAvailable } = this.state;
    const { changeFinishesImages, editFinishesTitles, product } = this.props;

    const { finishes: editFinishes = [] } = product;

    const finishesNum = 3;
    const rows = [];

    for (let i = 0; i < finishesNum; i++) {
      const theFinish =
        editFinishes[
          editFinishes.findIndex(finish => finish.finish_number === i)
        ] || {};

      const {
        finish_title: title = '',
        finish_swatch_image,
        upload_file,
      } = theFinish;

      const uploadedFile = upload_file || finish_swatch_image;
      const available = !hasAvailable && !uploadedFile;

      if (available) {
        hasAvailable = true;
      }

      rows.push(
        <div className="col" key={i}>
          <p>
            <input
              type="text"
              className="form-control"
              placeholder="Finish Name"
              value={title || ''}
              name={`finish_title_${i}`}
              onChange={editFinishesTitles}
            />
          </p>
          <PastableDropzone
            available={available}
            id={i}
            onupload={changeFinishesImages}
            uploadedFile={uploadedFile}
          />
        </div>
      );
    }

    return rows;
  };

  resetAvailable = () => {
    this.setState({ hasAvailable: false });
  };

  render() {
    const { resetFinishes } = this.props;
    const rows = this.generateInputs();

    return (
      <div>
        <h1 className="mb-3">Add Finishes</h1>
        <div className="row text-center">{rows}</div>
        <button className="btn btn-link" type="button" onClick={resetFinishes}>
          Reset Finishes
        </button>
      </div>
    );
  }
}

FinishesModal.propTypes = {
  changeFinishesImages: PropTypes.func.isRequired,
  editFinishesTitles: PropTypes.func.isRequired,
  product: productType.isRequired,
  resetFinishes: PropTypes.func.isRequired,
};

export default FinishesModal;
