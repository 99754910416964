import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getImages } from '../../helpers/getImages';
import { ImagesPreview } from '../../images/ImagesPreview';
import ImageZoom from 'react-medium-image-zoom';
import Bullets from '../Bullets';
import Information from '../Information';
import Finishes from '../Finishes';
import formatNum from '../../helpers/formatNum';
import DescriptionLine from './DescriptionLine';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProductNote,
  notifyManager,
} from '../../../actions/quoteProducts';
import { useGetTaxonomies } from '../../taxonomies/useGetTaxonomies';
import { getTaxonomyNames } from '../../taxonomies/taxonomyUtils';
import toast from 'react-hot-toast';

const isEmpty = val => !val && val !== 0;

export const ProductViewLarge = ({
  product,
  showOptions,
  greyOut,
  settings,
  quote,
  taxonomies,
  taxonomiesLoaded,
}) => {
  const {
    id,
    product_name: productName,
    dimensions,
    bullets,
    warranty,
    brochure_title,
    brochure_url,
    swatches_title,
    swatches_url,
    lead_time,
    sell_price: sellPrice,
    cost_option = 'None',
    quantity = 1,
    finishes,
    client_note = '',
    image,
    currency_rates: currencyRates,
    currency,
    overwrite_lead_time,
    overwritten_lead_time,
    first_img: first,
    second_img: second,
    third_img: third,
  } = product;

  const [clientNoteValue, setClientNoteValue] = useState(client_note);

  const currencySymbol = useSelector(state => state?.settings?.currency);
  const quoteCurrencyName = useSelector(state => state?.settings?.currencyName);
  const dispatch = useDispatch();
  const { leadTimes } = taxonomies || {};
  let optionName = 'Optional';

  if (cost_option === 'A') {
    optionName = 'High';
  } else if (cost_option === 'B') {
    optionName = 'Low';
  } else if (cost_option === 'Both') {
    optionName = 'Both';
  }

  // const hidePrice = quoteSettings.hide_price;
  const client_notes_disabled =
    settings?.client_notes_disabled?.enabled || false;
  const images = getImages(image, 'full');

  const information = [
    { title: brochure_title, url: brochure_url },
    { title: swatches_title, url: swatches_url },
  ];

  const hidePrice = settings?.hide_price?.enabled;

  let productCurrency = currency || 'EUR';
  let rate = 1;

  if (currencyRates && currencyRates.length) {
    const rateObj = currencyRates.find(
      r => Object.keys(r)[0] === `${productCurrency}/${quoteCurrencyName}`
    );

    if (rateObj) {
      rate = Object.values(rateObj)[0];
    }
  }

  const total = quantity * Math.round(sellPrice * rate);

  return (
    <div className="product-view large-view">
      <div className="row d-md-flex align-items-md-stretch">
        <div
          className={`${
            client_notes_disabled ? 'col-md-12' : 'col-md-10'
          } product-view-columns`}
        >
          <div
            className={`large-view-list-container ${
              greyOut && (!cost_option || cost_option === 'None')
                ? 'greyed-out-large'
                : ''
            } ${showOptions ? 'options-shown' : ''}`}
          >
            <div className="full-card py-2">
              <Col sm={4}>
                <div
                  className="image-column-large product-view-image-wrap"
                  style={{ paddingLeft: 0 }}
                >
                  {(showOptions || (optionName === 'Optional' && greyOut)) && (
                    <span className="option">{optionName}</span>
                  )}
                  <div className="py-1 w-[315px] ml-[40px]">
                    <ImagesPreview
                      images={images}
                      chosenImages={{ first, second, third }}
                      height={390}
                      allowZoom
                    />
                    {/*
                    <ImageZoom
                      image={{
                        src: fullImage,
                        alt: productName,
                      }}
                      shouldReplaceImage={false}
                      shouldRespectMaxDimension
                    />
                    */}
                  </div>
                </div>
              </Col>
              <div className="full-content">
                <h5 className="mt-3 ml-1.5">{productName}</h5>
                <Row className="description-content">
                  <Col sm={8} className="description-container">
                    <div className="description-column product-view-description-wrap">
                      <span className="va-middle">
                        <DescriptionLine content={dimensions} />
                        <Bullets bullets={bullets} />
                        <DescriptionLine content={warranty} />
                      </span>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="information-column product-view-information-wrap">
                      <span className="va-middle finishes-container">
                        <Finishes finishes={finishes} context="view" />
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className="details-container">
                  <Col>
                    <div className="details-header information-column product-view-information-wrap">
                      <Information information={information} />
                    </div>
                  </Col>
                  <Col>
                    <div className="details-header">Lead Time</div>
                    <div className="lead-time">
                      {!taxonomiesLoaded ? (
                        <span className="lead-time-loading">loading ...</span>
                      ) : overwrite_lead_time ? (
                        overwritten_lead_time ||
                        getTaxonomyNames(lead_time, leadTimes) ||
                        lead_time
                      ) : (
                        getTaxonomyNames(lead_time, leadTimes) || lead_time
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="details-header">Quantity</div>
                    <div className="lead-time quantity-value">
                      {formatNum(quantity, false, false)}
                    </div>
                  </Col>
                  <Col>
                    <div className="details-header">Our Price</div>
                    <div className="lead-time">
                      {hidePrice
                        ? `-`
                        : formatNum(rate * sellPrice, currencySymbol)}
                    </div>
                  </Col>
                  <Col>
                    <div className="details-header">Our Total</div>
                    <div className="lead-time">
                      {hidePrice ? `-` : formatNum(total, currencySymbol)}
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!client_notes_disabled && (
          <div className="col-md-2 product-view-notes not-printable">
            <div className="captions text-center">Client Notes</div>
            <textarea
              name={id}
              className="form-control"
              placeholder="Leave your feedback here ..."
              onChange={e => setClientNoteValue(e.target.value)}
              value={clientNoteValue}
              style={{
                height: 'calc(93% - 32px)',
                resize: 'none',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
            <button
              className="btn btn-secondary btn-sm"
              style={{
                width: '100%',
                height: '32px',
                backgroundColor: '#F2F2F2',
                borderColor: '#D4D4D4',
                color: '#323232',
                borderTopWidth: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
              onClick={async () => {
                const {
                  userId,
                  customerName,
                  quoteName,
                  slug,
                  randslug,
                } = quote;

                notifyManager({
                  userId,
                  customerName,
                  quoteName,
                  slug,
                  randslug,
                });

                try {
                  dispatch(updateProductNote(quote.id, id, clientNoteValue));
                  toast.success('Successfully updated the note!');
                } catch (err) {
                  toast.error('Something went wrong when updating the note.');
                }
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
