import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import React from 'react';
import getImage from './helpers/getmage';


const MAX_FILE_SIZE = 2.5 * 1024 * 1024;

const DropzoneWithPreview = ({
  uploadedFile,
  onupload,
  id,
  availableForPasting,
  disabled
}) => {
  function onDrop(files) {
    onupload(files[0], id);

    window.dispatchEvent(new Event('addedImage'));
  }

  const preview = getImage(uploadedFile, 'thumbnail', false);
  let errorMessage = "";

  return (
    <section>
      <Dropzone accept="image/*" maxSize={MAX_FILE_SIZE} multiple={false} onDrop={onDrop} disabled={disabled}>
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          rejectedFiles
        }) => {
          let classNames = 'dropzone ';
          classNames += isDragActive ? 'active ' : '';
          classNames += isDragReject ? ' reject' : '';
          
          if (rejectedFiles.length) {
            errorMessage = rejectedFiles.reduce((message, file) => {
              
              if (file.size > MAX_FILE_SIZE) {
                message += file.name + ` exceeds max file size of ${MAX_FILE_SIZE / (1024 * 1024)}mb\n`;
              }

              return message;
            }, "");
          }
          
          return (
            <div {...getRootProps()} className={classNames}>
              <input {...getInputProps()} />
              {!uploadedFile && (
                <div className="drag-drop-caption">
                  {isDragAccept ? 'Drop ' : 'Drag '}
                  product image here
                  {availableForPasting ? ` or paste from your clipboard` : ``} (max {MAX_FILE_SIZE / (1024 * 1024)}mb)

                  <span className="file-upload-error">{errorMessage}</span>
                </div>
              )}
              {isDragReject && <div>Unsupported file type...</div>}
              {preview && (
                <aside className="thumbs-container">
                  <div className="thumb">
                    <div className="thumb-inner">
                      <img src={preview} alt="Preview of uploaded product" />
                    </div>
                  </div>
                </aside>
              )}
            </div>
          );
        }}
      </Dropzone>
    </section>
  );
};

DropzoneWithPreview.propTypes = {
  uploadedFile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onupload: PropTypes.func.isRequired,
  id: PropTypes.number,
  availableForPasting: PropTypes.bool.isRequired,
};

DropzoneWithPreview.defaultProps = {
  uploadedFile: null,
  id: null,
};

export default DropzoneWithPreview;
