import React from 'react';
import PropTypes from 'prop-types';
import { RIEInput } from 'riek';

const OptionalTitle = ({
  title = '',
  name,
  editTitle,
  defaultValue = 'Add group sub-heading',
  buttonCaption = 'Add Subtitle',
  Tag = 'h3',
}) => {
  if (title) {
    return (
      <Tag className={`group-${name}`}>
        <RIEInput
          value={title}
          propName={name}
          change={editTitle}
          className="editable"
        />
      </Tag>
    );
  }

  return (
    <button
      type="button"
      className="btn btn-sm btn-primary"
      onClick={() => {
        editTitle({ [name]: defaultValue });
      }}
    >
      {buttonCaption}
    </button>
  );
};

OptionalTitle.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  editTitle: PropTypes.func.isRequired,
  buttonCaption: PropTypes.string,
  Tag: PropTypes.string,
};

OptionalTitle.defaultProps = {
  title: '',
  defaultValue: 'Add group sub-heading',
  buttonCaption: 'Add Subtitle',
  Tag: 'h3',
};

export default OptionalTitle;
