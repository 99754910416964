import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from 'react-dropzone';

const ImportModal = ({ type, onClose, importProducts, importComponents }) => {
  const isOpen = type === 'importProducts' || type === 'importComponents';
  const title = `Import ${
    type === 'importProducts' ? 'Products' : 'Components'
  }`;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const firstFile = (uploadedFiles || [])[0];
  const onConfirm = () => {
    onClose();
    if (type == 'importProducts') importProducts(firstFile);
    if (type == 'importComponents') importComponents(firstFile);
  };
  const noFiles = (uploadedFiles || []).length == 0;
  const tooManyFiles = (uploadedFiles || []).length > 1;
  const exactlyOneFile = !noFiles && !tooManyFiles;
  const correctFileType = (firstFile || {}).type == 'text/csv';
  const confirmAllowed = exactlyOneFile && correctFileType;
  const wrongSelection = tooManyFiles || (exactlyOneFile && !correctFileType);
  const selectedFileText = (() => {
    if (noFiles) return '-';
    if (tooManyFiles) return 'Please select only one file';
    if (!correctFileType) return 'Wrong filetype, must be a .csv!';
    return firstFile.name || 'no filename';
  })();

  useEffect(() => setUploadedFiles([]), [type]);

  return (
    <Modal size="sm" isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <div>
          <h4 className="mb-3">{title}</h4>
          <div className="row text-center">
            <Dropzone onDrop={acceptedFiles => setUploadedFiles(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section style={{ width: '100%' }}>
                  <div style={{ width: '100%' }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      style={{ width: '100%' }}
                      className="d-flex flex-column justify-content-center align-items-center ml-auto"
                    >
                      <button className="btn btn-primary mb-2">
                        Upload Files
                      </button>
                      {wrongSelection ? (
                        <div style={{ color: 'red' }}>{selectedFileText}</div>
                      ) : (
                        <>
                          <div>Currently Selected:</div>
                          <div>
                            <i>{selectedFileText}</i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="container">
          <div className="row">
            <div className="col text-left">
              <button className="btn btn-link" type="button" onClick={onClose}>
                Close
              </button>
            </div>
            <div className="col text-right">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!confirmAllowed}
                onClick={onConfirm}
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export { ImportModal };
