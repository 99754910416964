export function formatPercent(num) {
  if (!num) {
    return 0;
  }

  let percentNum = +num;

  if (Number.isNaN(percentNum)) {
    percentNum = 0;
  }

  return Math.round(percentNum);
}

export default function formatNum(
  num = 0,
  currencySymbol = null,
  round = true
) {
  let formattedNum = num || 0;

  // remove commas from the string
  formattedNum = formattedNum.toString().replace(/,/g, '');
  formattedNum = +formattedNum;

  if (Number.isNaN(formattedNum)) {
    formattedNum = 0;
  }

  if (round) {
    formattedNum = Math.round(formattedNum);
  }

  // add thousands separator
  formattedNum = formattedNum.toLocaleString('en-IE');

  if (currencySymbol) {
    formattedNum = `${currencySymbol}${formattedNum}`;
  }

  return formattedNum;
}
