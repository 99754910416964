const state = {
  quoteName: 'Quote Name',
  revision: 0,
  date: new Date(),
  costs: {
    install: 0,
    other: 0,
  },
  subtotal: {
    // sells of corresponding options
    A: 0,
    B: 0,
    // costs of corresponding options
    AC: 0,
    BC: 0,
  },
  delivery: {
    A: 0,
    B: 0,
  },
  deliveryExclusive: {
    A: 'Total ex. VAT ex. Delivery & Installation',
    B: 'Total ex. VAT ex. Delivery & Installation',
  },
  deliveryInstall: {
    A: 'Delivery & Installation',
    B: 'Delivery & Installation',
  },
  deliveryInclusive: {
    A: 'Total ex. VAT incl. Delivery & Installation',
    B: 'Total ex. VAT incl. Delivery & Installation',
  },
  vatText: {
    A: 'VAT',
    B: 'VAT',
  },
  vatInclusive: {
    A: 'Total incl. VAT incl. Delivery & Installation',
    B: 'Total incl. VAT incl. Delivery & Installation',
  },
  updated: false,
  logo: null,
  quote_note: '',
};

module.exports = state;
