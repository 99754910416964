import {
  updateStatus,
  updateFieldAction,
  updateDelivery,
  updateCosts,
  updateTcs,
  setQuoteUpdated,
  unsetQuoteUpdated,
  saveQuoteAction,
} from '../actions/quote';

import { updateSettingAction } from '../actions/settings';

import {
  updateProductAction,
  moveProductAction,
  changeProductGroupAction,
  addProductToRemovedAction,
  duplicateProductAction,
  addSelectedProductsAction,
  saveEditedProductAction,
} from '../actions/quoteProducts';

import {
  addGroupToRemoved,
  updateGroupAction,
  changeGroupPositionAction,
} from '../actions/groups';

const modifyQuoteActions = {
  [updateStatus.toString()]: 1,
  [updateSettingAction.toString()]: 1,
  [updateFieldAction.toString()]: 1,
  [updateDelivery.toString()]: 1,
  [updateCosts.toString()]: 1,
  [updateTcs.toString()]: 1,
  [updateProductAction.toString()]: 1,
  [moveProductAction.toString()]: 1,
  [changeProductGroupAction.toString()]: 1,
  [addProductToRemovedAction.toString()]: 1,
  [duplicateProductAction.toString()]: 1,
  [addSelectedProductsAction.toString()]: 1,
  [saveEditedProductAction.toString()]: 1,
  [addGroupToRemoved.toString()]: 1,
  [updateGroupAction.toString()]: 1,
  [changeGroupPositionAction.toString()]: 1,
};

const editQuoteChangeDetector = store => next => action => {
  if (modifyQuoteActions[action.type]) {
    store.dispatch(setQuoteUpdated());
  }

  if (action.type === saveQuoteAction.toString()) {
    store.dispatch(unsetQuoteUpdated());
  }

  return next(action);
};

export default editQuoteChangeDetector;
