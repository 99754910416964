export function calculateSellPrice(
  costPrice,
  fixedPriceSurcharge,
  percentageIncrease,
  markUp
) {
  const sellPrice = getCostWithMarkUp(costPrice, markUp) + 
  getImportCosts(costPrice, fixedPriceSurcharge, percentageIncrease);

  return Math.round(sellPrice);
}

export const getLandedCost = (costPrice, fixedPriceSurcharge, percentageIncrease) => {
  return costPrice + getImportCosts(costPrice, fixedPriceSurcharge, percentageIncrease);
};

export const calculateMarkUp = (
  sellPrice, 
  costPrice, 
  fixedPriceSurcharge, 
  percentageIncrease
) => {
  if (costPrice) {
    const importCosts = getImportCosts(costPrice, fixedPriceSurcharge, percentageIncrease);
    
    const priceWithoutImportCosts = sellPrice - importCosts;

    const fixedMarkUp = priceWithoutImportCosts - costPrice;
    
    const markUpPercent = (fixedMarkUp * 100) / costPrice;
    
    return Math.round(markUpPercent);
  }

  return 0;
}

export const getCostWithMarkUp = (costPrice, markUpPercent) => {
  return Math.round(costPrice + getFixedProfit(costPrice, markUpPercent));
}


export const getFixedProfit = (costPrice, markUpPercent) => {
  return Math.round(costPrice * markUpPercent / 100);
}

export const getImportCosts = (costPrice, fixedPriceSurcharge, percentageIncrease) => {
  if(percentageIncrease) {

    return fixedPriceSurcharge + Math.round(costPrice * percentageIncrease / 100);
  }

  return fixedPriceSurcharge;
}

