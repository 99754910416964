import React from 'react';
import PropTypes from 'prop-types';

export default function withPastable(Dropzone) {
  const PastableDropzone = class extends React.Component {
    constructor(props) {
      super(props);

      this.handlePaste = this.handlePaste.bind(this);

      this.state = {
        file: null,
      };
    }

    componentDidMount() {
      window.addEventListener('paste', this.handlePaste, false);
    }

    componentWillUnmount() {
      window.removeEventListener('paste', this.handlePaste);
    }

    handlePaste(event) {
      const { available, onupload, id } = this.props;

      if (!available) {
        return;
      }

      const { items } =
        event.clipboardData || event.originalEvent.clipboardData;
      let file = null;

      // eslint-disable-next-line
      for (let index in items) {
        const item = items[index];

        if (item.kind === 'file') {
          // adds the file to your dropzone instance
          file = item.getAsFile();

          this.setState({
            file,
          });

          break;
        }
      }

      // upload the file only after paste events are finished on other inputs
      if (file) {
        setTimeout(() => {
          onupload(file, id);
        });
      }
    }

    render() {
      const { file } = this.state;
      const { available, onupload, uploadedFile, id } = this.props;

      return (
        <Dropzone
          uploadedFile={file || uploadedFile}
          availableForPasting={available}
          onupload={onupload}
          id={id}
        />
      );
    }
  };

  PastableDropzone.propTypes = {
    available: PropTypes.bool.isRequired,
    onupload: PropTypes.func.isRequired,
    uploadedFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.number,
  };

  PastableDropzone.defaultProps = {
    uploadedFile: null,
    id: null,
  };

  return PastableDropzone;
}
