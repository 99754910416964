/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

class Redirect extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.props.history.push({
      pathname: this.props.redirectPath || '/',
    });
  }

  render() {
    return <div>redirecting ...</div>;
  }
}

Redirect.propTypes = {
  redirectPath: PropTypes.string,
};

export default Redirect;
