import React from 'react';
import PropTypes from 'prop-types';

const DescriptionLine = ({ content }) => {
  if (!content) {
    return null;
  }

  return <span style={{ display: 'block' }}>{`> ${content}`}</span>;
};

DescriptionLine.propTypes = {
  content: PropTypes.string,
};

DescriptionLine.defaultProps = {
  content: '',
};

export default DescriptionLine;
