import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { orderImages } from './orderImages';
import { ModalImage } from './ModalImage';
import { AddImageModal } from './AddImageModal';
import withPastable from '../withPastable';
import Dropzone from '../Dropzone';

const PastableDropzone = withPastable(Dropzone);

export const ImagesModal = ({
  open,
  closeModal,
  images,
  addImage,
  selectImage,
  unselectImage,
  removeImage,
  chosenImages,
}) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const orderedNumberedImages = orderImages(images, chosenImages);

  return (
    <>
      <Modal show={open} onHide={closeModal} dialogClassName="modal-large">
        <Modal.Header>
          <Modal.Title>Product Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-full flex flex-row flex-wrap justify-start items-start py-10 px-2 mb-[-30px]">
            {(orderedNumberedImages || []).map(({ image, realIndex }) => (
              <ModalImage
                image={image}
                removeImage={() => removeImage(realIndex)}
                unselectImage={() => unselectImage(realIndex)}
                selectImage={oneOrTwoOrThree =>
                  selectImage(realIndex, oneOrTwoOrThree)
                }
                index={realIndex}
                chosenImages={chosenImages}
              />
            ))}
            {images.length < 20 && (
              <div
                className="w-[17%] h-[240px] mb-4 mx-[1.5%] flex flex-col items-center justify-center cursor-pointer border-solid border-[1px] border-grey hover:bg-primary hover:text-white rounded text-2xl text-center transition-colors"
                onClick={() => setUploadModalOpen(true)}
              >
                Add
                <br />
                Image
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <AddImageModal
        open={uploadModalOpen}
        closeModal={() => setUploadModalOpen(false)}
        addImage={addImage}
      />
    </>
  );
};
