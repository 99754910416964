import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import withPastable from '../withPastable';
import Dropzone from '../Dropzone';
import { maxFileSizeMB, maxFileSizeBytes } from '../../../types';
import apiWrapper from '../helpers/apiWrapper';
import toast from 'react-hot-toast';
import classnames from 'classnames';

const PastableDropzone = withPastable(Dropzone);

export const AddImageModal = ({ open, closeModal, addImage }) => {
  const [addType, setAddType] = useState('upload');
  const [imgUrl, setImgUrl] = useState('');
  const [uploadedFile, setUploadedFile] = useState();
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setImgUrl('');
    setUploadedFile(undefined);
  }, [open]);

  const uploadImage = useCallback(async () => {
    setUploading(true);
    try {
      const data = new FormData();
      if (uploadedFile instanceof Blob || uploadedFile instanceof File) {
        if (uploadedFile > maxFileSizeBytes) {
          throw new Error(
            `The image is too large, max file size is ${maxFileSizeMB}MB`
          );
        }
        // the file being uploaded
        data.append('image', uploadedFile, uploadedFile.name);
        const res =
          (await apiWrapper.callApiPost(
            `/api/products/uploadImage`,
            data,
            false
          )) || {};
        if (res.error || !res.result) throw new Error();
        else {
          console.log('uploaded image', res.result);
          toast.success('Successfully uploaded the image!');
          addImage(JSON.parse(res.result));
          closeModal();
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error('Something went wrong when uploading the image.');
    } finally {
      setUploading(false);
    }
  }, [uploadedFile, addImage, closeModal]);

  return (
    <Modal show={open} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>Add Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-row items-center mb-2">
          <div className="font-bold mr-2">Type:</div>
          <div className="flex flex-row space-x-2">
            <div className="flex flex-row items-center">
              <input
                type="radio"
                value="upload"
                name="upload"
                className="mr-2"
                checked={addType === 'upload'}
                onClick={() => setAddType('upload')}
              />{' '}
              Upload
            </div>
            <div className="flex flex-row items-center">
              <input
                type="radio"
                value="url"
                name="url"
                className="mr-2"
                checked={addType === 'url'}
                onClick={() => setAddType('url')}
                disabled={uploading}
              />{' '}
              Url
            </div>
          </div>
        </div>
        <div>
          {addType === 'upload' ? (
            <>
              <div
                className={classnames(
                  'flex justify-center items-center h-[300px] pt-3',
                  { hidden: uploading }
                )}
              >
                <PastableDropzone
                  onupload={file => {
                    setUploadedFile(file);
                  }}
                  uploadedFile={uploadedFile}
                  available
                  disabled={uploading}
                />
              </div>
              <div
                className={classnames(
                  'flex justify-center items-center h-[300px]',
                  { hidden: !uploading }
                )}
              >
                <div
                  class="spinner-border"
                  style={{ width: '4rem', height: '4rem' }}
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <div>
              <input
                type="text"
                placeholder="Image Url"
                className="form-control mb-2"
                onInput={e => setImgUrl(e.target.value)}
                value={imgUrl}
              />
              <div className="flex flex-row justify-center items-center w-full h-[400px] overflow-hidden border-solid border-[1px] border-grey rounded bg-grey-light px-4">
                {imgUrl ? (
                  <img
                    alt="product"
                    src={imgUrl}
                    className="h-auto mx-auto rounded"
                  />
                ) : (
                  <div className="text-2xl text-center">
                    Image
                    <br />
                    Preview
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            if (addType === 'upload') uploadImage();
            // alert('upload not implemented yet, sorry bro');
            else {
              addImage({ simple_url: imgUrl });
              closeModal();
            }
          }}
          disabled={
            (addType === 'upload' && !uploadedFile) ||
            (addType === 'url' && !imgUrl) ||
            uploading
          }
        >
          Add
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
