export default function getImage(
  imageObj,
  size = 'thumbnail',
  showPlaceholder = true
) {
  let image = '';

  try {
    if (imageObj instanceof File) {
      image = URL.createObjectURL(imageObj);
    } else if (
      imageObj &&
      typeof imageObj === 'string' &&
      imageObj !== 'null'
    ) {
      const parsedObj = JSON.parse(imageObj);
      if (parsedObj.simple_url) return parsedObj.simple_url;
      image = `https://app.quote.black/images/${parsedObj[size]}`;
    } else if (showPlaceholder) {
      return '/placeholder.png';
    } else {
      return null;
    }

    return image;
  } catch (e) {
    return '/placeholder.png';
  }
}
