import React from 'react';
import PropTypes from 'prop-types';
import TCS from '../TCS';
import Calculations from './Calculations';
import QuoteSettings from './QuoteSettings';
import FooterNav from './FooterNav';

const Footer = ({
  subtotal,
  saveQuote,
  saveAndQuotes,
  slug,
  randslug,
  goBack,
  loading,
  id
}) => {
  return (
    <div>
      <div className="row">
        <div className="col col-md-12">
          <TCS tag="ol" context="edit" />
        </div>
      </div>
      <div className="row">
        <div className="col col-md-6">
          <Calculations subtotal={subtotal} />
        </div>
        <div className="col col-md-6">
          <QuoteSettings key={id} quoteId={id} />
        </div>
      </div>
      <FooterNav
        save={saveQuote}
        saveAndQuotes={saveAndQuotes}
        slug={slug}
        randslug={randslug}
        goBack={goBack}
        loading={loading}
      />
    </div>
  );
}

Footer.propTypes = {
  deliverySell: PropTypes.shape({
    A: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    B: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  subtotal: PropTypes.shape({
    A: PropTypes.number.isRequired,
    B: PropTypes.number.isRequired,
    AC: PropTypes.number.isRequired,
    BC: PropTypes.number.isRequired,
  }).isRequired,
  saveQuote: PropTypes.func.isRequired,
  saveAndQuotes: PropTypes.func.isRequired,
  slug: PropTypes.string,
  randslug: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Footer.defaultProps = {
  slug: null,
  randslug: null,
  loading: null,
};

export default Footer;
