import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import apiWrapper from '../helpers/apiWrapper';
import toast from 'react-hot-toast';

export const useTaxonomies = (currentType, searchString) => {
  const [taxonomies, setTaxonomies] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const getRemoteTaxonomies = async () => {
    try {
      const result = await apiWrapper.callApi(`/api/taxonomies`);
      setTaxonomies(
        (result?.taxonomies || []).filter(taxonomy => taxonomy.name)
      );
    } catch (err) {}
  };

  useAsyncEffect(getRemoteTaxonomies, []);

  const addTaxonomy = () => {
    const newTaxonomy = {
      name: '',
      type: currentType,
    };
    setTaxonomies(oldTaxonomies => {
      const newTaxonomies = [...oldTaxonomies];
      newTaxonomies.unshift(newTaxonomy);
      return newTaxonomies;
    });
  };

  const editTaxonomy = (index, value) => {
    setTaxonomies(oldTaxonomies => {
      const newTaxonomies = [...oldTaxonomies];
      newTaxonomies[index] = {
        ...newTaxonomies[index],
        name: value,
        modified: true,
      };
      return newTaxonomies;
    });
  };

  const submitChanges = async () => {
    try {
      setSubmitting(true);

      const modifiedTaxonomies = taxonomies
        .filter(taxonomy => taxonomy.modified)
        .map(({ name, type, id }) => ({ name, type, id }));

      const removedTaxonomies = modifiedTaxonomies.filter(
        taxonomy => !taxonomy.name
      );
      if (removedTaxonomies.length) {
        const allowRemoving = window.confirm(
          'The entries with an empty name will be deleted from the taxonomy list and all products they are used on. Do you want to continue?'
        );
        if (!allowRemoving) return;
      }

      await apiWrapper.callApiPost('/api/taxonomies', {
        taxonomies: modifiedTaxonomies,
        removedTaxonomies,
      });
      await getRemoteTaxonomies();
      toast.success('Changes submitted successfully!');
    } catch (err) {
      toast.error('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const filteredTaxonomies = taxonomies
    .map((taxonomy, i) => ({ ...taxonomy, index: i }))
    .filter(
      taxonomy =>
        taxonomy.type === currentType &&
        (taxonomy.name || '')
          .toLowerCase()
          .includes((searchString || '').toLowerCase())
    );

  return {
    taxonomies: filteredTaxonomies,
    addTaxonomy,
    submitChanges,
    submitting,
    editTaxonomy,
  };
};
