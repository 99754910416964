import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Search from '../../Search';
import Select from 'react-select';
import { useProductFilters } from '../../hooks/useProductFilters';
import { getFilterWheres } from '../../helpers/getFilteredProducts';

const ProductListHeader = ({
  table,
  searchKeys,
  updateResults,
  updateFilters,
  page,
  resetPage,
  cleanFilters = true,
}) => {
  const {
    filterValues,
    filters,
    results,
    setManufacturer,
    setSupplier,
    setLeadTime,
    setTag,
    setSorting,
    setSearchText,
  } = useProductFilters(page, resetPage, cleanFilters);

  useEffect(() => {
    if (results) {
      updateResults(results);
    }
  }, [results]);

  useEffect(() => {
    updateFilters(filters);
  }, [filters]);

  const { where, where_or_likes } = getFilterWheres(filters);

  return (
    <div>
      <Helmet defer={false}>
        <title>Products</title>
      </Helmet>
      <div className="row">
        <div className="col-md-12 mx-auto text-center">
          <h1>Products</h1>
        </div>
        <div className="col-md-12 mx-auto text-center">
          <h5>
            Ensure you update the product{' '}
            <b style={{ textDecoration: 'underline' }}>before</b> you add it to
            the quote
          </h5>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-4 mx-auto text-center">
          <Search
            table={table}
            keys={searchKeys}
            updateResults={updateResults}
            currentSearch={filters.searchText}
            setCurrentSearch={setSearchText}
            order={filters?.sorting?.value}
            where={where}
            where_or_likes={where_or_likes}
          />
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-4 mx-auto text-center">
          <div
            className="small text-secondary text-center"
            style={{ lineHeight: 1.2 }}
          >
            Use % to search for multiple words in a title which are not running
            concurrently, i.e. "Canteen%Stacking" will return "Canteen Chair
            Stacking"
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-3 mx-auto text-center">
          <Select
            value={filters.manufacturer}
            options={filterValues.manufacturerOptions}
            onChange={setManufacturer}
          />
        </div>
        <div className="col-md-3 mx-auto text-center">
          <Select
            value={filters.supplier}
            options={filterValues.supplierOptions}
            onChange={setSupplier}
          />
        </div>
        <div className="col-md-3 mx-auto text-center">
          <Select
            value={filters.leadTime}
            options={filterValues.leadTimeOptions}
            onChange={setLeadTime}
          />
        </div>
        <div className="col-md-3 mx-auto text-center">
          <Select
            value={filters.tag}
            options={filterValues.tagOptions}
            onChange={setTag}
          />
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-5 mx-auto text-center">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div>Sort by:</div>
            <div className="d-flex flex-column ml-2" style={{ flexGrow: 1 }}>
              <Select
                value={filters.sorting}
                options={filterValues.sortingOptions}
                onChange={setSorting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductListHeader.propTypes = {
  table: PropTypes.string,
  searchKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  updateResults: PropTypes.func.isRequired,
};

ProductListHeader.defaultProps = {
  searchKeys: null,
  table: null,
};

export default ProductListHeader;
