const isEmpty = val => !val && val !== 0;

export const orderImages = (images = [], chosenImages = {}) => {
  const { first, second, third } = chosenImages;

  const numberedImages = images.map((image, index) => ({
    image,
    realIndex: index,
  }));

  const firstImage = isEmpty(first) ? [] : [numberedImages[first]];
  const secondImage = isEmpty(second) ? [] : [numberedImages[second]];
  const thirdImage = isEmpty(third) ? [] : [numberedImages[third]];
  const filteredImages = numberedImages.filter((_, index) =>
    [first, second, third].includes(index) ? false : true
  );

  return [...firstImage, ...secondImage, ...thirdImage, ...filteredImages];
};
