/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { addMessage, deleteMessage } from '../actions/messages';

const messages = createReducer([], {
  [addMessage]: (state, { payload }) => {
    state.push(payload);
  },
  [deleteMessage]: (state, { payload }) => {
    state.splice(state.length - 1, 1);
  },
});

export default messages;
