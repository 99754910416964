import axios from 'axios';

const apiWrapper = {
  defaultTimeout: 15000,

  timeoutCall(ms, promise) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('API request timed out'));
      }, ms);

      promise.then(resolve, reject);
    });
  },

  async callApi(url, timeout = apiWrapper.defaultTimeout, method = 'get') {
    try {
      const response = await apiWrapper.timeoutCall(
        timeout,
        axios({
          url,
          method,
        })
      );

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async callApiPost(
    url,
    data = {},
    json = true,
    timeout = apiWrapper.defaultTimeout,
    put = false
  ) {
    const requestBody = json ? JSON.stringify(data) : data;

    const options = {
      url,
      method: put ? 'put' : 'post',
      data: requestBody,
    };

    if (json) {
      options.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await apiWrapper.timeoutCall(timeout, axios(options));

      if (response.status !== 200) {
        throw new Error(response.status);
      }

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  // fetch fails to send cookies so use axios anywhere auth involved
  async callApiWithCredentials({
    url,
    timeout = apiWrapper.defaultTimeout,
    method = 'get',
    responseType = 'json',
  }) {
    const options = {
      method,
      url,
      credentials: 'include',
      responseType,
      headers: { Cache: 'no-cache' },
    };

    try {
      const response = await apiWrapper.timeoutCall(timeout, axios(options));

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default apiWrapper;
