const formatAuditDate = date => {
  if (!date) return;
  const date_obj = new Date(date);
  const year = date_obj.getFullYear();
  const month = date_obj.toLocaleString('default', { month: '2-digit' });
  const day = date_obj.toLocaleString('default', { day: '2-digit' });
  return `${year}/${month}/${day}`;
};

const getAuditLogString = logEntry => {
  try {
    if (!logEntry) return "";
    if (!logEntry.user_name) return "";
    const name = logEntry.user_name || "no name";
    const date = formatAuditDate(logEntry.changed_date) || "no date";
    return `${name}, ${date}`;
  } catch (err) {
    return "";
  }
};

export { getAuditLogString };
