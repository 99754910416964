import React from 'react';
import PropTypes from 'prop-types';
import formatNum, { formatPercent } from '../../helpers/formatNum';

const CalculationsOption = ({
  title,
  subtotal,
  subtotalCost,
  installCost,
  otherCost,
  deliverySell = 0,
  currency,
}) => {
  const totalSell = Number.isNaN(+deliverySell)
    ? +subtotal
    : +subtotal + +deliverySell;
  const totalCost = +subtotalCost + +installCost + +otherCost;

  const grossProfit = totalSell - totalCost;
  const markUp = totalCost ? (grossProfit / totalCost) * 100 : 0;
  const grossMargin = totalSell ? (grossProfit / totalSell) * 100 : 0;

  return (
    <div>
      <h3>{title}</h3>
      <ul className="list-unstyled">
        <li>{`Total Cost: ${formatNum(totalCost, currency)}`}</li>
        <li>{`Total Sell: ${formatNum(totalSell, currency)}`}</li>
        <li>{`Mark-up: ${formatPercent(markUp)}%`}</li>
        <li>{`Gross Margin: ${formatPercent(grossMargin)}%`}</li>
        <li>{`Gross Profit: ${formatNum(grossProfit, currency)}`}</li>
      </ul>
    </div>
  );
};

CalculationsOption.propTypes = {
  currency: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
  subtotalCost: PropTypes.number.isRequired,
  installCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  otherCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  deliverySell: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CalculationsOption.defaultProps = {
  currency: process.env.REACT_APP_CURRENCY_SYMBOL,
  deliverySell: 0,
};

export default CalculationsOption;
