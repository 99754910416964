import React from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import IconButton from '../../IconButton';
import apiWrapper from '../../helpers/apiWrapper';

// TODO: migrate logic to actions
export class QuoteListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    QuoteListItem.api = apiWrapper;
  }

  deleteQuote = async id => {
    const { alert, deleteFromList, loadQuotes, page } = this.props;

    if (!window.confirm('Are you sure you want to delete this quote?')) {
      return;
    }

    // deletes quote from the state of the parent component
    deleteFromList(id);

    try {
      const res = await QuoteListItem.api.callApiWithCredentials({
        url: `/api/quotes/edit/${id}`,
        timeout: 15000,
        method: 'delete',
      });

      if (res.error) {
        alert.show('The quote was not deleted', {
          timeout: 3000,
          type: 'error',
        });

        return;
      }

      loadQuotes(page);

      alert.show(res.success, { timeout: 3000, type: 'success' });
    } catch (e) {
      console.log(e);
    }
  };

  handleDuplicateQuote = id => {
    this.props.handleDuplicate(id, this.duplicateQuote);
  }

  duplicateQuote = (id, duplicateFinishes) => {

    const { alert, loadQuotes, page } = this.props;

    QuoteListItem.api
      .callApiPost(`/api/quotes/duplicate/${id}`, {
        duplicateFinishes
      })
      .then(res => {
        if (res.error) {
          alert.show('The quote was not duplicated', {
            timeout: 3000,
            type: 'error',
          });

          return;
        }

        loadQuotes(page);

        alert.show(res.success, { timeout: 3000, type: 'success' });
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const { quote, isAdmin } = this.props;

    const date = new Date(quote.date);
    const { id, user } = quote;
    const displayedQuoteName = `${quote.quote_name} - ${quote.revision}${
      quote.is_uk ? ' (UK)' : ''
    }`;

    return (
      <div className="row">
        <div className="col">{date.toLocaleDateString('en-GB')}</div>
        <div className="col">{quote.customer_name && quote.customer_name}</div>
        <div className="col">
          <Link to={`/quotes/edit/${quote.id}`}>{displayedQuoteName}</Link>
        </div>
        <div className="col">{user && user.name}</div>
        <div className="col">{quote.status}</div>
        <div className="col">
          <IconButton
            onClick={() => {
              this.handleDuplicateQuote(id);
            }}
            icon="copy"
            title="Duplicate Quote"
          />
          {isAdmin ? (
            <IconButton
              icon="trash-alt"
              title="Delete quote"
              className="btn btn-link delete-quote-button"
              onClick={() => {
                this.deleteQuote(id);
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

QuoteListItem.propTypes = {
  loadQuotes: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  deleteFromList: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    date: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    quote_name: PropTypes.string.isRequired,
    revision: PropTypes.number.isRequired,
    customer_name: PropTypes.string.isRequired,
    user_id: PropTypes.number,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withAlert()(QuoteListItem);
