import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RIEInput } from 'riek';
import formatNum from '../helpers/formatNum';
import {
  updateDelivery,
  updateDeliveryExclusive,
  updateDeliveryInstall,
  updateDeliveryInclusive,
  updateVatText,
  updateVatInclusive,
  updateTotalNote,
  updateTotalNoteWithButton,
} from '../../actions/quote';
import { notifyManager } from '../../actions/quoteProducts';
import toast from 'react-hot-toast';

import { getSettingsEnabledMap } from '../../selectors/settings';

const mapStateToProps = ({ quote, ...state }) => {
  return {
    quote,
    currency: state.settings.currency,
    quoteSettings: getSettingsEnabledMap(state),
  };
};

const mapDispatchToProps = {
  updateDelivery,
  updateDeliveryExclusive,
  updateDeliveryInstall,
  updateDeliveryInclusive,
  updateVatText,
  updateVatInclusive,
  updateTotalNote,
  updateTotalNoteWithButton,
  notifyManager,
};

const Total = ({
  quote,
  option,
  title,
  context = 'edit',
  note,
  subtotal,
  updateDelivery,
  updateDeliveryExclusive,
  updateDeliveryInstall,
  updateDeliveryInclusive,
  updateVatText,
  updateVatInclusive,
  updateTotalNote,
  updateTotalNoteWithButton,
  currency,
  quoteSettings,
}) => {
  const [clientNoteValue, setClientNoteValue] = React.useState(note);
  const {
    id,
    delivery,
    deliveryExclusive,
    deliveryInstall,
    deliveryInclusive,
    vatText,
    vatInclusive,
    vatRate,
  } = quote;
  const thisOptionDeliveryCosts = delivery[option] || 0;
  const total = Number.isNaN(+thisOptionDeliveryCosts)
    ? +subtotal
    : +subtotal + +thisOptionDeliveryCosts;
  const formattedDelivery = Number.isNaN(+thisOptionDeliveryCosts)
    ? thisOptionDeliveryCosts
    : formatNum(thisOptionDeliveryCosts, false);

  const valueDeliveryExclusive =
    (deliveryExclusive || {})[option] ||
    'Total ex. VAT ex. Delivery & Installation';
  const valueDeliveryInstall =
    (deliveryInstall || {})[option] || 'Delivery & Installation';
  const valueDeliveryInclusive =
    (deliveryInclusive || {})[option] ||
    'Total ex. VAT incl. Delivery & Installation';
  const valueVatText = (vatText || {})[option] || 'VAT';
  const valueVatInclusive =
    (vatInclusive || {})[option] ||
    'Total incl. VAT incl. Delivery & Installation';

  const {
    hide_price: hidePrice,
    show_vat: showVat,
    large_layout: largeLayout,
    client_notes_disabled,
  } = quoteSettings;
  const vat = (total * vatRate) / 100;

  return hidePrice && context !== 'edit' ? null : (
    <div
      className={`row total-wrap mt-5 nobreak ${
        !!largeLayout ? 'large-layout' : 'small-layout'
      }`}
    >
      <header className="total-header col-md-12">
        <h3>{`${title}`}</h3>
      </header>
      <div
        className={
          context === 'view' && !client_notes_disabled
            ? 'col-md-10 product-view-total'
            : 'col-md-12'
        }
      >
        <div className="total-body p-2">
          <div className="d-flex justify-content-between">
            <span>
              {context === 'edit' ? (
                <RIEInput
                  value={valueDeliveryExclusive}
                  change={value => {
                    updateDeliveryExclusive({ option, value });
                  }}
                  propName="deliveryExclusive"
                  className="editable"
                />
              ) : (
                valueDeliveryExclusive
              )}
            </span>
            <span>{formatNum(subtotal, currency)}</span>
          </div>
          <div
            data-test-id="di-block"
            className="d-flex justify-content-between"
          >
            <span>
              {context === 'edit' ? (
                <RIEInput
                  value={valueDeliveryInstall}
                  change={value => {
                    updateDeliveryInstall({ option, value });
                  }}
                  propName="deliveryInstall"
                  className="editable"
                />
              ) : (
                valueDeliveryInstall
              )}
            </span>
            <span data-test-id="di">
              {!Number.isNaN(+thisOptionDeliveryCosts) && currency}
              {context === 'edit' ? (
                <RIEInput
                  value={formattedDelivery}
                  change={value => {
                    updateDelivery({ option, value });
                  }}
                  propName="delivery"
                  className="editable"
                />
              ) : (
                formattedDelivery
              )}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              {context === 'edit' ? (
                <RIEInput
                  value={valueDeliveryInclusive}
                  change={value => {
                    updateDeliveryInclusive({ option, value });
                  }}
                  propName="deliveryInclusive"
                  className="editable"
                />
              ) : (
                valueDeliveryInclusive
              )}
            </span>
            <span data-test-id="total-incl-di">
              {formatNum(total, currency)}
            </span>
          </div>
          {showVat ? (
            <>
              <div className="d-flex justify-content-between">
                <span>
                  {context === 'edit' ? (
                    <RIEInput
                      value={valueVatText}
                      change={value => {
                        updateVatText({ option, value });
                      }}
                      propName="vatText"
                      className="editable"
                    />
                  ) : (
                    valueVatText
                  )}
                </span>
                <span data-test-id="total-incl-di">
                  {formatNum(vat, currency)}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span>
                  {context === 'edit' ? (
                    <RIEInput
                      value={valueVatInclusive}
                      change={value => {
                        updateVatInclusive({ option, value });
                      }}
                      propName="vatInclusive"
                      className="editable"
                    />
                  ) : (
                    valueVatInclusive
                  )}
                </span>
                <span data-test-id="total-incl-di">
                  {formatNum(vat + total, currency)}
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {context === 'view' && !client_notes_disabled && (
        <div className="col-md-2 product-view-notes not-printable">
          <div className="captions text-center">Client Notes</div>
          <textarea
            placeholder="Leave your feedback here ..."
            name={option}
            className="form-control"
            style={{
              height: 'calc(100% - 32px)',
              resize: 'none',
              borderRadius: 0,
            }}
            onChange={e => setClientNoteValue(e.target.value)}
            value={clientNoteValue}
          />
          <button
            className="btn btn-secondary btn-sm"
            style={{
              width: '100%',
              height: '32px',
              backgroundColor: '#F2F2F2',
              borderColor: '#D4D4D4',
              color: '#323232',
              borderTopWidth: 0,
              borderRadius: 0,
            }}
            onClick={async () => {
              const { userId, customerName, quoteName, slug, randslug } = quote;

              notifyManager({
                userId,
                customerName,
                quoteName,
                slug,
                randslug,
              });

              try {
                updateTotalNoteWithButton(quote.id, option, clientNoteValue);
                toast.success('Successfully updated the note!');
              } catch (err) {
                toast.error('Something went wrong when updating the note.');
              }
            }}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

Total.propTypes = {
  option: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  context: PropTypes.string,
  updateNote: PropTypes.func,
  note: PropTypes.string,
  currency: PropTypes.string,
};

Total.defaultProps = {
  context: 'edit',
  updateNote: null,
  note: null,
  currency: process.env.REACT_APP_CURRENCY_SYMBOL,
};

export default connect(mapStateToProps, mapDispatchToProps)(Total);
