import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

library.add(faGoogle);

const Login = () => (
  <div className="container my-3 text-center">
    <a href="/auth/login" className="btn btn-primary">
      <FontAwesomeIcon icon={['fab', 'google']} /> Login with Google
    </a>
  </div>
);

export default Login;
