import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import TagManager from 'react-gtm-module';
import NotFound from '../../404';
import HeaderView from './HeaderView';
import TCS from '../TCS';
import GroupsDisplay from '../GroupsDisplay';
import Total from '../Total';
import Welcome from './Welcome';
import calculateTotals from '../../helpers/calculateTotals';
import { quoteStatus } from '../../../../types';
import apiWrapper from '../../helpers/apiWrapper';

import {
  getQuoteBySlug,
  loadDefaultTcsAction,
  approve,
} from '../../../actions/quote';
import { loadSettings } from '../../../actions/settings';
import { getSettingsEnabledMap } from '../../../selectors/settings';
import { getQuoteProducts } from '../../../actions/quoteProducts';
import { getGroups } from '../../../actions/groups';
import { getManagerNameById } from '../../../actions/managers';

const mapDispatchToProps = {
  getQuoteBySlug,
  getQuoteProducts,
  getGroups,
  loadDefaultTcsAction,
  getManagerNameById,
  loadSettings,
  approve,
};

// TODO: move groups and products states to corresponding components
function mapStateToProps({ quote, quoteProducts, groups, managers, ...state }) {
  return {
    quote,
    managerName: managers.managerName,
    groups: groups.groups,
    products: quoteProducts.products,
    settingsMap: getSettingsEnabledMap(state),
    subtotal: calculateTotals(
      quoteProducts.products,
      state.settings.currencyName
    ),
  };
}
class QuoteView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.quoteRef = React.createRef();
  }

  async componentDidMount() {
    try {
      this.getTaxonomies();
      await this.constructQuoteState();

      this.setState({
        ready: true,
        approvePopupOpen: false,
      });

      TagManager.initialize({
        gtmId: 'GTM-MRFLWTC',
      });

      window.addEventListener('beforeunload', this.beforeUnload);
    } catch (e) {
      console.log(e);
    }
  }

  closeApproveModalPopup = () => {
    this.setState({ approvePopupOpen: false });
  };

  openApproveModalPopup = () => {
    this.setState({ approvePopupOpen: true });
  };

  getTaxonomies = async () => {
    try {
      const result = await apiWrapper.callApi(`/api/taxonomies`);
      const taxonomies = (result?.taxonomies || []).filter(
        taxonomy => taxonomy.name
      );
      const manufacturers = taxonomies.filter(
        taxonomy => taxonomy.type == 'manufacturer'
      );
      const suppliers = taxonomies.filter(
        taxonomy => taxonomy.type == 'supplier'
      );
      const leadTimes = taxonomies.filter(
        taxonomy => taxonomy.type == 'lead_time'
      );
      const tags = taxonomies.filter(taxonomy => taxonomy.type == 'tag');

      this.setState({
        taxonomies: { manufacturers, suppliers, leadTimes, tags },
      });

      setTimeout(() => {
        this.setState({
          taxonomiesLoaded: true,
        });
      }, 1000);
    } catch (err) {}
  };

  constructQuoteState = async () => {
    const { settingsMap = {}, match } = this.props;
    const { slug, randslug } = match.params;

    const promises = [];

    const { payload } = await this.props.getQuoteBySlug(randslug, slug);
    const { id, userId } = payload;

    promises.push(this.props.loadSettings(id));
    promises.push(this.props.getQuoteProducts(id, true));
    promises.push(this.props.getGroups(id));
    promises.push(this.props.getManagerNameById(userId));

    return Promise.all(promises);
  };

  approve = () => {
    const { quote } = this.props;
    const { id, userId, customerName, quoteName, slug, randslug } = quote;

    this.props.approve({ id, userId, customerName, quoteName, slug, randslug });
  };

  render() {
    const {
      quote,
      settingsMap = {},
      subtotal = { A: 0, B: 0 },
      managerName,
    } = this.props;

    if (!this.state.ready) {
      return null;
    }

    const {
      quoteName = '',
      delivery = { A: 0, B: 0 },
      revision = 1,
      date = '',
      customerName = '',
      error,
      totalNotes,
      status,
      isUk,
      logo,
    } = quote;

    const {
      client_link_active,
      client_notes_disabled,
      show_option_a,
      show_totals,
      show_approve_button,
      show_welcome,
      large_layout,
      grey_out,
    } = settingsMap;

    const { taxonomies, taxonomiesLoaded } = this.state;

    const { search } = this.props.location;
    const pdf = search.includes('pdf');

    if (error || !client_link_active) {
      return (
        <Route
          render={() => (
            <NotFound
              title=""
              text="Your quote is no longer active! Please contact the sales team who will be able to assist you"
            />
          )}
          status={404}
        />
      );
    }

    return (
      <div
        className={`quote-view-wrap ${
          large_layout ? 'large-layout-container' : ''
        } ${taxonomiesLoaded ? 'taxonomies-are-loaded' : ''}`}
        ref={this.quoteRef}
      >
        <div className="container-fluid">
          <HeaderView
            quoteName={quoteName}
            revision={revision}
            date={date}
            selectedCustomer={customerName}
            managerName={managerName}
            isUk={settingsMap.is_uk}
            isUS={settingsMap.is_us}
            logo={logo}
          />
          {!large_layout && (
            <div className="row text-center d-none d-md-flex">
              <div
                className={`${
                  client_notes_disabled ? 'col-md-12' : 'col-md-10'
                } captions-columns`}
              >
                <div className="row captions">
                  <div className="image-column h-100 my-auto">Image</div>
                  <div className="description-column h-100 my-auto">
                    Description
                  </div>
                  <div className="information-column h-100 my-auto">
                    Information
                  </div>
                  <div className="qty-column h-100 my-auto">QTY</div>
                  <div className="price-column h-100 my-auto">Our Price</div>
                  <div className="total-column h-100 my-auto">Our Total</div>
                </div>
              </div>
              {!client_notes_disabled && (
                <div className="col col-md-2 not-printable">
                  <div className="captions">
                    <span className="restrict-width">Client Notes</span>
                  </div>
                </div>
              )}
            </div>
          )}
          <GroupsDisplay
            context="view"
            showOptions={show_option_a}
            greyOut={grey_out}
            updateNote={this.updateNote}
            taxonomies={taxonomies}
            taxonomiesLoaded={taxonomiesLoaded}
          />
          {show_totals && (
            <div className="quote-view-totals">
              <Total
                title={show_option_a ? 'Low Cost' : 'Total'}
                option="B"
                subtotal={subtotal.B}
                delivery={delivery.B}
                context="view"
                note={totalNotes.B}
                updateNote={this.updateTotalNote}
                showOptions={show_option_a}
              />
              {show_option_a && (
                <Total
                  option="A"
                  title="High Cost"
                  subtotal={subtotal.A}
                  delivery={delivery.A}
                  context="view"
                  note={totalNotes.A}
                />
              )}
            </div>
          )}
          {<TCS tag="ol" context="view" />}
          {show_approve_button && (
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={this.openApproveModalPopup}
              disabled={status !== quoteStatus.open}
              data-test-id="approve_quote"
            >
              Approve
            </button>
          )}
        </div>
        {!pdf && show_welcome && <Welcome />}
        <Modal
          size="lg"
          className="welcome-modal"
          isOpen={this.state.approvePopupOpen}
          toggle={this.closeApproveModalPopup}
        >
          <ModalBody>
            Are you sure you want to approve this quote and order?
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              aria-label="Ok"
              onClick={() => {
                this.approve();
                this.closeApproveModalPopup();
              }}
            >
              Ok
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Cancel"
              onClick={this.closeApproveModalPopup}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

QuoteView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      randslug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteView);
