export const getSettingsList = (state) => {
  const { settings: { settings } } = state;

  return Object.values(settings);
};

export const getSettingsEnabledMap = (state) => {
  if (state && state.settings) {
    const {settings: {settings}} = state;

    return Object.values(settings)
      .reduce((acc, obj) => {
        acc[obj.slug] = obj.enabled;

        return acc;
      }, {});
  } 

  return {};
}