import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { productType, childrenType, saCatsType } from '../../../../types';
import {
  calculateMarkUp,
  calculateSellPrice,
  getImportCosts,
  getFixedProfit,
} from '../../helpers/costs';
import { useCurrencies } from '../../hooks/useCurrencies';
import { useModal } from '../../hooks/useModal';
import { getAuditLogString } from '../../helpers/getAuditLogString';
import { ComponentsForm } from '../../components/ComponentsForm';
import { Button } from 'react-bootstrap';
import { useGetTaxonomies } from '../../taxonomies/useGetTaxonomies';
import {
  getChosenTaxonomies,
  getTaxonomyOptions,
} from '../../taxonomies/taxonomyUtils';
import { ImageArea } from '../../images/ImageArea';
import moment from 'moment';

const defaultCurrency = { label: 'EUR', value: 'EUR' };

export const CURRENCY_SYMBOLS = {
  GBP: `£`,
  USD: `$`,
  EUR: `€`,
};

const ProductForm = ({
  form,
  handleChange,
  handleBlur,
  addFile,
  removeImage,
  selectImage,
  unselectImage,
  children,
  isQuoteProduct,
  quoteId,
  onUpdateComponents,
}) => {
  const [markUp, setMarkUp] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [sellPriceInitialized, setSellPriceInitialized] = useState(false);
  const [profit, setProfit] = useState(0);
  const [currency, setCurrency] = useState(defaultCurrency);
  console.log('form: ', form);

  const [isCostPriceDisabled, setIsCostPriceDisabled] = useState(false);

  const { currenciesOptions } = useCurrencies();

  const { manufacturers, suppliers, leadTimes, tags } = useGetTaxonomies();

  const manufacturerOptions = getTaxonomyOptions(
    form.manufacturer,
    manufacturers
  );
  const supplierOptions = getTaxonomyOptions(form.supplier, suppliers);
  const leadTimeOptions = getTaxonomyOptions(form.lead_time, leadTimes);
  const tagOptions = getTaxonomyOptions(form.product_code, tags);

  const chosenManufacturers = getChosenTaxonomies(
    form.manufacturer,
    manufacturers
  );

  const chosenSuppliers = getChosenTaxonomies(form.supplier, suppliers);
  const chosenLeadTimes = getChosenTaxonomies(form.lead_time, leadTimes);
  const chosenTags = getChosenTaxonomies(form.product_code, tags);

  const handleTaxonomyChange = (chosen, name) =>
    handleChange({
      target: { value: chosen.map(option => option.value).join('+'), name },
    });

  const componentsCount = (form?.components?.components || []).length;
  const haveComponents = componentsCount > 0;
  const usingOwnRRP = !!form.supplier_rrp || !!form.supplier_discount;

  useEffect(() => {
    if (sellPriceInitialized !== form.product_id && form.cost_price) {
      setMarkUp(
        calculateMarkUp(
          +form.sell_price,
          +form.cost_price,
          +form.fixed_price_surcharge,
          +form.percentage_increase
        )
      );

      setSellPriceInitialized(form.product_id);
    }
  }, [sellPriceInitialized, form.sell_price, form.cost_price]);

  const {
    manufacturer,
    manufacturer_is_supplier,
    manufacturer_unknown,
    add_supplier_details,
    last_time_used,
  } = form;

  useEffect(() => {
    if (manufacturer_unknown) {
      handleChange({
        target: {
          name: 'manufacturer',
          value: '',
        },
      });
    }
  }, [manufacturer_unknown]);

  useEffect(() => {
    if (manufacturer_is_supplier) {
      handleChange({
        target: {
          name: 'supplier',
          value: '',
        },
      });
    }
  }, [manufacturer_is_supplier]);

  useEffect(() => {
    if (!add_supplier_details) {
      handleChange({
        target: {
          name: 'supplier_sku',
          value: '',
        },
      });
      handleChange({
        target: {
          name: 'supplier_title',
          value: '',
        },
      });
    }
  }, [add_supplier_details]);

  useEffect(() => {
    console.log(form.supplier_rrp, form.supplier_discount);

    if (form.supplier_rrp && form.supplier_discount) {
      setIsCostPriceDisabled(true);
    } else {
      setIsCostPriceDisabled(false);
      return;
    }

    const supplier_rrp = +form.supplier_rrp;
    const supplier_discount = 100 - +form.supplier_discount;

    handleChange({
      target: {
        name: 'cost_price',
        value: +((supplier_rrp * supplier_discount) / 100),
      },
    });
  }, [
    form.supplier_rrp,
    form.supplier_discount,
    setIsCostPriceDisabled,
    handleChange,
  ]);

  useEffect(() => {
    const sellPrice = calculateSellPrice(
      +form.cost_price,
      +form.fixed_price_surcharge,
      +form.percentage_increase,
      +markUp
    );

    const profit = getFixedProfit(+form.cost_price, +markUp);

    handleChange({ target: { name: 'sell_price', value: sellPrice } });
    setProfit(profit);
    setSellPrice(sellPrice);
  }, [
    markUp,
    form.cost_price,
    form.percentage_increase,
    form.fixed_price_surcharge,
  ]);

  const handleMarkUpChange = e => {
    setMarkUp(e.target.value);

    setSellPrice(
      calculateSellPrice(
        +form.cost_price,
        +form.fixed_price_surcharge,
        +form.percentage_increase,
        +markUp
      )
    );
  };

  useEffect(() => {
    let currencyValue;

    if (form.currency) {
      currencyValue = currenciesOptions.find(c => c.value === form.currency);
    } else {
      currencyValue = defaultCurrency;
    }

    setCurrency(currencyValue || defaultCurrency);
  }, [form.currency, currenciesOptions]);

  const handleChangeCurrency = currency => {
    handleChange({ target: { name: 'currency', value: currency.value } });
  };

  const handleSellPriceChange = e => {
    handleChange(e);
    setSellPrice(e.target.value);
  };

  const handleEnterSellPrice = e => {
    if (e.key === 'Enter') onBlurSellPrice(e);
  };

  const onBlurSellPrice = e => {
    setMarkUp(
      calculateMarkUp(
        +sellPrice,
        +form.cost_price,
        +form.fixed_price_surcharge,
        +form.percentage_increase
      )
    );
  };

  const { closeModal, openModal } = useModal();

  const showComponentsModal = useCallback(() => {
    openModal({
      title: 'Product Components',
      isVeryLarge: true,
      content: (
        <ComponentsForm
          productCurrency={currency}
          productId={isQuoteProduct ? form.id : form.product_id}
          isQuoteProduct={isQuoteProduct}
          components={form.components}
          onUpdate={onUpdateComponents}
          quoteId={quoteId}
          productsForm={form}
          handleProductChange={handleChange}
        />
      ),
      actionButtons: (
        <Button
          variant="secondary"
          onClick={() => {
            closeModal();
            if (isQuoteProduct) {
              setTimeout(() => {
                document.body.classList.add('modal-open');
              }, 500);
            }
          }}
        >
          Close
        </Button>
      ),
    });
  }, [
    currency,
    form.product_id,
    form.id,
    isQuoteProduct,
    quoteId,
    onUpdateComponents,
    form.components,
  ]);

  const getFormAuditLogString = field => {
    const audit_log =
      form.audit_log && typeof form.audit_log == 'string'
        ? JSON.parse(form.audit_log)
        : form.audit_log || {};
    const log_entry = audit_log[field];
    return getAuditLogString(log_entry);
  };

  console.log('form: ', form);

  return (
    <form method="post">
      <div className="row">
        <div style={{ paddingLeft: '15px' }} className="mt-3 mb-3">
          <b>Last time used:</b>{' '}
          {last_time_used
            ? moment(last_time_used).format('YYYY-MM-DD HH:mm')
            : 'Not used yet'}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <input
              type="text"
              name="product_name"
              placeholder="Product Title *"
              className="form-control"
              defaultValue={form.product_name || ''}
              onInput={handleChange}
              onBlur={handleBlur}
              required
            />
            <span className="mt-1 small text-secondary">
              {getFormAuditLogString('product_name')}
            </span>
          </div>
          {!!add_supplier_details && (
            <div className="d-flex flex-column mb-3">
              <input
                type="text"
                name="supplier_title"
                placeholder="Supplier Title"
                className="form-control"
                onInput={handleChange}
                defaultValue={form.supplier_title || ''}
              />
              <span className="mt-1 small text-secondary">
                {getFormAuditLogString('supplier_title')}
              </span>
            </div>
          )}
          <div className="d-flex flex-column mb-3">
            <div className="d-flex justify-content-end align-items-center">
              <span className="mr-2">Add Supplier Title and SKU</span>
              <input
                type="checkbox"
                name={'add_supplier_details'}
                onChange={e => {
                  handleChange({
                    target: {
                      name: 'add_supplier_details',
                      value: e.target.checked,
                    },
                  });
                }}
                checked={add_supplier_details}
              />
            </div>
            <span className="mt-1 small text-secondary d-flex justify-content-end">
              {getFormAuditLogString('add_supplier_details')}
            </span>
          </div>
          <div className="d-flex flex-column mb-3">
            <input
              type="text"
              name="dimensions"
              placeholder='Dimensions i.e. "W1600 x D800 x H725"'
              className="form-control"
              defaultValue={form.dimensions || ''}
              onInput={handleChange}
            />
            <span className="mt-1 small text-secondary">
              {getFormAuditLogString('dimensions')}
            </span>
          </div>

          <div className="d-flex flex-column mb-3">
            <textarea
              name="bullets"
              rows="3"
              placeholder="Bullets"
              className="form-control"
              value={form.bullets || ''}
              onInput={handleChange}
            />
            <span className="mt-1 small text-secondary">
              {getFormAuditLogString('bullets')}
            </span>
          </div>
          <div className="d-flex flex-column mb-3">
            <input
              type="text"
              name="warranty"
              placeholder='Guarantee i.e. "10-year guarantee"'
              className="form-control"
              defaultValue={form.warranty || ''}
              onInput={handleChange}
            />
            <span className="mt-1 small text-secondary">
              {getFormAuditLogString('warranty')}
            </span>
          </div>
          <div className="form-group">
            <div className="d-flex flex-column mb-1">
              <Select
                value={manufacturer_unknown ? [] : chosenManufacturers}
                onChange={chosen =>
                  handleTaxonomyChange(chosen, 'manufacturer')
                }
                isMulti
                name="manufacturer"
                options={manufacturerOptions}
                placeholder={
                  manufacturer_unknown
                    ? 'UNKNOWN'
                    : 'Manufacturer i.e. "Profim, OMP"'
                }
                isDisabled={manufacturer_unknown}
              />
              <span className="mt-1 small text-secondary">
                {getFormAuditLogString('manufacturer')}
              </span>
            </div>
            <div className="d-flex flex-column mb-3">
              <div className="d-flex justify-content-end align-items-center">
                <span className="mr-2">Manufacturer is unknown</span>
                <input
                  type="checkbox"
                  name={'manufacturer_unknown'}
                  onChange={e => {
                    handleChange({
                      target: {
                        name: 'manufacturer_unknown',
                        value: e.target.checked,
                      },
                    });
                  }}
                  checked={manufacturer_unknown}
                />
              </div>
              <span className="mt-1 small text-secondary d-flex justify-content-end">
                {getFormAuditLogString('manufacturer_unknown')}
              </span>
            </div>
            <div className="d-flex flex-column mb-1">
              <Select
                value={manufacturer_is_supplier ? [] : chosenSuppliers}
                isDisabled={manufacturer_is_supplier}
                onChange={chosen => handleTaxonomyChange(chosen, 'supplier')}
                isMulti
                name="supplier"
                options={supplierOptions}
                placeholder={
                  manufacturer_is_supplier
                    ? 'MANUFACTURER'
                    : 'Supplier i.e. "Edit Office, Spacestor"'
                }
              />
              <span className="mt-1 small text-secondary">
                {getFormAuditLogString('supplier')}
              </span>
            </div>
            <div className="d-flex flex-column mb-3">
              <div className="d-flex justify-content-end align-items-center">
                <span className="mr-2">
                  Buy direct, no supplier entry required
                </span>
                <input
                  type="checkbox"
                  name={'manufacturer_is_supplier'}
                  onChange={e => {
                    handleChange({
                      target: {
                        name: 'manufacturer_is_supplier',
                        value: e.target.checked,
                      },
                    });
                  }}
                  checked={manufacturer_is_supplier}
                />
              </div>
              <span className="mt-1 small text-secondary d-flex justify-content-end">
                {getFormAuditLogString('manufacturer_is_supplier')}
              </span>
            </div>
            <div className="row mb-3">
              <span className="col-md-12">
                <label htmlFor="supplier_price_list_url">
                  Supplier Price List URL
                  <input
                    type="text"
                    name="supplier_price_list_url"
                    id="supplier_price_list_url"
                    placeholder="Supplier Price List URL"
                    className="form-control"
                    defaultValue={form.supplier_price_list_url || ''}
                    onInput={handleChange}
                    style={{ minWidth: '400px' }}
                  />
                </label>
                {form.supplier_price_list_url && (
                  <a
                    href={form.supplier_price_list_url}
                    target="_blank"
                    style={{ display: 'inline-block', marginLeft: '10px' }}
                  >
                    Open Link
                  </a>
                )}
              </span>
              <span className="col-md-12 small text-secondary">
                {getFormAuditLogString('supplier_price_list_url')}
              </span>
            </div>

            <p className="row">
              <span className="col-md-6 d-flex flex-column">
                <label htmlFor="suggested_direct_markup">
                  Suggested direct markup
                  <input
                    type="number"
                    name="suggested_direct_markup"
                    id="suggested_direct_markup"
                    placeholder="Suggested Markup (direct) %"
                    className="form-control"
                    defaultValue={form.suggested_direct_markup || ''}
                    onInput={handleChange}
                  />
                </label>
                <span className="small text-secondary">
                  {getFormAuditLogString('suggested_direct_markup')}
                </span>
              </span>
              <span className="col-md-6 d-flex flex-column">
                <label htmlFor="suggested_trade_markup">
                  Suggested trade markup
                  <input
                    type="number"
                    id="suggested_trade_markup"
                    name="suggested_trade_markup"
                    placeholder="Suggested Markup (trade) %"
                    className="form-control"
                    defaultValue={form.suggested_trade_markup || ''}
                    onInput={handleChange}
                  />
                </label>
                <span className="small text-secondary">
                  {getFormAuditLogString('suggested_trade_markup')}
                </span>
              </span>
            </p>
            <div className="d-flex flex-column mb-1">
              <textarea
                name="sales_notes"
                rows="4"
                placeholder="Internal sales team notes..."
                className="form-control"
                onInput={handleChange}
                value={form.sales_notes || ''}
              />
              <span className="mt-2 small text-secondary">
                {getFormAuditLogString('sales_notes')}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <ImageArea
            addImage={addFile}
            removeImage={removeImage}
            selectImage={selectImage}
            unselectImage={unselectImage}
            image={form.image}
            chosenImages={{
              first: form.first_img,
              second: form.second_img,
              third: form.third_img,
            }}
            className="mb-4"
          />
          <p className="row">
            <span className="col-md-6">
              <input
                type="text"
                placeholder="Finishes"
                className="form-control"
                name="swatches_title"
                onInput={handleChange}
                defaultValue={form.swatches_title || ''}
              />
              <span className="mt-1 small text-secondary">
                {getFormAuditLogString('swatches_title')}
              </span>
            </span>
            <span className="col-md-6">
              <input
                type="url"
                placeholder="Finishes Folder URL"
                className="form-control"
                name="swatches_url"
                onInput={handleChange}
                defaultValue={form.swatches_url || ''}
                onBlur={handleBlur}
              />
              <span className="mt-1 small text-secondary">
                {getFormAuditLogString('swatches_url')}
              </span>
            </span>
          </p>
          <p className="row">
            <span className="col-md-6">
              <input
                type="text"
                placeholder="Brochure"
                className="form-control"
                name="brochure_title"
                onInput={handleChange}
                defaultValue={form.brochure_title || ''}
              />
              <span className="mt-1 small text-secondary">
                {getFormAuditLogString('brochure_title')}
              </span>
            </span>
            <span className="col-md-6">
              <input
                type="url"
                placeholder="Brochure Folder URL"
                className="form-control"
                name="brochure_url"
                onInput={handleChange}
                defaultValue={form.brochure_url || ''}
                onBlur={handleBlur}
              />
              <span className="mt-1 small text-secondary">
                {getFormAuditLogString('brochure_url')}
              </span>
            </span>
          </p>
          <p>
            <Select
              value={chosenLeadTimes}
              onChange={({ value }) =>
                handleChange({
                  target: { value: `${value}`, name: 'lead_time' },
                })
              }
              name="lead_time"
              options={leadTimeOptions}
              placeholder='Lead Time i.e "4 to 6 Weeks"'
            />
            <span className="mt-1 small text-secondary">
              {getFormAuditLogString('lead_time')}
            </span>
          </p>
          <p>
            <input
              type="text"
              name="sku"
              placeholder="SKU"
              className="form-control"
              onInput={handleChange}
              defaultValue={form.sku || ''}
            />
            <span className="mt-1 small text-secondary">
              {getFormAuditLogString('sku')}
            </span>
          </p>
          {!!add_supplier_details && (
            <>
              <p>
                <input
                  type="text"
                  name="supplier_sku"
                  placeholder="Supplier SKU"
                  className="form-control"
                  onInput={handleChange}
                  defaultValue={form.supplier_sku || ''}
                />
                <span className="mt-1 small text-secondary">
                  {getFormAuditLogString('supplier_sku')}
                </span>
              </p>
            </>
          )}
          <p>
            <Select
              value={chosenTags}
              onChange={chosen => handleTaxonomyChange(chosen, 'product_code')}
              isMulti
              name="product_code"
              options={tagOptions}
              placeholder="Tag"
            />
            <span className="mt-1 small text-secondary">
              {getFormAuditLogString('product_code')}
            </span>
          </p>
        </div>
      </div>
      <div className="row product-form-costs">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h3>Costs</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="currency">
                    Сurrency
                    <Select
                      value={currency}
                      options={currenciesOptions}
                      onChange={handleChangeCurrency}
                      isDisabled={haveComponents}
                    />
                  </label>
                  <span className="mt-1 small text-secondary">
                    {getFormAuditLogString('currency')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <p className="row">
                <span className="col-md-6 mb-3">
                  <div className="row cost-price">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="supplier_rrp">
                        Supplier RRP
                        <div class="input-group">
                          <input
                            id="supplier_rrp"
                            type="number"
                            name="supplier_rrp"
                            placeholder="Supplier RRP"
                            className="form-control"
                            aria-describedby="supplier_rrp"
                            defaultValue={form.supplier_rrp || ''}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            disabled={haveComponents}
                          />
                        </div>
                        <span className="mt-1 small text-secondary">
                          {getFormAuditLogString('supplier_rrp')}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="supplier_discount">
                        Supplier % Discount
                        <div class="input-group">
                          <input
                            id="supplier_discount"
                            type="number"
                            name="supplier_discount"
                            placeholder="Supplier Discount"
                            className="form-control"
                            aria-describedby="supplier_discount"
                            defaultValue={form.supplier_discount || ''}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            disabled={haveComponents}
                          />
                        </div>
                        <span className="mt-1 small text-secondary">
                          {getFormAuditLogString('supplier_discount')}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="row cost-price">
                    <div className="col-md-6">
                      <label htmlFor="cost_price">
                        Supplier Cost Price
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span
                              class="input-group-text"
                              id="supplier_cost_price"
                            >
                              {CURRENCY_SYMBOLS[currency.value]}
                            </span>
                          </div>
                          <input
                            id="cost_price"
                            type="number"
                            name="cost_price"
                            placeholder="Cost Price"
                            className="form-control"
                            aria-describedby="supplier_cost_price"
                            value={form.cost_price || 0}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isCostPriceDisabled || haveComponents}
                            required
                          />
                        </div>
                      </label>
                    </div>
                    <div
                      className="col-md-6 components-button-container"
                      style={{ marginBottom: -15 }}
                    >
                      <button
                        onClick={showComponentsModal}
                        type="button"
                        className="btn btn-primary"
                        disabled={usingOwnRRP}
                      >
                        Components
                      </button>
                      <div className="ml-2 components-count">
                        <span className="components-count-inner">
                          {componentsCount} Components
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="mt-1 small text-secondary">
                    {getFormAuditLogString('cost_price')}
                  </span>
                </span>
                <span className="col-md-6 mb-3">
                  <label htmlFor="sell_price">
                    Sell Price
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="sell_price">
                          {CURRENCY_SYMBOLS[currency.value]}
                        </span>
                      </div>
                      <input
                        id="sell_price"
                        type="number"
                        name="sell_price"
                        placeholder="Sell Price *"
                        className="form-control"
                        aria-describedby="sell_price"
                        value={sellPrice}
                        onChange={handleSellPriceChange}
                        onKeyDown={handleEnterSellPrice}
                        onBlur={onBlurSellPrice}
                        required
                      />
                    </div>
                    <span className="mt-1 small text-secondary">
                      {getFormAuditLogString('sell_price')}
                    </span>
                  </label>
                </span>
              </p>

              <p className="row">
                <span className="col-md-6 mb-3">
                  <label htmlFor="fixed_price_surcharge">
                    Import Fixed Charge Per Item
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          class="input-group-text"
                          id="fixed_price_surcharge"
                        >
                          {CURRENCY_SYMBOLS[currency.value]}
                        </span>
                      </div>
                      <input
                        id="fixed_price_surcharge"
                        type="number"
                        min={0}
                        name="fixed_price_surcharge"
                        placeholder="Import Fixed Charge Per Item"
                        className="form-control"
                        aria-describedby="fixed_price_surcharge"
                        defaultValue={
                          form.fixed_price_surcharge === undefined
                            ? ''
                            : form.fixed_price_surcharge
                        }
                        onInput={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </label>
                  <span className="mt-2 small text-secondary">
                    {getFormAuditLogString('fixed_price_surcharge')}
                  </span>
                </span>

                <span className="col-md-6 mb-3">
                  <label htmlFor="displayed_cost_price">
                    Import Costs
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="import_costs">
                          {CURRENCY_SYMBOLS[currency.value]}
                        </span>
                      </div>
                      <input
                        id="import_costs"
                        type="number"
                        disabled
                        name="import_costs"
                        placeholder="Displayed Cost Price"
                        className="form-control"
                        aria-describedby="import_costs"
                        value={getImportCosts(
                          +form.cost_price,
                          +form.fixed_price_surcharge,
                          +form.percentage_increase
                        )}
                      />
                    </div>
                  </label>
                </span>
              </p>
              <p className="row">
                <span className="col-md-6">
                  <label htmlFor="sell_price">
                    Import Percentage Per Item (%)
                    <input
                      id="percentage_increase"
                      type="number"
                      name="percentage_increase"
                      placeholder="Import Percentage Per Item (%)"
                      className="form-control"
                      value={
                        form.percentage_increase === undefined
                          ? ''
                          : form.percentage_increase
                      }
                      onInput={handleChange}
                    />
                  </label>
                  <span className="mt-2 small text-secondary">
                    {getFormAuditLogString('percentage_increase')}
                  </span>
                </span>
                <span className="col-md-6 mb-3">
                  <label htmlFor="profit_per_item">
                    Profit Per Item
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="profit_per_item">
                          {CURRENCY_SYMBOLS[currency.value]}
                        </span>
                      </div>
                      <input
                        id="profit_per_item"
                        type="number"
                        name="profit_per_item"
                        aria-describedby="profit_per_item"
                        disabled
                        className="form-control"
                        value={profit}
                      />
                    </div>
                  </label>
                </span>
              </p>
              <p className="row">
                <span className="col-md-6">
                  <label htmlFor="mark_up">
                    Mark-Up (%)
                    <input
                      id="mark_up"
                      type="number"
                      name="mark_up"
                      placeholder="Mark-Up (%)"
                      value={markUp}
                      onInput={handleMarkUpChange}
                      className="form-control"
                    />
                  </label>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {children}
    </form>
  );
};

ProductForm.propTypes = {
  form: productType.isRequired,
  cats: saCatsType,
  handleSelect: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  addFile: PropTypes.func.isRequired,
  children: childrenType,
};

ProductForm.defaultProps = {
  children: null,
  handleSubmit: null,
  cats: [],
  handleBlur: null,
};

export default ProductForm;
