import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'react-search-input';
import apiWrapper from './helpers/apiWrapper';

class Search extends React.PureComponent {
  searchUpdated = term => {
    const {
      table,
      keys,
      updateResults,
      order,
      where,
      where_or_likes,
      setCurrentSearch,
      useApi,
    } = this.props;

    if (term && term.length < 3) {
      return;
    }

    if (setCurrentSearch) setCurrentSearch(term);
    if (useApi) {
      apiWrapper
        .callApiPost('/api/search', {
          term,
          table,
          columns: keys,
          order,
          where,
          where_or_likes,
        })
        .then(res => {
          updateResults(res);
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <SearchInput
          className="search-input"
          value={this.props.currentSearch}
          onChange={this.searchUpdated}
          throttle={300} // this is debounce, actually
        />
      </React.Fragment>
    );
  }
}

Search.propTypes = {
  table: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateResults: PropTypes.func.isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
  where: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
  setCurrentSearch: PropTypes.func,
};

Search.defaultProps = {
  order: null,
  where: null,
  setCurrentSearch: null,
};

export default Search;
