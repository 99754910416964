module.exports = {
  settings: {
    is_uk: {
      label: 'UK Quote',
      slug: 'is_uk',
      enabled: false,
    },
    is_us: {
      label: 'US Quote',
      slug: 'is_us',
      enabled: false,
    },
    client_link_active: {
      label: 'Client Link Active',
      slug: 'client_link_active',
      enabled: true,
    },
    client_notes_disabled: {
      label: 'Hide Client Notes On View Quote',
      slug: 'client_notes_disabled',
      enabled: false,
    },
    show_approve_button: {
      label: 'Show Client Approve Button On Quote',
      slug: 'show_approve_button',
      enabled: false,
    },
    show_option_a: {
      label: 'Show HIGH COST',
      slug: 'show_option_a',
      enabled: false,
    },
    hide_price: {
      label: 'Hide Price',
      slug: 'hide_price',
      enabled: false,
    },
    grey_out: {
      label: 'Make Not Included Grey',
      slug: 'grey_out',
      enabled: true,
    },
    show_vat: {
      label: 'Add VAT Total Line',
      slug: 'show_vat',
      enabled: false,
    },
    show_totals: { label: 'Show Totals', slug: 'show_totals', enabled: true },
    show_welcome: {
      label: 'Show Welcome',
      slug: 'show_welcome',
      enabled: false,
    },
    // "show_sku": { label: 'SKU', slug: 'show_sku', enabled: false },
    show_finishes: { label: 'Finishes', slug: 'show_finishes', enabled: false },
    large_layout: {
      label: 'Large Product Layout',
      slug: 'large_layout',
      enabled: false,
    },
  },
  currency: process.env.REACT_APP_CURRENCY_SYMBOL,
  currencyName: 'EUR',
};
