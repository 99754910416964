import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faExternalLinkAlt);

const Information = ({ information, className = 'list-unstyled' }) => {
  const items = information.map((item, i) => {
    if (item.title && item.url) {
      return (
        <li key={i}>
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            {item.title}
            <FontAwesomeIcon icon="external-link-alt" />
          </a>
        </li>
      );
    }

    return null;
  });
  return items.length ? <ul className={className}>{items}</ul> : null;
};

Information.propTypes = {
  information: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
};

Information.defaultProps = {
  className: 'list-unstyled',
};

export default Information;
