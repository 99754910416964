/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  setManagers,
  showManagersLoader,
  hideManagersLoader,
  selectManager,
  getManagerNameByIdAction,
} from '../actions/managers';

const managers = createReducer(
  {
    users: [],
    loaded: false,
    loading: false,
    selected: null,
  },
  {
    [setManagers]: (state, { payload }) => {
      state.users = payload;
      state.loaded = true;
    },
    [showManagersLoader]: state => {
      state.loading = true;
    },
    [hideManagersLoader]: state => {
      state.loading = false;
    },
    [selectManager]: (state, { payload }) => {
      state.selected = payload;
    },
    [getManagerNameByIdAction]: (state, { payload }) => {
      state.managerName = payload;
    },
  }
);

export default managers;
