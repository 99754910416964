import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FixedHeaderNavItem = ({
  linkType,
  link,
  text,
  className,
  state,
  listItemClassName,
}) => (
  <li className={`nav-item ${listItemClassName || ''}`}>
    {linkType === 'regular' && (
      <a href={link} className={className}>
        {text}
      </a>
    )}
    {linkType === 'router' && (
      <Link
        to={{
          pathname: link,
          state,
        }}
        className={className}
      >
        {text}
      </Link>
    )}
  </li>
);

FixedHeaderNavItem.propTypes = {
  linkType: PropTypes.oneOf(['regular', 'router']),
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  state: PropTypes.shape({}),
};

FixedHeaderNavItem.defaultProps = {
  className: 'nav-link',
  linkType: 'regular',
  state: {},
};

export default FixedHeaderNavItem;
