import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import quoteReducer from './reducers/quote';
import quoteProductsReducer from './reducers/quoteProducts';
import groupsReducer from './reducers/groups';
import managersReducer from './reducers/managers';
import messagesReducer from './reducers/messages';
import settingsReducer from './reducers/settings';
import appSettingsReducer from './reducers/appSettings';
import opportunitiesReducer from './reducers/opportunities';
import editQuoteChangeDetector from './middleware/editQuoteChangeDetector';

const store = configureStore({
  reducer: {
    quote: quoteReducer,
    quoteProducts: quoteProductsReducer,
    groups: groupsReducer,
    managers: managersReducer,
    messages: messagesReducer,
    settings: settingsReducer,
    appSettings: appSettingsReducer,
    opportunities: opportunitiesReducer,
  },
  middleware: [...getDefaultMiddleware(), editQuoteChangeDetector],
});

export default store;
