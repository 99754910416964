import { createAction } from '@reduxjs/toolkit';
import settingsDefaultState from '../defaultState/settings';
import apiWrapper from '../components/helpers/apiWrapper';
import { cloneDeep } from 'lodash';

import { toggleQuote } from '../services/settings';

const loadSettingsAction = createAction('LOAD_SETTINGS');
const updateSettingAction = createAction('UPDATE_SETTING');

const loadSettings = quoteId => async dispatch => {
  if (!quoteId) {
    return dispatch(loadSettingsAction(cloneDeep(settingsDefaultState)));
  }

  try {
    const settings = await apiWrapper.callApi(
      `/api/quotes/getSettings/${quoteId}`
    );

    const mappedSettings = Object.keys(settingsDefaultState.settings).reduce(
      (acc, key) => {
        acc[key] = {
          ...settingsDefaultState.settings[key],
          enabled: !!settings[key],
        };

        return acc;
      },
      {}
    );

    dispatch(loadSettingsAction({ settings: mappedSettings }));
  } catch (e) {
    console.log(e);
  }
};

function updateSetting(name, checked, settings) {
  const settingToChangeIndex = settings.findIndex(
    setting => name === setting.slug
  );

  if (name) return updateSettingAction({ name, checked }, settingToChangeIndex);
}

export {
  loadSettingsAction,
  loadSettings,
  updateSettingAction,
  updateSetting,
};
