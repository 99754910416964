import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import Search from '../../Search';
import { quoteStatus, quoteStatusLabels, quoteCurrencies, quoteCurrenciesLabels } from '../../../../types';

const statusOptions = [
  { value: null, label: 'All' },
  { value: quoteStatus.open, label: quoteStatusLabels[quoteStatus.open] },
  { value: quoteStatus.won, label: quoteStatusLabels[quoteStatus.won] },
  {
    value: quoteStatus.approved,
    label: quoteStatusLabels[quoteStatus.approved],
  },
  { value: quoteStatus.lost, label: quoteStatusLabels[quoteStatus.lost] },
  { value: quoteStatus.duplicated, label: quoteStatusLabels[quoteStatus.duplicated] }
];

const currenciesOptions = [
  { value: null, label: "All" },
  { value: quoteCurrencies.euro, label: quoteCurrenciesLabels[quoteCurrencies.euro]},
  { value: quoteCurrencies.us, label: quoteCurrenciesLabels[quoteCurrencies.us]},
  { value: quoteCurrencies.uk, label: quoteCurrenciesLabels[quoteCurrencies.uk]}
];

const QuoteListHeader = ({
  updateResults,
  updateFilter,
  getFilters,
  users,
  setCurrentSearch,
}) => {
  const { status, currency, user } = getFilters();

  const usersOptions = [
    { value: null, label: 'All' }
  ];

  users && users.length && users.forEach(u => {
    usersOptions.push({
      value: u.id,
      label: u.name
    });
  })

  const filterStatusValue = status
    ? { value: status, label: quoteStatusLabels[status] }
    : null;

  const filterCurrencyValue = currency
    ? { value: currency, label: quoteCurrenciesLabels[currency] }
    : null;


  let filterUserValue = null;

  if (usersOptions.length > 1 && user) {
    filterUserValue =  { value: user, label: usersOptions.find(u => parseInt(u.value) === parseInt(user)).label }
  }

  const updateFilterValue = useCallback((key, value) => {
    updateFilter(key, value);
  });

  return (
    <div>
      <Helmet defer={false}>
        <title>Quotes</title>
      </Helmet>
      <div className="row my-3">
        <div className="col-md-12 mx-auto text-center">
          <h1>Quotes</h1>
          <div className="row">
            <div className="col-md-4">
              <Search
                table="quotes"
                keys={['quote_name', 'customer_name']}
                updateResults={updateResults}
                order={['date', 'DESC']}
                where={{ status, user, currency }}
                setCurrentSearch={setCurrentSearch}
                useApi
              />
            </div>
            <div className="col-md-3 text-left">
              <Select
                value={filterStatusValue || { label: 'All' }}
                options={statusOptions}
                onChange={(value) => updateFilterValue("status", value)}
              />
            </div>
            <div className="col-md-2 text-left">
              <Select
                value={filterCurrencyValue || { label: 'All' }}
                options={currenciesOptions}
                onChange={(value) => updateFilterValue("currency", value)}
              />
            </div>

            <div className="col-md-3 text-left">
              <Select
                value={filterUserValue || { label: 'All' }}
                options={usersOptions}
                onChange={(value) => updateFilterValue("user", value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuoteListHeader.propTypes = {
  updateResults: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
  setCurrentSearch: PropTypes.func.isRequired,
};

export default QuoteListHeader;
