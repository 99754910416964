import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes, { arrayOf } from 'prop-types';
import { DropTarget } from 'react-dnd';
import { ListGroup } from 'reactstrap';
import { RIEInput } from 'riek';
import flow from 'lodash/flow';
import Product from './edit/Product';
import OptionalTitle from './OptionalTitle';
import { productType } from '../../../types';

import { changeProductGroupAction } from '../../actions/quoteProducts';

import {
  updateGroup,
  changeGroupPosition,
  addGroupToRemoved,
} from '../../actions/groups';

const mapDispatchToProps = {
  changeProductGroupAction,
  updateGroup,
  addGroupToRemoved,
  changeGroupPosition,
};

function mapStateToProps({ quote, quoteProducts }) {
  return { quote, products: quoteProducts.products };
}

const productTarget = {
  drop(props, monitor) {
    const { id, products } = props;
    const sourceObj = monitor.getItem();

    if (id !== sourceObj.listId) {
      const { product } = sourceObj;
      props.changeProductGroupAction({
        position: products.findIndex(item => item.id === product.id),
        newGroupId: id,
      });
    }

    return {
      listId: id,
    };
  },
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

class Group extends React.PureComponent {
  edit = group => {
    const { id } = this.props;
    const groupUpdate = { ...group };

    // if we are setting name and if it is empty, reset it to New group
    if (groupUpdate.hasOwnProperty('name') && !groupUpdate.name) {
      groupUpdate.name = 'New group';
    }

    this.props.updateGroup(id, groupUpdate);
  };

  moveGroupPosition = e => {
    const { position, groupsCount } = this.props;
    const { value: newPosition } = e.target;

    // make sure that the value is within the boundaries
    if (newPosition > groupsCount || newPosition < 1) {
      return;
    }

    this.props.changeGroupPosition(position - 1, newPosition - 1);
  };

  render() {
    const {
      id,
      thisGroupProducts,
      parent_title,
      name,
      canDrop,
      isOver,
      groupsCount,
      position,
      subtitle,
      duplicateProduct,
      updateMainProduct,
      connectDropTarget,
      openModal,
      taxonomies,
      taxonomiesLoaded,
    } = this.props;

    const { id: quoteId } = this.props.quote;

    const isActive = canDrop && isOver;
    const backgroundColor = isActive ? 'gray' : '#fff';

    return connectDropTarget(
      <div>
        <header className="group-header">
          <OptionalTitle
            name="parent_title"
            title={parent_title}
            addTitle={this.addTitle}
            editTitle={this.edit}
            defaultValue="Add group parent title"
            buttonCaption="Add Parent Title"
            Tag="h2"
          />
          <div className="d-flex justify-content-between">
            <h2 className="group-title my-2">
              <RIEInput
                value={name || ''}
                propName="name"
                change={this.edit}
                className="editable"
              />
            </h2>
            <input
              type="number"
              className="group-position"
              min={1}
              max={groupsCount}
              step={1}
              value={position}
              onChange={this.moveGroupPosition}
            />
          </div>
          <OptionalTitle
            name="subtitle"
            title={subtitle}
            addTitle={this.addTitle}
            editTitle={this.edit}
          />
        </header>
        <div
          style={{ outline: '2px dashed gray', backgroundColor }}
          className="p-1"
        >
          {thisGroupProducts.length ? (
            <ListGroup className="list-group">
              {thisGroupProducts.map((product, i) => (
                <Product
                  product={product}
                  key={`${product.id}`}
                  index={i}
                  listId={id}
                  duplicate={duplicateProduct}
                  openModal={openModal}
                  updateMainProduct={updateMainProduct}
                  taxonomies={taxonomies}
                  taxonomiesLoaded={taxonomiesLoaded}
                />
              ))}
            </ListGroup>
          ) : (
            <button
              type="button"
              className="btn btn-link text-danger remove-group-link"
              onClick={() => {
                this.props.addGroupToRemoved(id);
              }}
            >
              Remove Empty Group
            </button>
          )}
          <Link
            className="btn btn-link small"
            to={{
              pathname: '/products',
              state: {
                editQuote: quoteId,
                groupId: id,
              },
            }}
          >
            Add Products to this Group
          </Link>
        </div>
      </div>
    );
  }
}

Group.propTypes = {
  quoteId: PropTypes.number,
  id: PropTypes.number,
  canDrop: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  updateMainProduct: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  duplicateProduct: PropTypes.func.isRequired,
  groupsCount: PropTypes.number.isRequired,
  thisGroupProducts: arrayOf(productType).isRequired,
  position: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  parent_title: PropTypes.string,
};

Group.defaultProps = {
  id: null,
  quoteId: null,
  subtitle: '',
  parent_title: '',
};

export default flow(
  DropTarget('product', productTarget, collect),
  connect(mapStateToProps, mapDispatchToProps)
)(Group);
