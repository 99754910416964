import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Bullets from './Bullets';
import EditableTextarea from './EditableTextarea';
import { loadDefaultTcs, updateTcs } from '../../actions/quote';

const mapStateToProps = ({ quote }) => ({
  quote,
});

const mapDispatchToProps = {
  loadDefaultTcs,
  updateTcs,
};

const TCS = ({ quote, updateTcs, tag, context, loadDefaultTcs }) => {
  const { tcs } = quote;

  React.useEffect(() => {
    if (quote.id && !tcs) {
      loadDefaultTcs();
    }
  }, [quote.id]);

  return (
    <div className="my-4 terms-and-conditions-container">
      <h5>Terms and Conditions</h5>
      {context === 'edit' ? (
        <EditableTextarea update={updateTcs} value={tcs}>
          <Bullets bullets={tcs} className="tcs-list" Tag={tag} />
        </EditableTextarea>
      ) : (
        <Bullets bullets={tcs} className="tcs-list" Tag={tag} />
      )}
    </div>
  );
};

TCS.propTypes = {
  tag: PropTypes.string,
  context: PropTypes.string,
};

TCS.defaultProps = {
  tag: 'ol',
  context: 'edit',
};

export default connect(mapStateToProps, mapDispatchToProps)(TCS);
