import IconButton from "../../IconButton"
import React from "react";
import { useModal } from "../../hooks/useModal";
import {Button} from "react-bootstrap";

export const DuplicateButton = ({handleDuplicate}) => {
    const {openModal, closeModal} = useModal();

    const onClickAction = (allowDuplicate) => {
        handleDuplicate(allowDuplicate);
        closeModal();
    }

    const onConfirmDuplicateFinishes = () => {
        openModal({
            title: "Duplicate Product",
            content: "Do you want to copy the finishes?",
            actionButtons: (
                <>
                    <Button variant="primary" onClick={() => onClickAction(true)}>Yes</Button>
                    <Button variant="secondary" onClick={() => onClickAction(false)}>No</Button>
                </>
            )
        })
    }
    return (
        <IconButton
            onClick={onConfirmDuplicateFinishes}
            icon="copy"
            title="Duplicate Product"
        />
    )
}