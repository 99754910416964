import React from 'react';
import PropTypes from 'prop-types';
import { productType } from '../../../../types';
import ProductView from './ProductView';
import GroupHeader from './GroupHeader';
import { useSelector } from 'react-redux';
import { ProductViewLarge } from './ProductViewLarge';

const GroupView = ({
  thisGroupProducts = [],
  name,
  subtitle,
  parent_title,
  updateNote,
  showOptions,
  greyOut,
  taxonomies,
  taxonomiesLoaded,
}) => {
  const settings = useSelector(state => state?.settings?.settings);
  const quote = useSelector(state => state?.quote);
  // Could not get break-after: avoid working on group title so wrapped it into a div along with
  // the first product and disallowed break inside
  // console.log(settings?.large_layout);

  return (
    <div className="product-group-view">
      {thisGroupProducts.length === 0 && (
        <GroupHeader
          name={name}
          subtitle={subtitle}
          parent_title={parent_title}
        />
      )}
      {thisGroupProducts.map((product, i) => (
        <div
          className="product-view-wrapper"
          style={{ breakInside: 'avoid' }}
          key={product.id}
        >
          {i === 0 && (
            <GroupHeader
              name={name}
              subtitle={subtitle}
              parent_title={parent_title}
            />
          )}
          {settings?.large_layout?.enabled ? (
            <ProductViewLarge
              showOptions={showOptions}
              product={product}
              index={i}
              updateNote={updateNote}
              greyOut={greyOut}
              quote={quote}
              settings={settings}
              taxonomies={taxonomies}
              taxonomiesLoaded={taxonomiesLoaded}
            />
          ) : (
            <ProductView
              showOptions={showOptions}
              product={product}
              index={i}
              firstInGroup={i === 0}
              updateNote={updateNote}
              greyOut={greyOut}
              taxonomies={taxonomies}
              taxonomiesLoaded={taxonomiesLoaded}
            />
          )}
        </div>
      ))}
    </div>
  );
};

GroupView.propTypes = {
  thisGroupProducts: PropTypes.arrayOf(productType).isRequired,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  parent_title: PropTypes.string,
  updateNote: PropTypes.func.isRequired,
  showOptions: PropTypes.bool.isRequired,
  greyOut: PropTypes.bool.isRequired,
};

GroupView.defaultProps = {
  subtitle: '',
  parent_title: '',
};

export default GroupView;
